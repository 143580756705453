import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  accountInfo: {},
  accountInfoLoaded: false,
  subscriptionChangeReceived: null,
  subscriptionChangePending: null,
  showPleaseWaitDialog: false
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setAccountInfo: (state, { payload }) => {
      state.accountInfo = {
        ...payload.accountInfo
      }
      state.accountInfoLoaded = true
      state.subscriptionChangePending = false
      state.subscriptionChangeReceived = false
    },
    setSubscriptionChangePending: (state, { payload = false }) => {
      state.subscriptionChangePending = payload
    },
    setSubscriptionChangeError: (state, { payload = false }) => {
      state.subscriptionChangePending = false
    },
    setSubscriptionChangeReceived: (state, { payload }) => {
      // This will trigger a request for new account info
      state.subscriptionChangeReceived = payload
    }
  }
})

export const { setAccountInfo, setSubscriptionChangePending, setSubscriptionChangeReceived, setSubscriptionChangeError } = content.actions

export default content.reducer
