import { useSession } from '@emerald-works/react-auth'
import { useIdleTimer } from 'react-idle-timer'
import useLogout from './useLogout'

const INACTIVIY_TIMEOUT =
  process.env.REACT_APP_INACTIVIY_TIMEOUT ?? 60 * 60 * 1000

const useSessionInactivity = () => {
  const { isAuthenticated } = useSession()
  const isBrowserUser = !isAuthenticated
  const { logout } = useLogout()

  const onIdle = () => {
    if (window.location.pathname === '/login' || isBrowserUser) return

    logout('/login')
  }

  useIdleTimer({ timeout: INACTIVIY_TIMEOUT, onIdle })
}

export default useSessionInactivity
