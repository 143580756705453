import React from 'react'
import clsx from 'clsx'
import { useStyles } from './style'

const Container = props => {
  const cls = useStyles(props)

  const { className, classes = {} } = props

  return (
    <div className={clsx(cls.container, className)}>
      <div className={clsx(cls.content, classes.content)}>{props.children}</div>
    </div>
  )
}

export default Container
