import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    width: '100%',
    margin: spacing(4, 1),
    flex: 1
  },
  divider: {
    backgroundColor: palette.grey.shade[20],
    marginBottom: spacing(2)
  }
}))

const ContactUs = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Divider variant='fullWidth' className={classes.divider} />
      <Typography align='center'><b>Contact for support:</b></Typography>
      <Typography align='center'><b>Tel:</b> (+44) (0)131 526 4111</Typography>
      <Typography align='center'><b>Email:</b> mtfbsupport@mindtools.com</Typography>
    </Box>
  )
}

export default ContactUs
