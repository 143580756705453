import React from 'react'
import { Helmet } from 'react-helmet'
import { ServicePage, Title, Desc, Actions, Image } from '../../components/service-page'
import { ReactComponent as NotFound } from './images/not-found.svg'

const title = '404 - Page Not Found'
const desc = [
  "We can't find the page you're looking for.",
  'You can either return to the previous page, visit the homepage or contact our support team.'
]

const NotFoundPage = props => {
  React.useEffect(() => {
    window.prerenderReady = undefined
    console.debug(window.performance.now(), 'PrerenderReady is undefined.')
  }, [])

  return (
    <ServicePage maxContentWidth={1440} inset={3}>
      <Helmet>
        <meta name='prerender-status-code' content='404' />
      </Helmet>
      <Title>{title}</Title>
      <Desc text={desc} />
      <Image imageComponent={NotFound} />
      <Actions />
    </ServicePage>
  )
}

export default NotFoundPage
