import * as Usergroups from '../../services/cognito/userGroups'

export const ALLOW_LIKE_GROUP = Usergroups.ALL_AUTHENTICATED
export const ALLOW_SHARE_GROUP = Usergroups.ALL_CONSUMER
export const ALLOW_SOCIAL_SHARE_GROUP = Usergroups.ALL_CONSUMER
export const ALLOW_SKILL_BITES = [Usergroups.CONSUMER, Usergroups.MARKETING, Usergroups.ADMIN, Usergroups.ENTERPRISE_READER, Usergroups.CLIENT_MANAGER, Usergroups.EW, Usergroups.EDITOR, Usergroups.CLIENT_EXPERIENCE]
export const ALLOW_PLAYLISTS_GROUP = [Usergroups.CONSUMER, Usergroups.MARKETING, Usergroups.ADMIN, Usergroups.ENTERPRISE_READER, Usergroups.CLIENT_MANAGER, Usergroups.EW, Usergroups.EDITOR, Usergroups.CLIENT_EXPERIENCE]
export const ALLOW_TRANSLATE_GROUP = [Usergroups.CONSUMER, Usergroups.MARKETING, Usergroups.ADMIN, Usergroups.ENTERPRISE_READER, Usergroups.CLIENT_MANAGER, Usergroups.EDITOR, Usergroups.CLIENT_EXPERIENCE]
export const ALLOW_DELETE_COMMENTS_GROUP = [Usergroups.MARKETING, Usergroups.ADMIN]
export const ALLOW_ADD_COMMENTS_GROUP = Usergroups.ALL_AUTHENTICATED
export const ALLOW_BOOKMARKS_GROUP = Usergroups.ALL_AUTHENTICATED
export const ALLOW_REPORTING_GROUP = [Usergroups.CLIENT_MANAGER, Usergroups.MARKETING, Usergroups.ADMIN, Usergroups.EW]
export const ALLOW_USER_PAGE_GROUP = [Usergroups.CLIENT_MANAGER]
export const ALLOW_PROFILE_GROUP = Usergroups.ALL_AUTHENTICATED
export const ALLOW_ADMIN_TOOLS = [Usergroups.CLIENT_MANAGER, Usergroups.ADMIN]
export const ALLOW_DOWNLOADS_GROUP = [Usergroups.CONSUMER, Usergroups.MARKETING, Usergroups.ADMIN, Usergroups.ENTERPRISE_READER, Usergroups.CLIENT_MANAGER, Usergroups.EDITOR, Usergroups.CLIENT_EXPERIENCE]
export const ALLOW_HISTORY_EDIT = Usergroups.ALL_AUTHENTICATED
