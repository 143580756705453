import React, { useState, useRef, Suspense, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Avatar,
  IconButton,
  Box,
  Popover,
  CircularProgress,
  Typography
} from '@mui/material'
import { ActionButton } from '../..'
import { EmojiEmotionsOutlined } from '@mui/icons-material'
import { useStyles } from './style'
import { useSession } from '@emerald-works/react-auth'
import { Link } from 'react-router-dom'
import getRegisterLink from '../../../helpers/getRegisterLink'
import { useTenant } from '@emerald-works-nova/auth'
import { FreeAccountCtaLock } from '../../icons'
const EmojiPicker = React.lazy(() => import('./emoji'))

/** Form to submit a comment on an article.
 * <br/>
 * Additional props are forwarded to the underlying ```TextField``` component
 */
const CommentInput = props => {
  const { author, onSubmit, onCancel, isReply, ...other } = props
  const classes = useStyles(props)
  const { isAuthenticated } = useSession()

  const { givenName = '', familyName = '' } = author || {}
  const authorName = `${givenName} ${familyName}`
  const authorSlug = `${givenName?.charAt(0)}${familyName?.charAt(0)}`

  const inputRef = useRef(null)
  const tenant = useTenant()

  const [value, setValue] = useState(props?.value ?? '')
  const [cursor, setCursor] = useState({ start: 0, end: 0 })
  const [emojiPickerAnchor, setEmojiPickerAnchor] = useState(null)
  const [isAddingComment, setIsAddingComment] = useState(false)

  const emojiPickerOpen = Boolean(emojiPickerAnchor)

  const handleChange = ({ target: { value } }) => setValue(value)

  const signupRoute = useMemo(() => getRegisterLink(tenant), [tenant])

  const handleSubmit = async () => {
    setIsAddingComment(true)
    await onSubmit(value)
    setValue('')
    setIsAddingComment(false)
  }

  const handleOpenEmojiPicker = ({ target }) => {
    setEmojiPickerAnchor(target)
    setCursor({
      start: inputRef.current.selectionStart,
      end: inputRef.current.selectionEnd
    })
  }

  const handleEmojiSelect = emoji => {
    setValue(
      value =>
        value.slice(0, cursor.start) +
        emoji.native +
        ' ' +
        value.slice(cursor.end, value.length)
    )
  }

  const FreeAccountCommentCta = () => {
    if (isAuthenticated) return null

    return (
      <Box className={classes.freeAccountCommentCtaContainer}>
        <FreeAccountCtaLock />
        <Typography variant='caption'><span data-test='comment-create-account-message'>Create an account to comment ·</span> <Link to={signupRoute} data-test='comment-create-account'>Click here</Link></Typography>
        <Typography variant='caption'>Or <Link to='/login' data-test='comment-sign-in'>Sign in here</Link></Typography>
      </Box>
    )
  }

  return (
    /* eslint-disable multiline-ternary */
    <>
      <Grid container spacing={1} wrap='nowrap' className={classes.root}>
        <Grid item>
          {isAuthenticated && (
            <Avatar
              alt={authorName}
              src={author?.image}
              name={authorName}
              className={classes.avatar}
              data-test='comment-input-avatar'
            >
              {authorSlug}
            </Avatar>
          )}
        </Grid>
        <Grid container item direction='column' spacing={2}>
          <Grid container item className={classes.input}>
            <TextField
              id='new-comment-input'
              inputRef={inputRef}
              placeholder='Comment here'
              variant='standard'
              value={value}
              onChange={handleChange}
              multiline
              disabled={isAddingComment || !isAuthenticated}
              minRows={isReply ? 1 : 5}
              maxRows={isReply ? 5 : 10}
              fullWidth
              margin={isReply ? 'none' : 'dense'}
              InputProps={{
                'aria-label': 'comment on this article',
                disableUnderline: true
              }}
              className={classes.textarea}
              {...other}
              data-test='new-comment-input'
            />
            <Box className={classes.emojiButton}>
              <IconButton
                aria-label='add emoji'
                size='small'
                disabled={!isAuthenticated}
                onClick={handleOpenEmojiPicker}
                data-test='comment-input-emoji-button'
              >
                <EmojiEmotionsOutlined />
              </IconButton>
              <Popover
                anchorEl={emojiPickerAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={emojiPickerOpen}
                onClose={() => setEmojiPickerAnchor(null)}
                PaperProps={{
                  elevation: 0
                }}
                data-test='comment-input-emoji-popover'
              >
                <Suspense fallback={<CircularProgress />}>
                  <EmojiPicker
                    set='native'
                    sheetSize={16}
                    onEmojiSelect={handleEmojiSelect}
                  />
                </Suspense>
              </Popover>
            </Box>
          </Grid>
          {!isReply ? (
            <Grid item className={classes.addCommentContainer}>
              <ActionButton
                variant='primary'
                label='Add comment'
                loading={isAddingComment}
                loadingIndicator='Adding comment...'
                onClick={handleSubmit}
                disabled={value.length < 1 || !isAuthenticated}
                data-test='article-new-comment-button'
              />
              <FreeAccountCommentCta />
            </Grid>
          ) : (
            <Grid container justifyContent='flex-end'>
              <FreeAccountCommentCta />
              {isAuthenticated && (
                <>
                  <ActionButton
                    variant='none'
                    size='xs'
                    label='Cancel'
                    onClick={onCancel}
                    data-test='comment-reply-cancel-button'
                  />
                  <ActionButton
                    variant='link'
                    size='xs'
                    label='Send'
                    disabled={!isAuthenticated}
                    onClick={handleSubmit}
                    data-test='comment-reply-send-button'
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default CommentInput

CommentInput.propTypes = {
  /** Name and profile image of user who is submitting the comment */
  author: PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    image: PropTypes.string
  }),
  /** True if replying to an existing comment */
  isReply: PropTypes.bool,
  /** Callback fired when the 'Add Comment or Send' button is clicked */
  onSubmit: PropTypes.func,
  /** Callback fired when the 'Cancel' button is clicked */
  onCancel: PropTypes.func
}
