import React from 'react'
import Button from '@mui/material/Button'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { SharePanel } from '../..'
import { useStyles } from './style'

const ShareDialogPane = ({ url, locationId, name = 'content', onClose, onCopy, title, subtitle, image }) => {
  const classes = useStyles()

  return (
    <>
      <DialogTitle id='dialog-title' className={classes.title}>
        {`Share this ${name}`}
      </DialogTitle>
      <DialogContent>
        <SharePanel from='share-modal' url={url} onCopy={onCopy} locationId={locationId} title={title} subtitle={subtitle} image={image} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color='primary'
          className={classes.close}
          data-test='dialog-share-close'
        >
          Close
        </Button>
      </DialogActions>
    </>
  )
}

export default ShareDialogPane
