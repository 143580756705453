import name from './name'

const selectSliceData = state => state[name]

const selectProfile = (state) => selectSliceData(state).profile
const selectSkillBiteLanguage = (state) => {
  return state?.profile?.skillBiteLanguage ?? localStorage.getItem('skillBiteLanguage') ?? 'en'
}
const selectIsProfileLoaded = state => selectSliceData(state).isProfileLoaded
const selectIsProfileUpdating = state => selectSliceData(state).isUpdating
const selectProfileLoadError = state => selectSliceData(state).hasProfileLoadError
const selectAuthEvents = state => selectSliceData(state).authEvents

export { selectProfile, selectIsProfileLoaded, selectProfileLoadError, selectSkillBiteLanguage, selectIsProfileUpdating, selectAuthEvents }
