import React from 'react'
import {
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import useFormFactor from '../../hooks/useFormFactor'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SocialIcon } from 'react-social-icons'
import { MtLogo } from '../logos'

const contents = [
  {
    category: 'Useful Links',
    articles: [
      { name: 'Signup', href: 'signup/' },
      { name: 'How It Works', href: 'how-it-works/' },
      { name: 'FAQ', href: 'faq/' },
      { name: 'About Us', href: 'about-us/' },
      { name: 'For Organizations', href: 'solutions/organizations/' }
    ]
  },
  {
    category: 'Top Trending Topics',
    articles: [
      {
        name: 'Johari Window',
        href: 'au7v71d/the-johari-window'
      },
      {
        name: 'Root Cause Analysis',
        href: 'ag6pkn9/root-cause-analysis'
      },
      {
        name: 'Emotional Intelligence',
        href: 'ab4u682/emotional-intelligence'
      },
      {
        name: 'Learning Styles',
        href: 'addwv9h/learning-styles'
      },
      {
        name: 'How to Stop Procrastinating',
        href: 'a5plzk8/how-to-stop-procrastinating'
      },
      {
        name: 'How to Make Decisions',
        href: 'aiplsat/how-to-make-decisions'
      }
    ]
  },
  {
    category: 'Top Quizzes',
    articles: [
      {
        href: 'apdfhaw/how-good-are-your-leadership-skills',
        name: 'Leadership Quiz'
      },
      {
        href: 'a3y5cte/how-good-are-your-communication-skills',
        name: 'Communication Quiz'
      },
      {
        href: 'aavjrgg/how-good-is-your-time-management',
        name: 'Time Management Quiz'
      },
      {
        href: 'aoubxrt/how-good-is-your-problem-solving',
        name: 'Problem Solving Quiz'
      },
      {
        href: 'a0psx3v/how-good-is-your-decision-making',
        name: 'Decision Making Quiz'
      },
      {
        href: 'a08j05x/how-good-are-your-management-skills',
        name: 'Management Skills Quiz'
      }
    ]
  }
]

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    width: '100%'
  },
  content: {
    width: '100%',
    maxWidth: 1415,
    justifyContent: 'space-between',
    paddingTop: spacing(5)
  },
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    textDecoration: 'none',
    color: palette.grey.shade[80],
    fontWeight: typography.weight.medium,
    fontSize: typography.body.s.fontSize,
    height: 25
  },
  accordionDetails: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    backgroundColor: palette.grey.shade[5]
  },
  accordion: {
    boxShadow: 'none',
    borderTop: '1px solid #DDDBE0'
  },
  summary: {
    height: spacing(2),
    backgroundColor: palette.grey.shade[5]
  },
  icons: {
    display: 'flex',
    gap: spacing(1.5)
  },
  category: {
    lineHeight: 0,
    color: palette.grey.shade[90]
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  iconsMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(2)
  }
}))

const SocialIcons = () => {
  const classes = useStyles()
  return (
    <div className={classes.icons}>
      <SocialIcon
        style={{ height: 35, width: 35 }}
        target='_blank'
        url='https://www.facebook.com/mindtools'
      />
      <SocialIcon
        style={{ height: 35, width: 35 }}
        target='_blank'
        bgColor='#b3399f'
        url='https://www.instagram.com/mind_tools_learning'
      />
      <SocialIcon
        style={{ height: 35, width: 35 }}
        target='_blank'
        bgColor='#51b3eb'
        url='https://x.com/Mind_Tools'
      />
      <SocialIcon
        style={{ height: 35, width: 35 }}
        target='_blank'
        url='https://www.linkedin.com/company/mind-tools'
      />
      <SocialIcon
        style={{ height: 35, width: 35 }}
        target='_blank'
        url='https://www.youtube.com/c/MindToolsVids'
      />
    </div>
  )
}

const ContentLinks = () => {
  const classes = useStyles()

  const { isLargeTablet, isDesktop } = useFormFactor()

  const isLargeScreen = React.useMemo(
    () => isDesktop || isLargeTablet,
    [isDesktop, isLargeTablet]
  )

  const LargeScreen = () => (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.columns}>
          <div>
            <Link href='https://mindtools.com' target='_blank' rel='noreferrer'>
              <MtLogo width='156' height='40' />
            </Link>
            <SocialIcons />
          </div>
          {contents.map(({ articles, category }, key) => (
            <div className={classes.grid} key={key}>
              <h4 className={classes.category}>{category}</h4>
              {articles.map(({ href, name }) => (
                <Link
                  href={`https://${window.location.host}/${href}`}
                  target='_blank'
                  rel='noreferrer'
                  key={href}
                  className={classes.link}
                >
                  {name}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  const SmallScreen = () => (
    <div>
      <div className={classes.iconsMobileContainer}>
        <Link href='https://mindtools.com' target='_blank' rel='noreferrer'>
          <MtLogo />
        </Link>
        <SocialIcons />
      </div>
      {contents.map(({ articles, category }, key) => (
        <Accordion className={classes.accordion} key={key}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            <h4 className={classes.category}>{category}</h4>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box display='flex' flexDirection='column'>
              {articles.map(({ href, name }) => (
                <Link
                  href={`https://${window.location.host}/${href}`}
                  target='_blank'
                  rel='noreferrer'
                  key={href}
                  className={classes.link}
                >
                  {name}
                </Link>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )

  return isLargeScreen ? <LargeScreen /> : <SmallScreen />
}

export default ContentLinks
