import React from 'react'
import ArticleList from './article-list'
import ArticleCard from './article-card'
import ExploreLayout from './layout'

const Discover = ({ popular, trending, recommended, onClose, onSelect }) => (
  <ExploreLayout
    title='Discover something new today'
    onClose={onClose}
    panel1={
      <ArticleList
        items={trending}
        title='Trending'
        renderArticle={article => (
          <ArticleCard
            variant='landscape'
            article={article}
            onSelect={onSelect}
          />
        )}
      />
    }
    panel2={
      <ArticleList
        items={popular}
        title='Popular'
        renderArticle={article => (
          <ArticleCard
            variant='landscape'
            article={article}
            onSelect={onSelect}
            showImage={false}
          />
        )}
      />
    }
    panel3={
      <ArticleList
        items={recommended}
        title='Recommended for you'
        changeSecondPosition
        renderArticle={article => (
          <ArticleCard
            variant='portrait'
            article={article}
            onSelect={onSelect}
          />
        )}
      />
    }
  />
)

export default Discover
