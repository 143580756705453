import { stackAlert } from '../toast/helpers'
import * as actions from './slice'

export const getCampaignData = {
  eventName: 'campaignGetForTenant',
  onSuccess: { action: actions.setCampaignData, redux: true },
  onError: [
    { action: stackAlert('Unable to get campaign data', 'error'), redux: true }
  ]
}
