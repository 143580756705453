export default {
  main: `0px 1px 3px rgba(24, 24, 24, 0.1), 
        0px 1px 2px rgba(24, 24, 24, 0.06)`,
  s: '0px 1px 2px rgba(24, 24, 24, 0.07)',
  m: `0px 4px 6px -1px rgba(24, 24, 24, 0.1), 
      0px 2px 4px -1px rgba(24, 24, 24, 0.06)`,
  l: `0px 4px 6px -2px rgba(24, 24, 24, 0.06), 
      0px 10px 15px -3px rgba(24, 24, 24, 0.1)`,
  xl: `0px 10px 10px -5px rgba(24, 24, 24, 0.06),
       0px 20px 25px -5px rgba(24, 24, 24, 0.1)`,
  xxl: `0px 4px 6px -2px rgba(24, 24, 24, 0.06), 
        0px 25px 50px -12px rgba(24, 24, 24, 0.25)`
}
