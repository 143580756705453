import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton, Grid, Typography, CircularProgress } from '@mui/material'

import { useStyles } from './style'
import { OnboardingPopper } from '../../onboarding/popper'

const loadingIndicatorSize = { xs: 16, s: 18, m: 20, l: 22, xl: 24 }

/** Generic text/icon button for performing actions */
const ActionButton = ({
  label,
  hideText = false,
  variant = 'secondary',
  fullWidth,
  size = 's',
  startIcon,
  endIcon,
  onClick = () => { },
  loading,
  loadingIndicator,
  disabled,
  popper = null,
  ...other
}) => {
  const classes = useStyles({ label, hideText, variant, fullWidth, size, startIcon, endIcon, onClick, loading, loadingIndicator, disabled, popper, ...other })
  const finalStartIcon = loading ? (<CircularProgress size={loadingIndicatorSize[size]} color='inherit' />) : startIcon

  const finalLabel = loading && loadingIndicator ? loadingIndicator : label

  const popperRef = useRef()

  return (
    <>
      <div ref={popperRef}>
        <Grid item data-test={`action-button-${label}'}`} style={{ width: fullWidth ? '100%' : undefined }}>
          {hideText
            ? (
              <IconButton
                aria-label={label}
                onClick={onClick}
                classes={{ root: classes.root }}
                variant={variant}
                data-test={`action-button-icon-${label}'}`}
                {...other}
                size='large'
              >
                {startIcon || endIcon}
              </IconButton>
              )
            : (
              <Button
                classes={{
                  root: classes.root,
                  startIcon: classes.startIcon
                }}
                variant={variant}
                fullWidth={fullWidth}
                onClick={onClick}
                endIcon={endIcon}
                startIcon={finalStartIcon}
                disabled={disabled || loading}
                {...other}
              >
                <Typography
                  color='inherit'
                  noWrap
                  className={classes.label}
                  data-test={`action-button-label-${label}'}`}
                >
                  {finalLabel}
                </Typography>
              </Button>
              )}
        </Grid>
      </div>
      {popper && <OnboardingPopper anchorEl={popperRef.current} enabled={popper.enabled} />}
    </>
  )
}

ActionButton.propTypes = {
  /** Appearence of the button */
  variant: PropTypes.string,
  /** Text to appear on the button (also used for ```aria-label``` if not provided) */
  label: PropTypes.string.isRequired,
  /** Size of the button. Affects padding and text size */
  size: PropTypes.string,
  /** Only show the button icon */
  hideText: PropTypes.bool,
  /** Icon placed before the text */
  startIcon: PropTypes.element,
  /** Only show the button icon */
  endIcon: PropTypes.element,
  /** Action to occur when button is clicked  */
  onClick: PropTypes.func
}

export default ActionButton
