import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation as baseUseTranslation } from 'react-i18next'
import { userSlice } from '../reducers'
import { updateI18nLanguage } from '../services/i18n'

const useTranslation = (...args) => {
  const language = useSelector(userSlice.selectors.selectSkillBiteLanguage)
  const response = baseUseTranslation(...args)

  useEffect(() => {
    const setLanguage = async (language) => {
      await updateI18nLanguage(language)
    }

    setLanguage(language)
  }, [language])

  return response
}

export default useTranslation
