import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastSlice, addToPlaylistSlice, playlistSlice } from '../../reducers'
import { Alert, AlertTitle, Toast, ToastLink } from '..'
import { routes } from '../../routes'
import { playlistUrl } from '../../helpers/url'
import AddToPlaylistDialog from './dialog'
import { useLocation, useNavigate } from 'react-router-dom'

const AddToPlaylistHelper = ({ article, backText }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [playlist, setPlaylist] = useState()

  const dialogOpen = useSelector(addToPlaylistSlice.selectors.selectShowDialog)

  const showAddToPlaylistToast = useSelector(state =>
    toastSlice.selectors.selectShowToast(state, 'addtoplaylist')
  )
  const showCreateSuccessToast = useSelector(state =>
    toastSlice.selectors.selectShowToast(state, 'playlist-create-success')
  )

  // Only close dialog explicitly. Not on backdrop click or hitting escape.
  const handleDialogClose = (evt, reason) => {
    if (!reason) {
      dispatch(addToPlaylistSlice.actions.showDialog(false))
    }
  }

  const handleToastClose = name => {
    dispatch(toastSlice.actions.hideToast(name))
    dispatch(addToPlaylistSlice.actions.reset())
  }

  const handleContentInserted = ({ playlist }) => {
    dispatch(addToPlaylistSlice.actions.showDialog(false))
    setPlaylist(playlist)
    dispatch(toastSlice.actions.showToast('addtoplaylist'))
  }

  const handlePlaylistCreate = () => {
    dispatch(playlistSlice.actions.setContent({ content: [article] }))
    dispatch(playlistSlice.actions.setBack({ url: location, text: backText }))
    handleDialogClose()
    navigate(routes['playlist-create'].path)
  }

  return (
    <>
      {showAddToPlaylistToast && (
        <Toast
          open
          autoHideDuration={5000}
          onClose={() => handleToastClose('addtoplaylist')}
        >
          <Alert severity='success'>
            <AlertTitle>Successully added to playlist</AlertTitle>
            <ToastLink to={playlistUrl(playlist)}>View playlist</ToastLink>
          </Alert>
        </Toast>
      )}
      {showCreateSuccessToast && (
        <Toast
          open
          autoHideDuration={5000}
          onClose={() => handleToastClose('playlist-create-success')}
        >
          <Alert severity='success'>
            <AlertTitle>The playlist has been saved</AlertTitle>
            <ToastLink to={routes.playlists.path}>View playlists</ToastLink>
          </Alert>
        </Toast>
      )}
      {dialogOpen && (
        <AddToPlaylistDialog
          open
          article={article}
          backText={backText}
          onContentInserted={handleContentInserted}
          onPlaylistCreate={handlePlaylistCreate}
          onClose={handleDialogClose}
        />
      )}
    </>
  )
}

export default AddToPlaylistHelper
