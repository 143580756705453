import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, typography, breakpoints, palette }) => ({
  root: {
    background: '#F8F9FD',
    padding: `${spacing(3)} ${spacing(5)}`,
    marginTop: spacing(1),
    [breakpoints.down('sm')]: {
      padding: `${spacing(1)} ${spacing(1)}`
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '560px',
    marginTop: spacing(2),
    marginBottom: spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '16px'
    }
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    marginTop: spacing(4),
    marginBottom: spacing(4),
    textAlign: 'center',
    maxWidth: '560px',
    [breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  message: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
    textAlign: 'center'
  }
}))
