import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '.'
import { qsStringify } from '../helpers/url'
import { routes } from '../routes'
import { has, omit } from 'lodash'

const useFilteredQuery = ({ handlers, arrayNames, filterNames }) => {
  const {
    location: { pathname },
    params
  } = useQuery({ handlers, arrayNames })

  const [filters, setFilters] = useState({})

  useEffect(() => {
    setFilters(params || {})
  }, [params])

  const makeUrl = useCallback(
    (newFilter, { routeKey, clear = false } = {}) => {
      const makeSearch = (filter, p) => qsStringify({ ...p, ...filter })

      const addStart = filter => ({
        ...filter,
        ...(!has(filter, 'start') && { start: 1 })
      })

      const path = routeKey ? routes[routeKey].path : pathname
      const retainedParams = !clear ? params : omit(params, filterNames)

      return `${path}?${makeSearch(addStart(newFilter), retainedParams)}`
    },
    [params, pathname, filterNames]
  )

  const clearFilters = useCallback(() => makeUrl(undefined, { clear: true }), [
    makeUrl
  ])

  const addFilter = useCallback((filter, opts) => makeUrl(filter, opts), [
    makeUrl
  ])

  return { params, filters, clearFilters, addFilter }
}

export default useFilteredQuery
