import name from './name'

const selectSliceData = state => state[name]

const selectResults = state => selectSliceData(state).results

const selectSuggestions = state => selectSliceData(state).suggestions

const selectHistory = state => selectSliceData(state).history

const selectTotal = state => selectSliceData(state).total

const selectCounts = state => selectSliceData(state).counts

export { selectResults, selectTotal, selectCounts, selectSuggestions, selectHistory }
