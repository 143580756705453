import name from './name'

const selectSliceData = state => state[name]

const selectCategory = state => selectSliceData(state).category

const selectChildren = state => selectSliceData(state).childrenCategories

const selectPath = state => selectSliceData(state).pathToRoot

const selectCategoryDefaultHierarchy = state =>
  selectSliceData(state).categoryDefaultHierarchy

const selectCategoryDefaultHierarchyError = state =>
  selectSliceData(state).categoryDefaultHierarchyError

export {
  selectCategory,
  selectChildren,
  selectPath,
  selectCategoryDefaultHierarchy,
  selectCategoryDefaultHierarchyError
}
