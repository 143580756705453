import React from 'react'
import { Card, CardActionArea } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    border: 0,
    borderRadius: 0,
    borderBottom: `1px solid ${palette.divider}`
  },
  body: {
    padding: spacing(1)
  }
}))

const ArticleCardContainer = ({ onSelect, article, children }) => {
  const classes = useStyles()
  return (
    <Card variant='outlined' classes={{ root: classes.root }}>
      <CardActionArea
        disabled={!article}
        onClick={() => onSelect(article)}
        className={classes.body}
        data-test={`article-container-${article?.friendlyUrl}`}
      >
        {children}
      </CardActionArea>
    </Card>
  )
}

export default ArticleCardContainer
