import React from 'react'
import {
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography
  , Skeleton
} from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import MenuHeader from './menuHeader'
import CategoryLink from './category-link'

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '&  .Mui-selected *': {
      fontWeight: 600
    },
    '& .Mui-selected': {
      border: 'none',
      backgroundColor: palette.action.selected
    },
    marginBottom: props => props.sideMenu ? spacing(2) : undefined
  },
  divider: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginTop: spacing(1),
    marginBottom: spacing(1)
  },
  gutters: {
    padding: spacing(1)
  },
  header: {
    padding: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(1)
  },
  itemIcon: {
    minWidth: 'auto'
  }
}))

const Menu = (props) => {
  const classes = useStyles(props)
  const { title, link, items, onChange, value } = props

  return (
    <>
      {(title || link) &&
        <MenuHeader title={title} link={link} onClick={onChange} />}
      <List className={classes.root}>
        {Array.isArray(items) && items.length === 0
          ? <ListItem button component='li'>No categories to show. Sorry!</ListItem>
          : (items || Array.from(new Array(5))).filter(opt => opt.name).map((opt, idx) =>
              opt?.divider
                ? (
                  <Divider
                    key={opt.id}
                    className={classes.divider}
                  />
                  )
                : (
                  <ListItem
                    button
                    component='li'
                    key={opt?.id ?? idx}
                    disabled={!opt}
                    onClick={() => onChange(opt)}
                    selected={value === opt}
                    classes={{ gutters: classes.gutters }}
                    data-test={`menu-item-${opt?.sk ?? 'loading'}`}
                  >
                    <ListItemText
                      primary={
                        <Typography noWrap>
                          {opt?.name
                            ? <CategoryLink category={opt} />
                            : <Skeleton width='90%' />}
                        </Typography>
                  }
                    />
                    {!opt?.noExpand && (
                      <ListItemIcon className={classes.itemIcon}>
                        <ChevronRight />
                      </ListItemIcon>
                    )}
                  </ListItem>
                  )
            )}
      </List>
    </>
  )
}

export default Menu
