import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette }) => ({
  root: ({ width, color }) => ({
    backgroundColor:
      color === 'primary' ? palette.primary.main : palette.grey.shade[100],
    height: 4,
    width
  })
}))

const HeaderUnderline = props => {
  const classes = useStyles(props)

  return <div className={classes.root} />
}

export default HeaderUnderline
