import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  image: {
    [breakpoints.up('xs')]: {
      marginBottom: spacing(4)
    },
    [breakpoints.up('sm')]: {
      marginBottom: spacing(8)
    }
  }
}))

const Image = ({ imageComponent: ImageComponent }) => {
  const classes = useStyles()

  return <ImageComponent className={classes.image} />
}

export default Image
