import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'

const ErrorDialog = ({ open, message = 'An error has occurred', onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth='sm'
    fullWidth
    aria-labelledby='error-dialog-title'
    aria-describedby='error-dialog-description'
  >
    <DialogTitle id='error-dialog-title'>Error</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color='secondary'
        variant='contained'
        data-test='error-dialog-close'
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

export default ErrorDialog
