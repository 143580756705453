import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, palette }) => {
  const border = `1px solid ${palette.grey[300]}`

  return {
    container: {
      position: 'relative',
      padding: spacing(2),
      border
    },
    edge: {
      position: 'absolute',
      top: 'calc(100% - 29px)',
      right: -30,
      width: 63,
      height: 64,
      transform: 'rotate(-42deg)',
      borderTop: border,
      backgroundColor: palette.common.white
    }
  }
})
