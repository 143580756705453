import { Box } from '@mui/material'
import React from 'react'

const Hide = ({ hidden, children, ...props }) => {
  return (
    <Box {...props} style={{ display: hidden ? 'none' : 'block' }}>
      {children}
    </Box>
  )
}

export default Hide
