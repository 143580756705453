import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastSlice } from '../reducers'
import useAlert from './useAlert'

const useReduxAlert = () => {
  const dispatch = useDispatch()
  const { enqueueMsg } = useAlert()

  const msgs = useSelector(toastSlice.selectors.selectShowStackAlerts)

  useEffect(() => {
    if (Array.isArray(msgs) && msgs.length) {
      msgs.forEach(({ msg, severity }) => enqueueMsg(msg, severity))
      dispatch(toastSlice.actions.unstackAlerts(msgs.map(({ id }) => id)))
    }
  }, [msgs, enqueueMsg, dispatch])
}

export default useReduxAlert
