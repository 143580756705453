import { stackAlert } from '../toast/helpers'
import * as actions from './slice'

export const getSkillBite = {
  eventName: 'skillBiteGet',
  onSuccess: {
    action: actions.setSkillBite,
    redux: true
  }
}

export const trackStartSession = {
  eventName: 'skillBiteTrackStartSession'
}

export const trackFinishSession = ({ onSuccess }) => {
  return {
    eventName: 'skillBiteTrackFinishSession',
    onSuccess: [
      onSuccess
    ],
    onError: [
      { action: stackAlert('Unable to mark this session as complete.', 'error'), redux: true }
    ]
  }
}

export const getSkillBiteSession = ({ onSuccess, onError }) => {
  return {
    eventName: 'skillBiteGetSession',
    onSuccess: [
      {
        action: actions.setSession,
        redux: true
      },
      onSuccess
    ],
    onError: [
      onError
    ]
  }
}

export const saveQuizAnswer = {
  eventName: 'skillBiteSaveQuizAnswer'
}

export const getPage = ({ onSuccess }) => {
  return {
    eventName: 'skillBiteGetPage',
    onSuccess: [
      {
        action: actions.setCurrentPageWithBlocks,
        redux: true
      },
      onSuccess
    ]
  }
}
