import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@mui/material'
import SimpleSearch from '../simple-search'
import SearchResults from './results'
import { ActionButton } from '../action-buttons'
import { CreateNewFolderTwoTone } from '@mui/icons-material'
import Header from './header'
import { useStyles } from './style'
import { useFormFactor } from '../../hooks'
import State from './state'

const AddToPlaylistView = ({
  onClose,
  items,
  selectedPlaylist,
  state,
  onSearch,
  searchInputProps,
  onPlaylistChange,
  onPlaylistCreate,
  onContentInsert
}) => {
  const classes = useStyles()

  const { isMobile } = useFormFactor()

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      aria-labelledby='addtoplaylist-dialog-title'
    >
      <DialogTitle className={classes.title}>
        <Header
          onClose={onClose}
          disabled={state !== State.idle}
          id='addtoplaylist-dialog-title'
        >
          My Playlists
        </Header>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleSearch
          onSearch={onSearch}
          disabled={state !== State.idle}
          variant='standard'
          placeholder='Search for playlists'
          fullWidth
          autoFocus
          {...searchInputProps}
        />
        <SearchResults
          playlists={items}
          onChange={onPlaylistChange}
          value={selectedPlaylist}
          disabled={state === State.inserting}
        />
      </DialogContent>
      <Divider variant='fullWidth' className={classes.divider} />
      <DialogActions className={classes.actions}>
        <ActionButton
          onClick={onPlaylistCreate}
          variant='secondary'
          label='Create new Playlist'
          startIcon={<CreateNewFolderTwoTone />}
          disabled={state !== State.idle}
          size='l'
          fullWidth={isMobile}
          data-test='addtoplaylist-create-playlist'
        />
        <ActionButton
          onClick={onContentInsert}
          variant='primary'
          label='Add here'
          disabled={state !== State.idle || !selectedPlaylist}
          loading={state === State.inserting}
          size='l'
          fullWidth={isMobile}
          data-test='addtoplaylist-add-to-playlist'
          className={classes.addButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddToPlaylistView
