import withStyles from '@mui/styles/withStyles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const InnerAccordion = withStyles(({ spacing, breakpoints }) => ({
  root: {
    border: 'none',
    width: '100%',
    boxShadow: 'none',
    '&:not(:first-child)': {
      marginTop: spacing(2),
      [breakpoints.down('sm')]: {
        marginTop: spacing(1)
      }
    },
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {}
}))(MuiAccordion)

export const InnerAccordionSummary = withStyles(({ palette }) => ({
  root: {
    backgroundColor: '#F5F5F5',
    minHeight: 52,
    '&$expanded': {
      minHeight: 52
    }
  },
  expandIcon: {
    '& > .MuiIconButton-label': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      color: '#18191A'
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiAccordionSummary)

export const InnerAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)
