import React from 'react'
import { Box, Typography, Link as ExternalLink } from '@mui/material'
import { ActionButton } from '../../action-buttons'
import { useExternalApp } from '../../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  cta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${palette.grey[300]}`,
    padding: spacing(1),
    marginTop: spacing(1),
    gap: spacing(1)
  },
  ctaSidePanel: {
    width: '280px',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    textAlign: 'center'
  },
  ctaTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: 0,
    paddingLeft: spacing(1),
    paddingRight: spacing(1)
  }
}))

const GoogleAdCta = ({ location }) => {
  const classes = useStyles()
  const { getMtUrl } = useExternalApp()
  const isSidePanelAd = location === 'article-view>article-view.js' || location === 'category-landing>index.js'

  return (
    <Box className={clsx(classes.cta, isSidePanelAd && classes.ctaSidePanel)}>
      <Typography className={classes.ctaTitle} variant='h6' gutterBottom>Let&apos;s join Mindtools to have an ad free experience!</Typography>
      <ActionButton
        className={classes.ctaButton}
        id='join-action'
        key='join-action'
        variant='join'
        component={ExternalLink}
        href={getMtUrl('join')}
        fullWidth
        label='Join Now!'
        data-test='google-ad-join-button'
      />
    </Box>
  )
}

export default GoogleAdCta
