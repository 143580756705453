import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ palette, typography, spacing, breakpoints, props }) => ({
    root: {
      transition: 'filter 0.2s',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      filter: props =>
        props?.hoverShadow &&
        'drop-shadow(0px 10px 10px #CCC9CF)'
    },
    card: {
      display: 'flex',
      clipPath: cardShape,
      borderRadius: 0,
      width: '100%',
      height: '100%'
    },
    edge: {
      width: '100%',
      height: '100%',
      paddingRight: 1,
      paddingBottom: 1,
      clipPath: cardShape,
      backgroundColor: palette.grey.shade[20]
    },
    stack: {
      boxSizing: 'border-box',
      height: 'inherit',
      clipPath: 'inherit',
      paddingRight: 5,
      paddingBottom: 5,
      backgroundColor: ({ number }) => {
        if (number === undefined) return
        const shades = Object.entries(palette.grey.shade)
        if (number >= shades.length) return
        return shades[number][1]
      }
    },
    header: {
      maxWidth: '100%',
      marginBottom: spacing(1)
    },
    info: {
      color: palette.text.secondary,
      fontWeight: typography.fontWeightMedium,
      '& svg': {
        marginRight: spacing(0.5)
      }
    },
    time: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold
    },
    actions: {
      marginTop: props => props.fullWidth ? 0 : spacing(3),
      marginBottom: spacing(2)
    },
    action: ({ isConsumer }) => ({
      ...(isConsumer && { color: palette.grey.shade[100] })
    })
  })
)

const cardShape =
  'polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0% 100%)'
