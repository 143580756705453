import React from 'react'
import { Outlet } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useStyles } from './style'

const PrimaryTemplate = () => {
  const classes = useStyles()

  return (
    <>
      <Grid item className={classes.mainContainer}>
        <main>
          <Outlet />
        </main>
      </Grid>
    </>
  )
}

export default PrimaryTemplate
