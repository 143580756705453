import { useEvent } from '@emerald-works/react-event-bus-client'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { bonusMaterialSlice, userSlice } from '../reducers'

const useBonusMaterial = () => {
  const bonusMaterials = useSelector(bonusMaterialSlice.selectors.selectAvailableBonusMaterials)
  const isBonusMaterialLoading = useSelector(bonusMaterialSlice.selectors.selectIsLoading)
  const profile = useSelector(userSlice.selectors.selectProfile)

  const [_updateLastAccessedBonusPage] = useEvent([userSlice.eventBus.updateLastAccessedBonusPage])

  const newestBonusMaterialCount = useMemo(() => {
    if (!profile || !bonusMaterials) return 0

    if (!profile.lastAccessedBonusPage) return bonusMaterials.length

    return bonusMaterials.filter(e => e.createdAt >= profile.lastAccessedBonusPage).length
  }, [bonusMaterials, profile])

  const updateLastAcessedBonusPage = useCallback(() => {
    _updateLastAccessedBonusPage.trigger({
      lastAccessedBonusPage: new Date().getTime()
    })
  }, [_updateLastAccessedBonusPage])

  return {
    bonusMaterials,
    isBonusMaterialLoading,
    newestBonusMaterialCount,
    updateLastAcessedBonusPage
  }
}

export default useBonusMaterial
