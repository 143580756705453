import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { MtLogo } from '../logos'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& > *': {
      marginBottom: spacing(2)
    }
  },
  divider: {
    height: 50,
    '&.MuiDivider-root': {
      backgroundColor: palette.grey.shade[50]
    },
    '&.MuiDivider-flexItem': {
      alignSelf: 'auto'
    }
  }
}))

const Logos = props => {
  const classes = useStyles(props)
  const narrow = useMediaQuery(({ breakpoints }) => breakpoints.down(325))

  return (
    <Grid
      container
      direction={narrow ? 'column' : 'row'}
      alignItems='center'
      justifyContent={narrow ? 'center' : 'space-around'}
      wrap='nowrap'
      className={classes.root}
    >
      <MtLogo />
    </Grid>
  )
}

export default Logos
