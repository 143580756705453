/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GeneralCard from '../general-card'
import { LinearProgress, Box, Typography, Avatar, Skeleton } from '@mui/material'
import {
  LikeButton,
  ResumePlaylistButton,
  StartPlaylistButton
} from '../../action-buttons'
import clsx from 'clsx'
import { useStyles } from './style'
import { useFormFactor } from '../../../hooks'
import { getAuthor } from '../../../helpers/playlist'
import EmptyImgPlaylist from '../../icons/empty-img-playlist.svg'
import { usePlaylistContext } from '../../playlist-progress/context'

const PlaylistCard = props => {
  const {
    content,
    onSelect,
    onLike,
    loadingLike,
    className = ''
  } = props

  const playlistItemCount = content?.content.length
  const { isDesktop } = useFormFactor()
  const classes = useStyles({ ...props, isDesktop })

  const { getProgress, playlistProgressData } = usePlaylistContext()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!playlistProgressData.length || !content) return
    const percent = getProgress(content?.pk)
    setProgress(percent)
  }, [playlistProgressData, getProgress, content])

  const cardContent = content && {
    ...content,
    primaryType: ['playlist'],
    subtitle: { html: content?.description }
  }

  const actions = {
    leftActions: playlistProgressData.length ? (
      !progress ? (
        <>
          <StartPlaylistButton size='xs' sx={{ mr: 2 }} onClick={onSelect} />
          <LikeButton
            likes={content?.likeCount ?? 0}
            hasLiked={content?.hasLiked}
            onClick={() => onLike(content.pk)}
            loading={loadingLike}
            size='xs'
          />
        </>
      ) : (
        <>
          <ResumePlaylistButton size='xs' sx={{ ml: 1, mr: 2 }} onClick={onSelect} />
          <LikeButton
            likes={content?.likeCount ?? 0}
            hasLiked={content?.hasLiked}
            onClick={() => onLike(content.pk)}
            loading={loadingLike}
            size='xs'
          />
        </>
      ))
      : (
        <Skeleton variant='rect' width='100px' height='30px' />
        )
  }

  const authorName = getAuthor(content)

  return (
    <GeneralCard
      content={cardContent}
      actions={actions}
      onSelect={onSelect}
      stack={2}
      expand
      defaultImage={EmptyImgPlaylist}
      componentProps={{ title: 'h2' }}
      className={clsx(classes.root, className)}
      classes={{
        root: classes.root,
        card: classes.card,
        title: classes.title,
        image: classes.image,
        summary: classes.summary,
        time: classes.time
      }}
      playlistItemCount={playlistItemCount}
      isPlaylist
    >
      {content?.author && (
        <Box display='flex' alignItems='center' mt={3} mb={0}>
          <Box mr={0.5}>
            <Avatar alt={authorName} className={classes.avatar} />
          </Box>
          <Typography variant='body2' color='textSecondary'>
            by {authorName}
          </Typography>
        </Box>
      )}
      {(content && playlistProgressData) ? (
        (playlistProgressData.length && progress > 0) ? (
          <Box display='flex' alignItems='center' mt={2} mb={-1}>
            <Box width='100%' mr={1}>
              <LinearProgress
                variant='determinate'
                value={Math.min(progress, 100)}
                aria-label={`playlist progress ${progress}%`}
                classes={{
                  root: classes.progress,
                  bar: classes.foreground,
                  colorPrimary: classes.background
                }}
              />
            </Box>
            <Box mt={2} minWidth={35}>
              <Typography variant='body2' color='textSecondary'>
                {`${Math.min(Math.round(progress), 100)}%`}
              </Typography>
            </Box>
          </Box>
        )
          : (
            <Typography variant='body2' color='textSecondary' mt={3} mb={0}>
              Unlock new skills today
            </Typography>
            )
      )
        : null}
    </GeneralCard>
  )
}
PlaylistCard.propTypes = {
  /** Content to be displayed on card */
  content: PropTypes.object,
  /** The user's current progress through the playlist */
  progress: PropTypes.number,
  /** Callback function fired when the card is selected  */
  onSelect: PropTypes.func
}
PlaylistCard.defaultProps = {
  size: 'small',
  expand: false
}

export default PlaylistCard
