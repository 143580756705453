import React from 'react'
import { CardMedia, Skeleton } from '@mui/material'
import ArticleSummary from './article-summary'
import ArticleCardContainer from './article-card-container'
import makeStyles from '@mui/styles/makeStyles'
import { image } from '../../placeholder'

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  image: {
    position: 'relative',
    width: '100%',
    height: 180
  },
  summary: () => {
    const left = spacing(4)
    const bodyMargin = spacing(1) / 2 // @@@ pass this in
    return {
      position: 'relative',
      width: `calc(100% - ${left}px + ${bodyMargin}px)`,
      top: spacing(-3),
      left,
      backgroundColor: palette.common.white,
      paddingTop: spacing(2),
      paddingLeft: spacing(2)
    }
  },
  title: {
    fontWeight: 700
  }
}))

const ArticleCardPortrait = ({ onSelect, article }) => {
  const classes = useStyles()
  return (
    <ArticleCardContainer article={article} onSelect={onSelect}>
      {article
        ? <CardMedia
            image={(article.image?.imgUrl ?? image) + 'h=360'}
            title='alt text here'
            className={classes.image}
            data-test={`article-card-image-${article?.friendlyUrl}`}
          />
        : <Skeleton variant='rectangular' className={classes.image} />}
      <ArticleSummary
        article={article}
        className={classes.summary}
        classes={{ title: classes.title }}
      />
    </ArticleCardContainer>
  )
}

export default ArticleCardPortrait
