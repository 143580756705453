import React from 'react'
import { List } from '@mui/material'
import { useStyles } from './style'

const HorizontalList = props => {
  const classes = useStyles(props)
  const { children, renderItem, variant, ...rest } = props
  return (
    <List disablePadding className={classes.root} {...rest}>
      {React.Children.map(children, renderItem)}
    </List>
  )
}

export default HorizontalList
