/* eslint-disable indent */
import { useMediaQuery } from '@mui/material'

/**
 * The designs for the app bar use their own responsive breakpoints that differ
 * from the rest of the app.
 */
const appbarBreakpoints = {
  mobile: 0,
  tablet: 768,
  smallDesktop: 1280,
  largeDesktop: 1440,
  extraLargeDesktop: 1920
}

const useFormFactor = (breakpoints = appbarBreakpoints) => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.tablet - 1}px)`)
  const isTablet = useMediaQuery(
    `(max-width:${breakpoints.smallDesktop - 1}px)`
  )
  const isSmallDesktop = useMediaQuery(
    `(max-width:${breakpoints.largeDesktop - 1}px)`
  )
  const isLargeDesktop = useMediaQuery(
    `(max-width:${breakpoints.extraLargeDesktop - 1}px)`
  )
  const isExtraLargeDesktop = useMediaQuery(
    `(min-width:${breakpoints.extraLargeDesktop}px)`
  )

  const formFactor = isMobile
    ? 'mobile'
    : isTablet
    ? 'tablet'
    : isSmallDesktop
    ? 'smallDesktop'
    : isLargeDesktop
    ? 'largeDesktop'
    : isExtraLargeDesktop
    ? 'extraLargeDesktop'
    : undefined

  return { formFactor, breakpoints }
}

export default useFormFactor
