import React from 'react'
import { useSession } from '@emerald-works/react-auth'

/**
 *
 * @param { children } - The component that requires permissions
 * @param { allowed } - Allow these groups access
 * @param { accessDeniedComponent } - An alternative component to display to users with denied access
 * @returns
 */
export const UserGroupPolicy = ({ accessGroup = [], componentAllow, componentDeny = null }) => {
  const { session } = useSession()
  const groups = session?.accessToken?.payload['cognito:groups']
  let allow

  if (groups) {
    groups.forEach(group => {
      accessGroup.forEach(role => {
        if (group.includes(role)) {
          allow = true
        }
      })
    })
  } else {
    allow = accessGroup.includes('browser')
  }

  return (
    <>
      {allow ? componentAllow : componentDeny}
    </>
  )
}
