import { useEffect, useState } from 'react'
import { useFormFactor } from '.'

const pageSizes = {
  isDesktop: 20,
  isLargeTablet: 15,
  isSmallTablet: 10,
  isMobile: 10
}

const usePage = (params, totalResults = 0, sizes = pageSizes) => {
  const formFactor = useFormFactor()

  const [pageStart, setPageStart] = useState(1)
  const [pageSize, setPageSize] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    const calcSize = () => {
      const factor = Object.keys(formFactor).find(p => formFactor[p])
      return factor && sizes[factor]
    }

    const calcStart = size => {
      const start = params.start || 1
      return start <= 0 ? 1 : start - ((start - 1) % size)
    }

    if (formFactor.isUnknown) return

    const size = calcSize()
    setPageSize(size)
    setPageStart(calcStart(size))
  }, [formFactor, params.start, sizes])

  useEffect(() => {
    if (!pageSize) return
    const count = Math.ceil(totalResults / pageSize)
    let current = Math.ceil(pageStart / pageSize)
    current = current > count ? count + 1 : current
    setPageCount(count)
    setPageCurrent(current)
  }, [totalResults, pageStart, pageSize])

  const calcPageStart = p => (p - 1) * pageSize + 1

  return {
    pageStart,
    pageSize,
    pageCurrent,
    pageCount,
    calcPageStart
  }
}

export default usePage
