import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import React from 'react'
import { useStyles } from './style'

const Busy = ({ open }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      PaperComponent='div'
      classes={{ paper: classes.paper, root: classes.root }}
    >
      <CircularProgress size={75} />
    </Dialog>
  )
}

export default Busy
