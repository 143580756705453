import {
  useEvent, useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import { Grid, Typography } from '@mui/material'
import { Flipper, Flipped } from 'react-flip-toolkit'
import React, { useEffect, useState } from 'react'
import { ActionButton } from '../..'
import CommentGroup from '../comment-group'
import { useStyles } from './style'
import { commentSlice, contentSlice } from '../../../reducers'
import Hide from '../../hide'
import { useSelector } from 'react-redux'

const PAGE_SIZE = 6

/** Displays a list of comments */
const CommentSection = props => {
  const { comments, total, sort, onSort, loading, ...other } = props
  const { locationId } = useSelector(contentSlice.selectors.selectContent)
  const classes = useStyles(props)

  const [page, setPage] = useState(1)
  const showLoadMore = page * PAGE_SIZE < total

  const [commentAdded, commentRemoved] = useEvent([
    commentSlice.eventBus.commentAdded,
    commentSlice.eventBus.commentRemoved
  ])

  useEventsOnViewLoad(() => {
    if (locationId) {
      commentAdded.subscribeToEvent({ id: locationId })
      commentRemoved.subscribeToEvent({ id: locationId })
    }
  }, [locationId, commentAdded, commentRemoved])

  const handleLoadMore = () => setPage(prev => prev + 1)

  useEffect(() => {
    if (sort) setPage(1)
  }, [sort])

  return (
    /* eslint-disable multiline-ternary */
    <Flipper flipKey={comments}>
      <Grid
        container
        item
        direction='column'
        className={classes.root}
        spacing={3}
        data-test='comment-section'
      >
        <Grid
          container
          item
          wrap='nowrap'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography
              className={classes.header}
              data-test='comment-section-header'
            >
              Comments ({total ?? 0})
            </Typography>
          </Grid>
          {/* todo: add when the api supports the functionality
          <Grid item>
            <Box
              flexDirection='row'
              display='flex'
              alignItems='center'
              gridGap='5px'
              flex='1'
            >
              {total > 1 && (
                <ToggleButtonGroup
                  value={sort}
                  onChange={onSort}
                  exclusive
                  size='small'
                  aria-label='order by'
                  className={classes.sorter}
                  data-test='comment-section-sorter'
                >
                  <ToggleButton
                    value='newest'
                    aria-label='newest'
                    data-test='comment-section-newest-button'
                  >
                    <Typography>Newest</Typography>
                  </ToggleButton>
                  <ToggleButton
                    value='likes'
                    aria-label='most likes'
                    data-test='comment-section-likes-button'
                  >
                    <Typography>Likes</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Box>
          </Grid> */}
        </Grid>
        <Grid
          container
          item
          direction='column'
          spacing={3}
          className={classes.comments}
          data-test='comment-section-list'
        >
          {total !== 0 ? (
            <>
              {comments
                .map((comment, idx) => (
                  <Flipped key={comment?.id ?? idx} flipId={comment?.id ?? idx}>
                    <Hide
                      component={Grid}
                      item
                      hidden={idx >= PAGE_SIZE * page}
                    >
                      <CommentGroup comment={comment} {...other} />
                    </Hide>
                  </Flipped>
                ))}
              {!loading && showLoadMore && (
                <Grid item>
                  <ActionButton
                    label='Load more comments'
                    variant='link'
                    fullWidth
                    onClick={handleLoadMore}
                    className={classes.loadMore}
                    data-test='comment-section-load-more-button'
                  />
                </Grid>
              )}
            </>
          ) : (
            <Grid item>
              <Typography data-test='comment-section-empty-text'>
                Be the first to comment!
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Flipper>
  )
}

export default CommentSection
