import { useEvent as useCorvusEvent } from '@emerald-works/react-event-bus-client'
import { useSEO } from '../components/seo-context'

const useEventSEO = (events = []) => {
  const eventManagers = useCorvusEvent(events)

  const completed = eventManagers.every(
    ({ hasBeenTriggered, isWorking }) => hasBeenTriggered && !isWorking
  )

  useSEO(completed)

  return eventManagers
}

export default useEventSEO
