import React from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { homeSlice } from './../../reducers'
import { useTenant } from '@emerald-works-nova/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PromotedContentMainCard, PromotedContentSecondaryCard, PromotedContentGenericCard } from './../general-card/promoted-content-card'
import { useFormFactor, useGroup } from '../../hooks'
import { useAddToBookmarks } from '../add-to-bookmarks'
import { useLike } from '../like'
import { ALL_CONSUMER } from '../../services/cognito/userGroups'
import HeaderUnderline from '../header-underline'
import WithLoading from '../with-loading'

const useDesktopStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridColumnGap: '24px',
    gridRowGap: '24px'
  },
  item1: { gridArea: '1 / 1 / 3 / 10' },
  item2: { gridArea: '1 / 10 / 3 / 13' },
  item3: { gridArea: '3 / 1 / 4 / 5' },
  item4: { gridArea: '3 / 5 / 4 / 9' },
  item5: { gridArea: '3 / 9 / 4 / 13' }
}))

const useTabletStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    gridColumnGap: '24px',
    gridRowGap: '24px'
  },
  item1: { gridArea: '1 / 1 / 3 / 3' },
  item2: { gridArea: '3 / 1 / 4 / 2' },
  item3: { gridArea: '3 / 2 / 4 / 3' },
  item4: { gridArea: '4 / 1 / 5 / 2' },
  item5: { gridArea: '4 / 2 / 5 / 3' }
}))

const useMobileStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(6, 1fr)',
    gridColumnGap: '24px',
    gridRowGap: '24px'
  },
  item1: { gridArea: '1 / 1 / 3 / 2' },
  item2: { gridArea: '3 / 1 / 4 / 2' },
  item3: { gridArea: '4 / 1 / 5 / 2' },
  item4: { gridArea: '5 / 1 / 6 / 2' },
  item5: { gridArea: '6 / 1 / 7 / 2' }
}))

const PromotedContent = ({ onCardSelect, onAddToPlaylist }) => {
  const tenant = useTenant()
  const dispatch = useDispatch()
  const [getPromotedContent] = useEvent([homeSlice.eventBus.getPromotedContent])
  const promotedContent = useSelector(homeSlice.selectors.selectPromotedContent)
  const promotedContentHasBeenTriggered = useSelector(homeSlice.selectors.selectPromotedContentHasBeenTriggered)

  const { isSmallTablet, isLargeTablet, isMobile, isDesktop } = useFormFactor()

  const { isBookmarked, handleBookmark, bookmarking } = useAddToBookmarks(
    promotedContent
  )

  const { handleLike, liking } = useLike({
    onSuccess: payload => dispatch(homeSlice.actions.toggleRecommend(payload))
  })

  const desktopClasses = useDesktopStyles()
  const tabletClasses = useTabletStyles()
  const mobileClasses = useMobileStyles()

  const isSmallScreen = isSmallTablet || isMobile
  let classes = desktopClasses
  if (isLargeTablet) {
    classes = tabletClasses
  } else if (isSmallScreen) {
    classes = mobileClasses
  }

  const { isInGroup } = useGroup()
  const isConsumer = isInGroup(ALL_CONSUMER)
  const title = 'Featured Content'

  useEventsOnViewLoad(() => {
    if (tenant && tenant.baseTemplate && !promotedContentHasBeenTriggered) {
      getPromotedContent.trigger()
    }
  }, [getPromotedContent, tenant, promotedContentHasBeenTriggered])

  const contentList = getPromotedContent.isWorking ? new Array(5).fill(null) : promotedContent

  const emptyPromotedContent = !promotedContent.length && !getPromotedContent.isWorking

  if (emptyPromotedContent) {
    return null
  }

  return (
    <>
      <Box mt={4} mb={2}>
        <WithLoading loading={getPromotedContent.isWorking}>
          <h1 style={isConsumer ? { marginBottom: 0 } : {}}>{title}</h1>
          {isConsumer && <HeaderUnderline width={64} color='primary' />}
        </WithLoading>
      </Box>
      <Grid container className={classes.root}>
        {contentList.map((item, index) => {
          const componentProps = {
            onSelect: () => onCardSelect(item?.content),
            position: index,
            onAddToPlaylist,
            content: item?.content,
            size: index > 1 ? 'mobile' : 'large',
            testId: `promoted-content-card-${index}`,
            onLike: () => handleLike(item || {}),
            onBookmark: () => handleBookmark(item || {}),
            bookmarked: isBookmarked(item || {}),
            actionLoading: {
              like: liking(item || {}),
              bookmark: bookmarking(item || {})
            }
          }

          let component = <PromotedContentGenericCard {...componentProps} />

          if (index === 0) {
            component = <PromotedContentMainCard {...componentProps} />
          } else if (index === 1 && isDesktop) {
            component = <PromotedContentSecondaryCard {...componentProps} />
          }

          return (
            <Grid
              item
              className={classes[`item${index + 1}`]}
              key={item?.content?.friendlyUrl ?? index}
            >
              {component}
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default PromotedContent
