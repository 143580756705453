import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { contentSlice, playlistNavigationSlice, playlistSlice } from '../reducers'

const usePlaylistNavigation = () => {
  const [playlistContextId, setPlaylistContextId] = useState()
  const { detail, content } = useSelector(
    playlistSlice.selectors.selectPlaylist
  )
  const { playlistId } = useSelector(
    playlistNavigationSlice.selectors.selectPlaylistId
  )
  const [getPlaylist, getPlaylistContent] = useEvent([
    playlistSlice.eventBus.getPlaylist,
    playlistSlice.eventBus.getPlaylistContent
  ])
  const articleContent = useSelector(contentSlice.selectors.selectContent)
  const [playlistBreadcrumbs, setPlaylistBreadcrumbs] = useState({})

  useEffect(() => {
    if (detail && articleContent) {
      setPlaylistBreadcrumbs({
        ...articleContent,
        ...{
          categoryPath: [{
            name: 'Playlists',
            isVisible: true,
            parentFriendlyUrl: 'ROOT_CATEGORY_PARENT',
            sk: '1'
          }, {
            name: detail.title,
            sk: 'playlists',
            staticUrl: detail.pk.split('playlist#').join(''),
            isVisible: true
          }]
        }
      })
    }
  }, [detail, articleContent, setPlaylistBreadcrumbs])

  useEventsOnViewLoad(() => {
    if (playlistId) {
      setPlaylistContextId(playlistId)
      getPlaylistContent.trigger({ playlistId: `${playlistId}`, triggeredBy: 'playlist-nav' })
      getPlaylist.trigger({ playlistId: `${playlistId}`, triggeredBy: 'playlist-nav' })
    }
  }, [playlistId])

  return {
    playlistContextId,
    playlistDetail: detail,
    playlistContent: content,
    playlistBreadcrumbs
  }
}

export default usePlaylistNavigation
