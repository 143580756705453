import { useEffect, useState, useCallback } from 'react'

const useSearchTabs = (labels, filters) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  useEffect(() => {
    const getIndex = (param = '') => {
      const index = labels.findIndex(({ value }) => value === param)
      return index >= 0 ? index : 0
    }

    const index = getIndex(filters?.contenttype)
    setCurrentTabIndex(index)
  }, [labels, filters])

  const getTabFilter = useCallback(index => labels[index].value, [labels])

  return {
    currentTabIndex,
    getTabFilter,
    tabLabels: labels.map(({ label }) => label)
  }
}

export default useSearchTabs
