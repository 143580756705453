import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  logo: {
    marginBottom: theme.spacing(2)
  },
  loading_wrapper: {
    maxWidth: 400,
    marginBottom: theme.spacing(5)
  },
  title1: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  },
  title2: {
    marginBottom: theme.spacing(5)
  }
}))
