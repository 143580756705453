import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  playlistId: undefined
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setPlaylistId: (state, { payload }) => {
      state.playlistId = payload
    }
  }
})

export const {
  setPlaylistId
} = content.actions

export default content.reducer
