import React from 'react'
import { SnackbarProvider } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import ReduxAlertProvider from './redux-provider'

// Prevents the toasts being 100% and/or left justified on mobile.
const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: {
    width: 'auto',
    '& .MuiCollapse-wrapperInner': {
      width: 'auto'
    },
    alignItems: 'flex-end'
  }
}))

const AlertProvider = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      classes={{ containerRoot: classes.root }}
      {...props}
    >
      <ReduxAlertProvider />
      {children}
    </SnackbarProvider>
  )
}

export default AlertProvider
