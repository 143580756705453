import React from 'react'
import { MtLogo } from '../logos'
import { Typography, LinearProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Helmet } from 'react-helmet'
import { useStyles } from './style'

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 5,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#E45C0F'
  }
}))(LinearProgress)

const AppLoading = () => {
  const classes = useStyles()
  return (
    <>
      <Helmet>
        <meta name='prerender-status-code' content='504' />
      </Helmet>
      <div className={classes.root}>
        <div className={classes.loading_wrapper}>
          <MtLogo width={236} height={59} className={classes.logo} />
          <Typography
            variant='h5'
            align='center'
            aria-level='1'
            className={classes.title1}
          >
            Toolkit
          </Typography>

          <Typography variant='body2' align='center' className={classes.title2}>
            Loading your website experience
          </Typography>
          <BorderLinearProgress />
        </div>
      </div>
    </>
  )
}

export default AppLoading
