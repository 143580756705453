import * as React from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { playlistProgressSlice } from '../../reducers'
import { useSelector } from 'react-redux'

export const PlaylistProgressContext = React.createContext()

export const PlaylistProgressProvider = ({ children }) => {
  const [getPlaylistsProgress] = useEvent([
    playlistProgressSlice.eventBus.getPlaylistsProgress
  ])
  const playlistIds = useSelector(playlistProgressSlice.selectors.selectPlaylistIds)

  React.useEffect(() => {
    if (!playlistIds.length) return
    getPlaylistsProgress.trigger({ playlistIds })
  }, [getPlaylistsProgress, playlistIds])

  const playlistProgressData = useSelector(playlistProgressSlice.selectors.selectPlaylistProgressData)

  const isMarkedAsRead = (playlistId, contentId) => {
    const playlist = playlistProgressData.find(playlist => playlist.playlistId === playlistId)
    return playlist?.read.includes(contentId) || false
  }

  const isComplete = (playlistId) => {
    const playlist = playlistProgressData.find(playlist => playlist.playlistId === playlistId)
    return Math.round(playlist?.percent) === 100
  }

  const getProgress = (playlistId) => {
    const playlist = playlistProgressData.find(playlist => playlist.playlistId === playlistId)
    if (!playlist) return 0
    let percent = playlist.percent
    percent = (!percent || isNaN(percent)) ? 0 : percent
    percent = Math.round(percent)
    return percent
  }

  return (
    <PlaylistProgressContext.Provider value={{ isMarkedAsRead, getProgress, playlistProgressData, isComplete }}>
      {children}
    </PlaylistProgressContext.Provider>
  )
}

export const usePlaylistContext = () => {
  const context = React.useContext(PlaylistProgressContext)
  if (!context) {
    throw new Error(
      'usePlaylistContext must be used within the scope of PlaylistProgressProvider'
    )
  }
  return context
}
