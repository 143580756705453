import { merge as deepMerge } from 'lodash'
import { HTML_FONT_SIZE, FONT_SIZE, DEFAULT_FONTSIZE } from './settings'
import * as helpers from './helpers'
import custom from './custom'

// This is the base typography that will be used to build the theme.
// `typography` param option will either override or add values to
// the typography property of theme object that is inject for the entire application.

// Add new properties as needed and all application components will have the
// new proprity available in the `theme.typography` object

// For more information about each property and what it means:
// https://material-ui.com/customization/typography/

export default (options = {}) => {
  return (palette) => {
    const baseTypography = {
      htmlFontSize: HTML_FONT_SIZE,
      fontSize: FONT_SIZE,
      fontFamily: DEFAULT_FONTSIZE,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      // a collection of properties to be applied to all default material ui variants.
      // defaults: h1-h6, subtitle1, subtitle2, body1, body2, button, caption, overline
      // you can also add proprites to a specific variant like the h1 example.
      // for more information: https://material-ui.com/customization/typography/#variants
      allVariants: {},
      ...custom,
      h1: {
        fontSize: 96
      },
      breadcrumb: {
        fontSize: '1rem'
      },
      // Add extra typography elements here.
      // palette object is pass as a param so any custom color applied to
      // some typography element should be added there and then used it here
      customTitle: {
        // transform pixel to rem.
        // Responsive font sizes based on htmlFontSize.
        fontSize: helpers.pxToRem(30),
        color: palette.text.customColor
      },
      button: {
        textTransform: 'none'
      },
      ...helpers
    }

    return deepMerge(baseTypography, options)
  }
}
