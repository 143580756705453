import React from 'react'
import { Pagination as MuiPagination, Grid } from '@mui/material'
import PaginationItem from './pagination-item'
import { Link } from 'react-router-dom'

const getLink = (item, size) => {
  const query = new URLSearchParams(location.search)
  const start = (item.page - 1) * size + 1
  query.set('start', start < 1 ? 1 : start)
  return `${location.pathname}?${query.toString()}`
}

const Pagination = ({
  page,
  count,
  size,
  onChange,
  disabled,
  className = ''
}) => (
  <Grid container item justifyContent='center' className={className}>
    <MuiPagination
      page={page}
      count={count}
      onChange={onChange}
      disabled={disabled}
      color='primary'
      shape='rounded'
      data-test='pagination'
      siblingCount={0}
      renderItem={item => (
        <PaginationItem {...item} component={Link} to={getLink(item, size)} />
      )}
    />
  </Grid>
)

export default Pagination
