import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const togglePlaylistContentRead = {
  eventName: 'playlistContentToggleReadStatus',
  onSuccess: {
    action: actions.togglePlaylistContentRead,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to update content read', 'error'),
    redux: true
  }
}

export const getPlaylistsProgress = {
  eventName: 'playlistGetProgress',
  onSuccess: {
    action: actions.setProgress,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to fetch playlist progress', 'error'),
    redux: true
  }
}
