import React from 'react'
import { Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

// Internal links via react router. Destination via 'to' prop.
const Link = React.forwardRef((props, ref) => (
  <MuiLink
    ref={ref}
    component={RouterLink}
    color={props.color || 'inherit'}
    {...props}
    underline='hover'
  />
))
Link.displayName = 'Link'

export default Link
