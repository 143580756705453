import React from 'react'
import Menu from './menu'
import ExploreLayout from './layout'
import ArticleList from './article-list'
import ArticleCard from './article-card'
import { useFormFactor } from '../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import Hide from '../hide'

// Vertically align the top of the menu to the top of the recommended section in desktop.
// This can be removed when the 'browse all' link is put back.
const useStyles = makeStyles(({ palette, spacing }) => ({
  menu: {
    paddingTop: 0,
    marginTop: -10
  }
}))

const CategoryView = ({
  group,
  categories = {},
  onCategorySelect,
  related,
  recommended,
  onArticleSelect,
  onClose
}) => {
  const classes = useStyles()
  const { isDesktop } = useFormFactor()

  // render all categories to dom for SEO crawlability of links
  // visibility controlled by styles
  const categoryList = Object.entries(categories).map(([groupKey, children]) =>
    <Hide key={groupKey} hidden={groupKey !== group?.sk}>
      <Menu
        items={children}
        onChange={onCategorySelect}
        classes={{ root: isDesktop && classes.menu }}
      />
    </Hide>
  )

  return (
    <ExploreLayout
      title={group?.name}
      onClose={onClose}
      panelCtnLgSize={12}
      bannerLgSize={8}
      showBannerRight
      panel1={categoryList}
      panel1LgSize={4}
      panel2LgSize={8}
      panel2={
        <ArticleList
          items={recommended}
          title='Recommended for you'
          listDirection={isDesktop ? 'row' : 'column'}
          changeSecondPosition
          renderArticle={article => (
            <ArticleCard
              variant='portrait'
              article={article}
              onSelect={onArticleSelect}
            />
          )}
        />
        }
    />
  )
}

export default CategoryView
