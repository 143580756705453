import React from 'react'
import { Grid, Box, Typography, Divider, Skeleton } from '@mui/material'
import {
  OndemandVideoTwoTone
} from '@mui/icons-material'
import clsx from 'clsx'
import { useStyles } from './style'
import { contentUrl } from '../../../helpers/url'
import { useNavigate } from 'react-router-dom'
import SectionHeader from './section-header'
import WithLoading from '../../with-loading'
import { isEmpty } from 'lodash'
import { pluralize } from '../../../helpers/plural'

const VideoHeader = props => {
  const classes = useStyles(props)
  const { count = 0 } = props
  const text = `${count} ${pluralize('video', count)}`
  return (
    <SectionHeader classes={{ root: classes.videoSectionHeader }}>
      <Box component='span' display='inline-block' className={classes.videoSectionHeaderFirst}>{text} to watch in </Box>
      <Box>
        <Box component='span' fontWeight='fontWeightBold' className={classes.videoSectionHeaderSecond}>
          5 minutes or less
        </Box>
      </Box>
    </SectionHeader>
  )
}

const VideoItem = props => {
  const navigate = useNavigate()
  const classes = useStyles(props)
  const {
    content,
    showDivider
  } = props

  let contentImage = ''

  let contentTime = '' // default read time

  if (content?.readTime) {
    // eslint-disable-next-line
    contentTime = content?.readTime + ' mins'
  }

  if (content?.image?.imgUrl) {
    contentImage = content?.image?.imgUrl + 'fit=crop&w=327&h=184'
  }
  return (
    <Grid container spacing={2} onClick={() => navigate(contentUrl(content))} className={classes.itemContainer}>
      <Grid item xs={12}>
        {content
          ? (
            <Box className={classes.videoContainer}>
              <img
                src={contentImage}
                title={content?.image?.altText}
                data-test={`video-card-image-${content?.friendlyUrl}`}
                className={clsx(classes.imageVideo, classes.imageVideoBase)}
              />
              <OndemandVideoTwoTone className={classes.videoIcon} />
            </Box>
            )
          : (
            <Skeleton variant='rectangular' className={classes.imageVideoBase} />
            )}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.videoTitle} noWrap>
          {content ? content.title : <Skeleton width={200} />}
        </Typography>
        {/* TODO when the videos have the time that it takes to see them */}
        {/* <Typography className={classes.videoTime}>
          {content ? contentTime : <Skeleton width={200} />}
        </Typography> */}
      </Grid>
      {showDivider && (
        <Grid item xs={12}>
          <Divider className={classes.videoDivider} />
        </Grid>
      )}
    </Grid>
  )
}

const VideoContent = props => {
  const {
    content,
    isConsumer
  } = props

  const contentList = content?.length > 0 ? content : new Array(3)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WithLoading loading={isEmpty(content)}>
          <VideoHeader isConsumer={isConsumer} count={content?.length} />
        </WithLoading>
      </Grid>
      <Grid item xs={12}>
        {contentList.map((x, i) => {
          const isLast = i === contentList.length - 1

          return (
            <div key={i}>
              <VideoItem content={x} showDivider={!isLast} />
            </div>
          )
        })}
      </Grid>
    </Grid>
  )
}
export default VideoContent
