import { format, parseISO } from 'date-fns'

// @@@ Dummy data for now
const useCitation = (content, url) => {
  if (!content) return
  const { title, createdAt } = content
  const site = 'MindTools.com'
  // @@@ createdAt used for now. Almost certainly wrong. Copyright date maybe?
  const year = format(parseISO(createdAt), 'yyyy')
  const today = format(new Date(), 'd MMMM yyyy')

  return `${site}. (${year}). ${title}. [Online]. Available from: ${url}. Accessed: ${today}.`
}

export default useCitation
