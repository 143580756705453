import React from 'react'
import { Divider, Grid } from '@mui/material'
import Container from '../container'
import LinkPanel from './link-panel'
import Legal from './legal'
import makeStyles from '@mui/styles/makeStyles'
import ContentLinks from './content-links'
import { useTenant } from '@emerald-works-nova/auth'

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    paddingBottom: spacing(7)
  },
  consumerContent: {
    paddingBottom: spacing(7),
    backgroundColor: palette.grey.shade[5]
  },
  divider: {
    backgroundColor: palette.grey.shade[20],
    marginBottom: spacing(7),
    width: '100%',
    maxWidth: 1440
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: spacing(5),
    paddingRight: spacing(5)
  }
}))

const PageFooter = () => {
  const classes = useStyles()
  const tenant = useTenant()
  const isConsumer = React.useMemo(() => tenant?.tenantKey === 'www', [tenant])
  return (
    <Container>
      <Grid
        container
        direction='column'
        alignItems='stretch'
        className={isConsumer ? classes.consumerContent : classes.content}
      >
        {isConsumer && (
          <Grid item>
            <ContentLinks />
          </Grid>
        )}
        <Grid item>
          <Container inset={2}>
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </div>
            <LinkPanel />
          </Container>
        </Grid>
      </Grid>
      <Grid item>
        <Legal />
      </Grid>
    </Container>
  )
}

export default PageFooter
