import React from 'react'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import ClipboardText from '../clipboard-text'
import { useStyles } from './style'

const CiteDialog = ({ citation, locationId, open, onClose, onCopy }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      aria-labelledby='cite-dialog-title'
    >
      <DialogTitle id='cite-dialog-title'>Citation</DialogTitle>
      <DialogContent>
        <ClipboardText text={citation} locationId={locationId} from='cite-modal' onCopy={onCopy} multiline />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color='primary' data-test='cite-dialog-close'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CiteDialog
