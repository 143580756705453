import name from './name'
import { createSlice } from '@reduxjs/toolkit'

const localStorageKey = 'mt3-newsletter-subscribed'

const newsletter = createSlice({
  name,
  initialState: {
    newsletterPopoverOpen: false,
    isSubscribed: localStorage.getItem(localStorageKey)
  },
  reducers: {
    toggleNewsletterPopover: (state) => {
      state.newsletterPopoverOpen = !state.newsletterPopoverOpen
    },
    closeNewsletterPopover: (state) => {
      state.newsletterPopoverOpen = false
    },
    setNewsletterSubscribed: (state) => {
      state.isSubscribed = true
      localStorage.setItem(localStorageKey, true)
    }
  }
})

export const { toggleNewsletterPopover, closeNewsletterPopover, setNewsletterSubscribed } = newsletter.actions

export default newsletter.reducer
