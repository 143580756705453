import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing }) => {
  const variableWidthItems = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
  const fixedWidthItems = {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, 1fr)',
    gap: spacing(1),
    // Ensure all buttons take up all available space.
    // fullWidth doesn't hit the grid item that contains the button.
    '& .MuiGrid-item': {
      width: '100%'
    }
  }
  return {
    root: ({ variant }) =>
      variant === 'fixedWidthItems' ? fixedWidthItems : variableWidthItems
  }
})
