import React from 'react'
import { Grid } from '@mui/material'

const ShareButtons = ({ buttons, className }) => {
  return (
    <Grid container alignItems='center' spacing={3} className={className}>
      {buttons}
    </Grid>
  )
}

export default ShareButtons
