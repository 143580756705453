import React from 'react'
import PropTypes from 'prop-types'
import GeneralCard from '../general-card'
import { useFormFactor } from '../../../hooks'
import { useStyles } from './style'
import clsx from 'clsx'

const BaseContentCard = props => {
  const { size, actions, className = '', ...cardProps } = props

  const { isDesktop } = useFormFactor()
  const classes = useStyles({ ...props, size: isDesktop ? size : 'mobile' })

  return (
    <GeneralCard
      actions={actions}
      className={clsx(classes.root, className)}
      {...cardProps}
    />
  )
}

BaseContentCard.propTypes = {
  /** The size of the overall card */
  size: PropTypes.oneOf(['small', 'large', 'mobile']),
  /** Card actions */
  actions: PropTypes.exact({
    leftActions: PropTypes.element,
    rightActions: PropTypes.element
  })
}

BaseContentCard.defaultProps = {
  size: 'small'
}

export default BaseContentCard
