import { difference, intersection, union } from 'lodash'
import * as Usergroups from '../../../services/cognito/userGroups'
import {
  ALLOW_ADMIN_TOOLS,
  ALLOW_BOOKMARKS_GROUP,
  ALLOW_PLAYLISTS_GROUP,
  ALLOW_PROFILE_GROUP,
  ALLOW_REPORTING_GROUP,
  ALLOW_SKILL_BITES,
  ALLOW_USER_PAGE_GROUP
} from '../../user-group-policy/permissions'

const ALLOW_ALL = [
  ...Usergroups.ALL_CONSUMER,
  ...Usergroups.ALL_ENTERPRISE,
  Usergroups.ADMIN
]

const ALLOW_ALL_NON_CONSUMER = [...Usergroups.ALL_ENTERPRISE, Usergroups.ADMIN]

const ALLOW_NON_CONSUMER_PLAYLISTS = intersection(
  ALLOW_PLAYLISTS_GROUP,
  ALLOW_ALL_NON_CONSUMER
)

const ALLOW_NON_CONSUMER_BOOKMARKS = intersection(
  ALLOW_BOOKMARKS_GROUP,
  ALLOW_ALL_NON_CONSUMER
)

const ALLOW_NON_CONSUMER_PROFILE = intersection(
  ALLOW_PROFILE_GROUP,
  ALLOW_ALL_NON_CONSUMER
)

const ALLOW_NON_CONSUMER_SKILL_BITES = union(
  ALLOW_SKILL_BITES,
  ALLOW_ALL_NON_CONSUMER
)

export const ALLOW = {
  mobile: {
    menu: {
      mindsetai: ALLOW_ALL,
      howitworks: [Usergroups.BROWSER, Usergroups.FREE],
      blog: Usergroups.ALL_CONSUMER,
      bonusmaterial: [Usergroups.CONSUMER],
      newsletter: Usergroups.BROWSER,
      forbusiness: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      skillbites: ALLOW_NON_CONSUMER_SKILL_BITES,
      msa: ALLOW_ALL_NON_CONSUMER,
      playlists: ALLOW_PLAYLISTS_GROUP,
      bookmarks: ALLOW_BOOKMARKS_GROUP,
      reporting: ALLOW_REPORTING_GROUP,
      usermanagement: ALLOW_USER_PAGE_GROUP,
      profile: ALLOW_PROFILE_GROUP,
      logout: Usergroups.ALL_AUTHENTICATED_CONSUMER
    },
    startmenubuttons: {
      explore: ALLOW_ALL
    },
    endmenubuttons: {
      join: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      signin: [Usergroups.BROWSER]
    }
  },
  tablet: {
    linkbar: {
      more: ALLOW_ALL,
      mindsetai: ALLOW_ALL
    },
    menu: {
      howitworks: [Usergroups.BROWSER, Usergroups.FREE],
      bonusmaterial: [Usergroups.CONSUMER],
      blog: Usergroups.ALL_CONSUMER,
      newsletter: Usergroups.BROWSER,
      forbusiness: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      skillbites: ALLOW_NON_CONSUMER_SKILL_BITES,
      msa: ALLOW_ALL_NON_CONSUMER,
      playlists: ALLOW_PLAYLISTS_GROUP,
      bookmarks: ALLOW_BOOKMARKS_GROUP,
      reporting: ALLOW_REPORTING_GROUP,
      usermanagement: ALLOW_USER_PAGE_GROUP,
      profile: ALLOW_PROFILE_GROUP,
      logout: Usergroups.ALL_AUTHENTICATED_CONSUMER
    },
    endmenubuttons: {
      join: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      signin: [Usergroups.BROWSER]
    }
  },
  smallDesktop: {
    actionbar: {
      divider: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED]
    },
    linkbar: {
      howitworks: [Usergroups.BROWSER, Usergroups.FREE],
      blog: Usergroups.ALL_AUTHENTICATED_CONSUMER,
      newsletter: Usergroups.BROWSER,
      bonusmaterial: [Usergroups.CONSUMER],
      skillbites: ALLOW_NON_CONSUMER_SKILL_BITES,
      msa: ALLOW_ALL_NON_CONSUMER,
      playlists: [
        Usergroups.CONSUMER,
        Usergroups.MARKETING,
        ...ALLOW_NON_CONSUMER_PLAYLISTS
      ],
      bookmarks: ALLOW_NON_CONSUMER_BOOKMARKS,
      admintools: ALLOW_ADMIN_TOOLS,
      profile: ALLOW_NON_CONSUMER_PROFILE,
      more: [Usergroups.BROWSER, Usergroups.CONSUMER, Usergroups.MARKETING]
    },
    buttonbar: {
      join: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      signin: [Usergroups.BROWSER],
      more: [Usergroups.FREE, Usergroups.SUSPENDED]
    },
    menu: {
      howitworks: [Usergroups.BROWSER, Usergroups.FREE],
      blog: [Usergroups.BROWSER],
      newsletter: Usergroups.BROWSER,
      forbusiness: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      bookmarks: Usergroups.ALL_AUTHENTICATED_CONSUMER,
      profile: [
        ...Usergroups.ALL_AUTHENTICATED_CONSUMER,
        ...Usergroups.ALL_ENTERPRISE
      ],
      logout: Usergroups.ALL_AUTHENTICATED_CONSUMER
    },
    adminTools: {
      usermanagement: ALLOW_USER_PAGE_GROUP,
      reporting: ALLOW_REPORTING_GROUP
    }
  },
  largeDesktop: {
    actionbar: {
      divider: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED]
    },
    linkbar: {
      howitworks: [Usergroups.BROWSER, Usergroups.FREE],
      blog: Usergroups.ALL_CONSUMER,
      bonusmaterial: [Usergroups.CONSUMER],
      newsletter: Usergroups.BROWSER,
      forbusiness: difference(Usergroups.ALL_CONSUMER, [
        Usergroups.CONSUMER,
        Usergroups.MARKETING
      ]),
      bookmarks: difference(ALLOW_BOOKMARKS_GROUP, [
        Usergroups.FREE,
        Usergroups.SUSPENDED
      ]),
      skillbites: ALLOW_NON_CONSUMER_SKILL_BITES,
      msa: ALLOW_ALL_NON_CONSUMER,
      playlists: ALLOW_PLAYLISTS_GROUP,
      profile: difference(
        ALLOW_PROFILE_GROUP,
        Usergroups.ALL_AUTHENTICATED_CONSUMER
      ),
      mindsetai: ALLOW_ALL,
      admintools: ALLOW_ADMIN_TOOLS
    },
    buttonbar: {
      join: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      signin: [Usergroups.BROWSER],
      account: [Usergroups.CONSUMER, Usergroups.MARKETING],
      more: [Usergroups.FREE, Usergroups.SUSPENDED]
    },
    adminTools: {
      usermanagement: ALLOW_USER_PAGE_GROUP,
      reporting: ALLOW_REPORTING_GROUP
    },
    menu: {
      bookmarks: [Usergroups.FREE, Usergroups.SUSPENDED],
      profile: Usergroups.ALL_AUTHENTICATED_CONSUMER,
      logout: Usergroups.ALL_AUTHENTICATED_CONSUMER
    }
  },
  // extraLargeDesktop is now the same as largeDesktop because the app bar width is restricted.
  extraLargeDesktop: {
    actionbar: {
      divider: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED]
    },
    linkbar: {
      howitworks: [Usergroups.BROWSER, Usergroups.FREE],
      blog: Usergroups.ALL_CONSUMER,
      bonusmaterial: [Usergroups.CONSUMER],
      newsletter: Usergroups.BROWSER,
      forbusiness: difference(Usergroups.ALL_CONSUMER, [
        Usergroups.CONSUMER,
        Usergroups.MARKETING
      ]),
      bookmarks: difference(ALLOW_BOOKMARKS_GROUP, [
        Usergroups.FREE,
        Usergroups.SUSPENDED
      ]),
      skillbites: ALLOW_NON_CONSUMER_SKILL_BITES,
      msa: ALLOW_ALL_NON_CONSUMER,
      mindsetai: ALLOW_ALL,
      playlists: ALLOW_PLAYLISTS_GROUP,
      profile: difference(
        ALLOW_PROFILE_GROUP,
        Usergroups.ALL_AUTHENTICATED_CONSUMER
      ),
      admintools: ALLOW_ADMIN_TOOLS
    },
    buttonbar: {
      join: [Usergroups.BROWSER, Usergroups.FREE, Usergroups.SUSPENDED],
      signin: [Usergroups.BROWSER],
      account: [Usergroups.CONSUMER, Usergroups.MARKETING],
      more: [Usergroups.FREE, Usergroups.SUSPENDED]
    },
    adminTools: {
      usermanagement: ALLOW_USER_PAGE_GROUP,
      reporting: ALLOW_REPORTING_GROUP
    },
    menu: {
      bookmarks: [Usergroups.FREE, Usergroups.SUSPENDED],
      profile: Usergroups.ALL_AUTHENTICATED_CONSUMER,
      logout: Usergroups.ALL_AUTHENTICATED_CONSUMER
    }
  }
}
