import React from 'react'
import { Box, Button, IconButton, Popper, Typography } from '@mui/material'
import { CloseTwoTone } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { onboardingSlice } from '../../reducers'
import { useStyles } from './style'
import { useEvent } from '@emerald-works/react-event-bus-client'

export const OnboardingPopper = (props) => {
  const { anchorEl } = props
  const popoverOpen = useSelector(onboardingSlice.selectors.selectProfilePopoverOpen)
  const playlistOnboarding = useSelector(onboardingSlice.selectors.selectOnboardingProfile)
  const classes = useStyles()
  const buttonText = playlistOnboarding?.onboarding?.playlists?.viewCount === 2 ? 'Dismiss' : 'Skip for now'
  const [onboardingTrackEvent] = useEvent([
    onboardingSlice.eventBus.onboardingTrackEvent
  ])

  const onClose = () => {
    onboardingTrackEvent.trigger({
      type: 'dismissPlaylist'
    })
  }

  return (
    <Popper
      className={classes.popper}
      open={popoverOpen}
      anchorEl={anchorEl}
      disablePortal
      popperOptions={{
        modifiers: [{
          offset: {
            offset: '0,13'
          }
        }]
      }}
    >
      <div className={classes.arrow} />
      <IconButton title='close menu' onClick={onClose} className={classes.closeButton}>
        <CloseTwoTone className={classes.closeIcon} />
      </IconButton>
      <Box
        className={classes.content}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'middle'
        }}
      >
        <Typography className={classes.title} variant='subtitle1'>Click here to access playlists</Typography>
        <Typography variant='body'>Create your own Playlist, by adding and organizing articles, resources and tools in one place</Typography>
        <Button className={classes.dismiss} onClick={onClose} variant='text'>{buttonText}</Button>
      </Box>
    </Popper>
  )
}
