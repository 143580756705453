import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ breakpoints, palette, spacing, customShadows }) => ({
  root: {

  },
  avatar: {
    height: spacing(4),
    width: spacing(4)
  },
  input: {
    position: 'relative'
  },
  textarea: {
    border: palette.comment.textAreaBorder,
    borderRadius: spacing(0.5),
    padding: spacing(1)
  },
  emojiButton: {
    position: 'absolute',
    right: spacing(2.5),
    bottom: ({ isReply }) => isReply ? spacing(2) : spacing(2.5),
    '& .MuiIconButton-root': {
      color: palette.comment.emojiButtonColor
    }
  },
  freeAccountCommentCtaContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    '& svg': {
      height: 18,
      width: 18
    },
    marginRight: spacing(1),
    marginLeft: spacing(1)
  },
  addCommentContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
}))
