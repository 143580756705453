import name from './name'

const selectSliceData = state => state[name]
const selectAssessmentDetails = state => selectSliceData(state)
const selectAssessmentStatus = state => selectSliceData(state).assessmentStatus
const selectMetaSkillsStatus = state => selectSliceData(state).metaSkillsStatus

export {
  selectAssessmentDetails,
  selectAssessmentStatus,
  selectMetaSkillsStatus
}
