import { Button, Grid } from '@mui/material'
import { KeyboardReturn } from '@mui/icons-material'
import Comment from '../comment'

import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import Hide from '../../hide'

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      padding: spacing(4)
    },
    more: {
      '& .MuiButton-startIcon': {
        transform: 'scaleX(-1)',
        color: palette.text.hint
      },
      '& .MuiButton-label': {
        ...typography.body.s,
        fontWeight: typography.weight.regular,
        color: palette.text.secondary
      }
    }
  })
)
/** Displays the replies to a parent comment */
const Replies = props => {
  const { replies, isExpanded, ...other } = props
  const classes = useStyles(props)
  const [expand, setExpand] = useState(false)
  const expanded = isExpanded || expand
  return (
    <Grid container item direction='column' spacing={3}>
      {replies.map((reply, idx) => (
        <Hide key={idx} component={Grid} item hidden={idx !== 0 && !expanded}>
          <Comment
            comment={reply}
            isReply
            classes={{ root: classes.reply }}
            {...other}
          />
        </Hide>
      ))}
      {!expanded && replies.length > 1 && (
        <Grid item className={classes.more}>
          <Button
            onClick={() => setExpand(true)}
            startIcon={<KeyboardReturn />}
            data-test='view-more-replies-button'
          >
            View {replies.length - 1} more replies
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default Replies
