import { createSlice } from '@reduxjs/toolkit'
import name from './name'

export const onboarding = createSlice({
  name,
  initialState: {
    playlistPopperOpen: false,
    onboarding: {}
  },
  reducers: {
    setOnboardingProfile: (state, { payload }) => {
      state.playlistPopperOpen = payload.onboarding.playlists.show && !payload.onboarding.playlists.onboarded
      state.onboarding = {
        ...payload
      }
    },
    openPlaylistPopper: (state) => {
      state.playlistPopperOpen = false
    },
    closePlaylistPopper: (state) => {
      state.playlistPopperOpen = false
    }
  }
})

export const { setOnboardingProfile, openPlaylistPopper, closePlaylistPopper } = onboarding.actions

export default onboarding.reducer
