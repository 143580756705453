import { Grid, Hidden } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { campaignSlice } from '../../reducers'
import { ExploreMenuCampaignBanner } from '../campaign/explore-menu-banner'
import Header from './header'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  columns: {
    marginRight: spacing(6),
    marginBottom: spacing(2)
  }
}))

const Layout = ({
  title,
  onClose,
  panel1,
  panel2,
  panel3,
  panelCtnLgSize = 8,
  panel1LgSize = 6,
  panel2LgSize = 6,
  panel3LgSize = 4,
  bannerLgSize = 12,
  showBannerRight
}) => {
  const classes = useStyles()
  const campaign = useSelector(campaignSlice.selectors.campaignData)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header title={title} onClose={onClose} />
      </Grid>
      <Grid container item spacing={4} className={classes.columns}>
        <Grid container item xs={12} lg={panelCtnLgSize}>
          {panel1 && (
            <Grid item xs={12} lg={panel1LgSize}>
              {panel1}
            </Grid>
          )}
          {panel2 && (
            <Grid item xs={12} lg={panel2LgSize}>
              {panel2}
              {showBannerRight && <ExploreMenuCampaignBanner campaign={campaign} />}
            </Grid>
          )}
          {!showBannerRight && (
            <Grid item xs={12} lg={bannerLgSize}>
              <Hidden lgDown>
                <ExploreMenuCampaignBanner campaign={campaign} />
              </Hidden>
            </Grid>
          )}
        </Grid>
        {panel3 && (
          <Grid item xs={12} lg={panel3LgSize}>
            {panel3}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default Layout
