import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SCROLLTOP = 'scrollTop'

const APP_BAR_HEIGHT = 64

const SCROLL_DEFAULTS = {
  hash: { behavior: 'smooth' },
  [SCROLLTOP]: {}
}

/**
 * Checks location.hash and location.state to decide whether to scroll the view.
 * If location.hash is set this is assumed to be the id of the element that is
 * to be scrolled to.
 * If location.state.scrollTop is truthy the view is scrolled to the top.
 *
 * The setTimeout ensures that this works in all cases using smooth scrolling.
 * Without it loading a url containing a hash will result in the desired element
 * being slightly wrongly positioned. useLayoutEffect made no difference to this.
 * Auto scrolling for a hash doesn't need the setTimeout but is a worse UX (IMHO).
 */
const useLocationScroll = ({ scrollOptions } = {}) => {
  const location = useLocation()

  useEffect(() => {
    const scrollOpts = { ...SCROLL_DEFAULTS, ...scrollOptions }

    const getElement = hash => {
      const id = hash.startsWith('#') ? hash.substring(1) : hash
      return document.getElementById(id)
    }

    const scrollToEl = el => {
      const top =
        el.getBoundingClientRect().top + window.pageYOffset - APP_BAR_HEIGHT
      window.scrollTo({ top, ...scrollOpts.hash })
    }

    const { hash, state } = location
    if (hash) {
      const el = getElement(hash)
      if (el) {
        setTimeout(() => scrollToEl(el))
      }
    } else if (state?.[SCROLLTOP]) {
      window.scrollTo({ top: 0, ...scrollOpts[SCROLLTOP] })
    }
  }, [location, scrollOptions])
}

useLocationScroll.SCROLLTOP = SCROLLTOP

export default useLocationScroll
