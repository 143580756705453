import * as actions from './slice'

export const listBonusMaterials = {
  onStart: {
    action: actions.setLoading,
    redux: true
  },
  eventName: 'bonusMaterialList',
  onSuccess: [{ action: actions.setBonusMaterials, redux: true }],
  onError: [
    { action: actions.setLoadingOff, redux: true }
  ]
}
