import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  homeContent: {
    content: [],
    total: 0
  },
  promotedContent: [],
  promotedContentHasBeenTriggered: false,
  recommendedVideos: [],
  recommendedRead: [],
  recommendedCategories: [],
  randomHash: Math.random().toString(36).substring(7)
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setHomeContent: (state, { payload }) => {
      // console.log('Payload', payload, state)
      state.homeContent = {
        ...payload,
        content: [
          ...state.homeContent.content,
          ...payload.content
        ]
      }
    },
    setPromotedContent: (state, { payload }) => {
      state.promotedContent = payload.promotedContent
    },
    setPromotedContentHasBeenTriggered: (state, { payload }) => {
      state.promotedContentHasBeenTriggered = true
    },
    setRecommendedVideos: (state, { payload }) => {
      state.recommendedVideos = payload.items
    },
    setLastRead: (state, { payload }) => {
      state.recommendedRead = payload.items
    },
    setRecommendedCategories: (state, { payload }) => {
      state.recommendedCategories = payload
    },
    toggleRecommend: (state, { payload }) => {
      const { locationId, hasRecommend, recommendCount } = payload
      const homeIndex = state.homeContent.content.findIndex(
        content => content.locationId === locationId
      )
      if (homeIndex >= 0) {
        state.homeContent.content[homeIndex] = {
          ...state.homeContent.content[homeIndex],
          hasRecommend,
          recommendCount
        }
      }

      const promotedIndex = state.promotedContent.findIndex(c => c.locationId === locationId)

      if (promotedIndex >= 0) {
        state.promotedContent[promotedIndex] = {
          ...state.promotedContent[promotedIndex],
          content: {
            ...state.promotedContent[promotedIndex].content,
            hasRecommend,
            recommendCount
          }
        }
      }
    }
  }
})

export const { setHomeContent, toggleRecommend, setPromotedContent, setPromotedContentHasBeenTriggered, setLastRead, setRecommendedVideos, setRecommendedCategories } = content.actions

export default content.reducer
