import React from 'react'
import { Box, Card } from '@mui/material'
import clsx from 'clsx'
import { Stack } from '../stack'
import { useStyles } from './styles'

const PromotedCardContainer = ({ testId, content, stack, children, overrideClasses = {}, ...props }) => {
  const classes = useStyles(props)
  return (
    <Box
      className={clsx(classes.root, overrideClasses.root)}
      onMouseEnter={props?.onMouseEnter}
      onMouseLeave={props?.onMouseLeave}
      data-test={testId || `card-${content?.friendlyUrl}'}`}
    >
      <Stack number={stack ?? 0}>
        <Card variant='elevation' classes={{ root: clsx(classes.card, overrideClasses.cardRoot) }}>
          {children}
        </Card>
      </Stack>
    </Box>
  )
}

export default PromotedCardContainer
