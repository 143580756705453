import name from './name'
const selectSliceData = state => state[name]
const selectAccountInfo = state => selectSliceData(state).accountInfo
const selectAccountInfoLoaded = state => selectSliceData(state).accountInfoLoaded
const setSubscriptionChangePending = state => selectSliceData(state).setSubscriptionChangePending
const selectSubscriptionChangePending = state => selectSliceData(state).subscriptionChangePending
const setSubscriptionChangeReceived = state => selectSliceData(state).setSubscriptionChangeReceived
const selectSubscriptionChangeReceived = state => selectSliceData(state).subscriptionChangeReceived

export {
  selectAccountInfo,
  selectAccountInfoLoaded,
  setSubscriptionChangePending,
  selectSubscriptionChangePending,
  setSubscriptionChangeReceived,
  selectSubscriptionChangeReceived
}
