import name from './name'

const selectSliceData = state => state[name]

const selectResults = state => selectSliceData(state).results

const selectTotal = state => selectSliceData(state).total

const selectCounts = state => selectSliceData(state).counts

export { selectResults, selectTotal, selectCounts }
