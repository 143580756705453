import { useMediaQuery, useTheme } from '@mui/material'

const useFormFactor = () => {
  const { breakpoints } = useTheme()

  const isMobile = useMediaQuery(breakpoints.down('sm'))
  const aboveTablet = useMediaQuery(breakpoints.up('sm'))
  const aboveLargeTablet = useMediaQuery(breakpoints.up('md'))
  const isDesktop = useMediaQuery(breakpoints.up('lg'))
  const isLargeDesktop = useMediaQuery(breakpoints.up('xl'))

  return {
    isMobile,
    isDesktop,
    isSmallTablet: aboveTablet && !aboveLargeTablet,
    isLargeTablet: aboveLargeTablet && !isDesktop,
    isUnknown: !isMobile && !isDesktop && !aboveTablet && !aboveLargeTablet,
    isLargeDesktop
  }
}

export default useFormFactor
