import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

// Adhoc error handler added in PlaylistView
export const getPlaylist = {
  eventName: 'playlistGet',
  onSuccess: {
    action: actions.setDetail,
    redux: true
  }
}

export const getPlaylistContent = {
  eventName: 'playlistListContent',
  onStart: {
    action: actions.setLoading,
    redux: true
  },
  onSuccess: {
    action: actions.setContent,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get the playlist content', 'error'),
    redux: true
  }
}

export const batchGetPlaylistReadTimes = {
  eventName: 'playlistBatchGetReadTime',
  onSuccess: {
    action: actions.setReadTimes,
    redux: true
  }
}
