import { useEffect } from 'react'
import { useLocation } from 'react-router'

const findLastPageView = dataLayer => {
  return dataLayer.reverse().find(item => item.event === 'pageview')
}

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    const lastPageView = findLastPageView(window.dataLayer)
    if (!lastPageView || lastPageView.page.title !== location.pathname) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.href,
          title: location.pathname
        }
      })
    }
  }, [location])
}
export default usePageTracking
