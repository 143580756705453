import React from 'react'
import { PaginationItem as MuiItem } from '@mui/lab'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button } from '@mui/material'

const Previous = props => (
  <Button variant='text' startIcon={<ChevronLeft />} {...props}>
    Back
  </Button>
)

const Next = props => (
  <Button variant='text' endIcon={<ChevronRight />} {...props}>
    Next
  </Button>
)

const PaginationItem = props => {
  const { type, page } = props

  const getTestId = () => !!page && `pagination-page-${page}`

  const getComponent = () =>
    type === 'previous' ? Previous : type === 'next' ? Next : MuiItem

  const Comp = getComponent()
  const testId = getTestId()
  const allProps = { ...props, ...(testId && { 'data-test': testId }) }

  return <Comp {...allProps} />
}

export default PaginationItem
