import React from 'react'
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Link as RouterLink } from 'react-router-dom'
import { useHome } from '../../../hooks'
import { HouseTwoTone } from '@mui/icons-material'
import { getCategoryPathForBreadcrumb } from '../../../helpers/url'
import Container from '../../container'
import { useSelector } from 'react-redux'
import { contentSlice } from '../../../reducers'

export const useStyles = makeStyles(
  ({ palette, spacing, breakpoints, typography }) => ({
    root: {
      color: palette.grey[70],
      [breakpoints.down('md')]: {
        margin: spacing(2, 2, 0, 2)
      },
      [breakpoints.up('md')]: {
        marginTop: spacing(2)
      },
      '& .MuiLink-root, & .MuiTypography-root': {
        textTransform: 'capitalize',
        ...typography.body.xs,
        fontWeight: typography.fontWeightMedium,
        padding: spacing(0, 1),
        textAlign: 'start',
        lineHeight: 2.5
      },
      '& .MuiIconButton-root': {
        paddingTop: spacing(1),
        paddingBottom: spacing(1)
      },
      '& .MuiSkeleton-root': {
        margin: spacing(1, 0)
      }
    }

  })
)

export const BreadcrumbsLoading = ({ maxContentWidth = 1200, inset = 0 }) => {
  const classes = useStyles()

  return (
    <Container maxContentWidth={maxContentWidth} inset={inset} className={classes.breadcrumbContainer}>
      <MuiBreadcrumbs
        separator=''
        aria-label='breadcrumbs'
        data-test='content-breadcrumbs'
        classes={{ root: classes.root }}
      >
        <Skeleton variant='rectangular' width={40} height='1em' />
        <Skeleton variant='rectangular' width={120} height='1em' />
        <Skeleton variant='rectangular' width={120} height='1em' />
        <Skeleton variant='rectangular' width={120} height='1em' />
        <Skeleton variant='rectangular' width={150} height='1em' />
      </MuiBreadcrumbs>
    </Container>
  )
}

export const Breadcrumbs = ({ content, maxContentWidth = 1200, inset = 0 }) => {
  const classes = useStyles()
  const { linkProps } = useHome()

  const isLoadingExtraInfo = useSelector(contentSlice.selectors.selectIsLoadingExtraInfo)

  if (isLoadingExtraInfo) {
    return <BreadcrumbsLoading maxContentWidth={maxContentWidth} inset={inset} />
  }

  if (content.categoryPath && content.categoryPath.length) {
    return (
      <Container className={classes.breadcrumbContainer} maxContentWidth={maxContentWidth} inset={inset}>
        <MuiBreadcrumbs
          separator='›'
          aria-label='breadcrumbs'
          data-test='content-breadcrumbs'
          classes={{ root: classes.root }}
        >
          <IconButton
            data-test='breadcrumbs-home'
            color='inherit'
            aria-label='Home'
            {...linkProps}
            size='large'
          >
            <HouseTwoTone fontSize='small' />
          </IconButton>
          {content.categoryPath.map((category, idx) => {
            const hasUrl =
              category.isVisible &&
              category.parentFriendlyUrl !== 'ROOT_CATEGORY_PARENT'
            return (
              <Link
                key={category.sk}
                color='inherit'
                underline='hover'
                {...(hasUrl && {
                  component: RouterLink,
                  to: getCategoryPathForBreadcrumb(category)
                })}
              >
                {category.name}
              </Link>
            )
          })}
          <Typography color='primary'>{content.title}</Typography>
        </MuiBreadcrumbs>
      </Container>
    )
  }
}
