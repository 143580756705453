import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  skillBites: []
}

const defaultDescription = ''

export const skillBite = createSlice({
  name,
  initialState,
  reducers: {
    setSkillBite: (state, { payload }) => {
      payload.sessions = payload.sessions.map((session, index) => {
        return {
          ...session,
          description: session.description || defaultDescription
        }
      })

      state.skillBite = payload
    },
    setSkillBiteSubscription: (state, { payload }) => {
      const { skillBiteDetail } = payload
      state.skillBite = skillBiteDetail
    }
  }
})

export const {
  setSkillBite,
  setSkillBiteSubscription
} = skillBite.actions

export default skillBite.reducer
