import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, Box } from '@mui/material'
import { useStyles } from './style'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { searchResultsSlice } from '../../../reducers'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import SearchSuggestions from '../search-suggestions'
import SearchTextField from './text-field'
import useFormFactor from '../hooks/useFormFactor'
import { useAlert } from '../../../hooks'

const Search = ({ onSearch, className = '', showSuggestions, onShowSuggestions, triggerSuggestionsLength = 3 }) => {
  const [searchInput, setSearchInput] = useState('')
  const dispatch = useDispatch()
  const suggestions = useSelector(searchResultsSlice.selectors.selectSuggestions)
  const inputRef = useRef()
  const searchRootRef = useRef()
  const fullScreenWrapperRef = useRef()
  const { breakpoints: bps } = useFormFactor()
  const classes = useStyles({ bps })

  const { enqueueMsg } = useAlert()

  const handleClose = useCallback((focusInput = true) => {
    if (focusInput) {
      inputRef.current.focus()
    }
    onShowSuggestions(false)
  }, [onShowSuggestions])

  const onEraseSearch = (e) => {
    setSearchInput('')
    inputRef.current.focus()
  }

  const onSearchChange = ({ target: { value } }) => {
    setSearchInput(value)
  }

  const [getSuggestionsTrigger] = useEvent([searchResultsSlice.eventBus.getSearchSuggestions])
  const getSuggestions = useMemo(() => debounce((value) => {
    if (value.length) {
      getSuggestionsTrigger.trigger({ value })
    }
  }, 200, { leading: true }), [getSuggestionsTrigger])

  const [removeItemSearchHistoryTrigger] = useEvent([{
    eventName: 'searchHistoryRemoveItem',
    onSuccess: payload => {
      dispatch(searchResultsSlice.actions.setHistory(payload))
      inputRef.current.focus()
    },
    onError: () => {
      enqueueMsg('Unable to remove item from search history', 'error')
    }
  }])

  useEffect(() => {
    if (searchInput.length >= triggerSuggestionsLength) {
      getSuggestions(searchInput)
    } else {
      dispatch(searchResultsSlice.actions.setSuggestions({ suggestions: [] }))
    }
  }, [searchInput, getSuggestions, triggerSuggestionsLength, dispatch])

  const handleInputFocus = useCallback((e) => {
    const parent = searchRootRef.current
    if (parent?.contains(e.target) && !showSuggestions) {
      onShowSuggestions(true)
    }
  }, [showSuggestions, searchRootRef, onShowSuggestions])

  const handleEscButton = useCallback((e) => {
    if (showSuggestions && e.key === 'Escape') {
      handleClose()
    }
  }, [showSuggestions, handleClose])

  const handleSearchSuggestion = (value, type, position) => {
    setSearchInput(value)
    onSearch(value, type, position)
    handleClose()
  }

  const handleHistoryEdit = (term) => {
    removeItemSearchHistoryTrigger.trigger({ term })
  }

  const handleKeyUp = ({ key }) => {
    if (key === 'Enter') {
      const term = searchInput.trim()
      setSearchInput(term)
      if (term.length > 0) {
        onSearch(term, 'enter')
        handleClose()
      }
    } else if (key !== 'Escape') {
      onShowSuggestions(true)
    }
  }

  const handleWrapperBlur = useCallback(({ relatedTarget }) => {
    const checkBlurTarget = () => {
      const isFocusOutsideSearch = !fullScreenWrapperRef.current.contains(relatedTarget)
      if (isFocusOutsideSearch) {
        handleClose(false)
      }
    }
    // Safari work around, Safari does not give focus to buttons being clicked, which means the button container does not appear in blur event relatedTargets
    // and gets closed before calling removeItemSearchHistoryTrigger.
    // Adding a delay allows the event to trigger before blur closes the container.
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus
    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
    if (isSafari) {
      setTimeout(checkBlurTarget, 500)
      return
    }
    // All other browsers
    checkBlurTarget()
  }, [handleClose])

  useEffect(() => {
    document.addEventListener('focusin', handleInputFocus)
    document.addEventListener('click', handleInputFocus)
    document.addEventListener('keydown', handleEscButton)
    return () => {
      document.removeEventListener('focusin', handleInputFocus)
      document.removeEventListener('click', handleInputFocus)
      document.removeEventListener('keydown', handleEscButton)
    }
  }, [handleInputFocus, handleEscButton])

  return (
    <Box ref={searchRootRef} className={classes.searchRoot}>
      <Box onBlur={handleWrapperBlur} className={showSuggestions ? classes.fullScreenWrapper : ''} ref={fullScreenWrapperRef}>
        <Box className={`${classes.container} ${showSuggestions && classes.searchOnClick}`}>
          <Grid
            container
            wrap='nowrap'
            className={`${classes.search} ${classes.container} ${showSuggestions && classes.searchOnClick}`}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              <SearchTextField
                value={searchInput}
                inputRef={inputRef}
                showSuggestions={showSuggestions}
                onChange={onSearchChange}
                onKeyUp={handleKeyUp}
                onEraseSearch={onEraseSearch}
              />
            </Grid>
          </Grid>
          <SearchSuggestions
            open={showSuggestions}
            suggestions={suggestions}
            searchInput={searchInput}
            onInputChange={handleSearchSuggestion}
            onHistoryEdit={handleHistoryEdit}
            showAll={searchInput?.length >= triggerSuggestionsLength}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Search
