import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, typography, palette, breakpoints }) => ({
  titleBar: {
    alignSelf: 'end'
  },
  root: {
    [breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      right: spacing(2),
      left: spacing(2),
      margin: 0,
      height: '80%'
    }
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      margin: spacing(8)
    },
    [breakpoints.down('sm')]: {
      margin: spacing(2)
    }
  },
  lockIcon: {

  },
  message: {
    fontWeight: 500,
    ...typography.heading.h5
  },
  goToSignMessage: {
  }
}))
