/* eslint-disable indent */
import React from 'react'
import { Box, Card, CardContent } from '@mui/material'
import clsx from 'clsx'
import { Stack } from '../stack'
import { useStyles } from './style'
import { VideoContent, TopicsContent, RelatedContent, BonusMaterialCard } from './index'
import { useGroup } from '../../../hooks'
import { ALL_CONSUMER } from '../../../services/cognito/userGroups'

export const RIGHT_LONG_CARD_TYPES = {
  VIDEO: 'VIDEO',
  TOPICS: 'TOPICS',
  RELATED_CONTENT: 'RELATED_CONTENT'
}

const RightGeneraldCard = props => {
  const { cardType, content, stack, className, testId } = props
  const classes = useStyles(props)
  const { isInGroup } = useGroup()
  const isConsumer = isInGroup(ALL_CONSUMER)

  const getConsumerStyles = () =>
    cardType === 'TOPICS' && isConsumer
      ? classes.consumerTopicsCard
      : cardType === 'RELATED_CONTENT' && isConsumer
      ? classes.consumerRelatedCard
      : ''

  return (
    <Box
      className={clsx(classes.root, className)}
      onMouseEnter={props?.onMouseEnter}
      onMouseLeave={props?.onMouseLeave}
      data-test={testId || `card-${content?.friendlyUrl}'}`}
    >
      <Stack number={stack ?? 0}>
        <Card
          raised
          classes={{ root: clsx(classes.card, getConsumerStyles()) }}
        >
          <CardContent>
            {cardType === 'VIDEO' && <VideoContent content={content} isConsumer={isConsumer} />}
            {cardType === 'TOPICS' && <TopicsContent content={content} isConsumer={isConsumer} />}
            {cardType === 'RELATED_CONTENT' && <RelatedContent content={content} isConsumer={isConsumer} />}
            {cardType === 'BONUS_MATERIAL' && <BonusMaterialCard />}
          </CardContent>
        </Card>
      </Stack>
    </Box>
  )
}

export default RightGeneraldCard
