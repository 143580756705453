import React from 'react'
import { useStyles } from './style'

const VideoPlayer = ({ url }) => {
  const classes = useStyles()

  if (!url) return false

  return (
    <video controls preload='auto' key={url} className={classes.video}>
      <source src={url} />
      Sorry, no support for embedded video
    </video>
  )
}

export default VideoPlayer
