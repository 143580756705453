import React, { useState } from 'react'
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import { Clear, WatchLaterOutlined } from '@mui/icons-material'
import { useStyles } from './style'
import { UserGroupPolicy } from '../../user-group-policy'
import { ALLOW_HISTORY_EDIT } from '../../user-group-policy/permissions'

const History = ({ history, onInputChange, onHistoryEdit }) => {
  const [loading, setLoading] = useState(null)
  const classes = useStyles()
  const testAttr = 'search-history-list'
  return (
    <>
      <ListItem divider className={classes.searchSuggestionsTitle}>
        <ListItemText
          primaryTypographyProps={{ 'data-test': `${testAttr}-title` }}
        >
          Search History
        </ListItemText>
      </ListItem>
      {history.map((value, idx) => (
        <ListItem
          button
          component='li'
          onClick={() => onInputChange(value, 'search-history', idx)}
          divider={idx < history.length - 1}
          key={value}
          className={classes.historyItem}
          data-test={`${testAttr}-item-${idx}`}
        >
          <ListItemIcon>
            <WatchLaterOutlined
              fontSize='small'
              className={classes.historyIcon}
              data-test={`${testAttr}-item-${idx}-icon`}
            />
          </ListItemIcon>
          <ListItemText
            secondary={value}
            classes={{ secondary: classes.historyItemText }}
            secondaryTypographyProps={{
              'data-test': `${testAttr}-item-${idx}-text`
            }}
          />
          <ListItemSecondaryAction>
            <UserGroupPolicy
              accessGroup={ALLOW_HISTORY_EDIT}
              componentAllow={
                <IconButton
                  edge='end'
                  aria-label={`remove item ${idx} from search history`}
                  onClick={() => {
                    setLoading(value)
                    onHistoryEdit(value)
                  }}
                  className={classes.historyAction}
                  data-test={`${testAttr}-item-${idx}-action`}
                  disabled={loading === value}
                  size='large'
                >
                  {
                    loading === value
                      ? (
                        <CircularProgress size={20} color='inherit' />
                        )
                      : (
                        <Clear fontSize='small' className={classes.historyActionIcon} />
                        )
}
                </IconButton>
            }
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </>
  )
}

export default History
