import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  comments: [],
  total: 0,
  loading: false
}

const mapComment = comment => ({
  author: { ...comment.user },
  text: comment.comment,
  id: comment.sk,
  date: comment.createdAt,
  owner: comment.owner,
  likeCount: comment.likeCount,
  hasLiked: comment.hasLiked,
  parent: comment.parent,
  replies: comment.children?.map(mapComment) || []
})

export const comments = createSlice({
  name,
  initialState,
  reducers: {
    setComments: (state, { payload }) => {
      if (!payload.comments) return { ...state, comments: [] }
      state.comments = payload.comments.map(mapComment)
      state.total = payload.total
    },
    addComment: (state, { payload }) => {
      const { comment, doNotUpdateTotal } = payload
      const mappedComment = mapComment(comment)

      if (mappedComment.parent) {
        const parent = state.comments.find(e => e.id === mappedComment.parent)
        if (parent) {
          parent.replies = [...parent.replies, mappedComment].filter(Boolean)
        }
        return
      }

      state.comments = [mappedComment, ...state.comments].filter(Boolean)
      if (!doNotUpdateTotal) state.total = state.total + 1
    },
    deleteComment: (state, { payload }) => {
      const deletedComment = mapComment(payload.comment)
      if (deletedComment.parent) {
        const parent = state.comments.find(comment => comment.id === deletedComment.parent)
        parent.replies = parent.replies.filter(reply => deletedComment.id !== reply.id)
      } else {
        state.comments = state.comments.filter(comment => comment.id !== deletedComment.id)
        state.total = state.total - 1
      }
    },
    preAddComment: (state, { payload }) => {
      const { parent } = payload || {}
      if (parent) {
        const result = state.comments.find(c => c.id === parent)
        result.replies = [...result.replies, undefined]
      } else {
        state.comments = [undefined, ...state.comments]
        state.total = state.total + 1
      }
    },
    cancelPreAddComment: (state, { payload }) => {
      const { parent } = payload || {}
      if (parent) {
        const result = state.comments.find(c => c.id === parent)
        result.replies.pop()
      } else {
        state.comments.shift()
        state.total = state.total - 1
      }
    },
    toggleCommentLike: (state, { payload }) => {
      const { comment: { id, parent } } = payload
      const commentToUpdate = parent
        ? state.comments.find(c => c.id === parent).replies.find(r => r.id === id)
        : state.comments.find(c => c.id === id)

      commentToUpdate.hasLiked = !commentToUpdate.hasLiked
      commentToUpdate.likeCount = commentToUpdate.hasLiked ? commentToUpdate.likeCount + 1 : commentToUpdate.likeCount - 1
    },
    updateComment: (state, { payload }) => {
      const { sk: id, parent, likeCount, comment: text } = payload.comment

      const commentToUpdate = parent
        ? state.comments.find(c => c.id === parent).replies.find(r => r.id === id)
        : state.comments.find(c => c.id === id)

      commentToUpdate.likeCount = likeCount
      commentToUpdate.text = text
    },
    setCommentsLoadingOn: state => {
      state.loading = true
    },
    setCommentsLoadingOff: state => {
      state.loading = false
    },
    reset: state => initialState
  }
})

export const {
  setComments,
  deleteComment,
  toggleCommentLike,
  setCommentsLoadingOn,
  setCommentsLoadingOff,
  preAddComment,
  cancelPreAddComment,
  addComment,
  removeComment,
  updateComment,
  reset
} = comments.actions

export default comments.reducer
