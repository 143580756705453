import React from 'react'
import Alert from './alert'
import AlertTitle from './title'
import { capitalize } from 'lodash'

const MsgAlert = ({
  severity,
  msg,
  title,
  ...props
}) => {
  const msgTitle = title || capitalize(severity)
  return (
    <Alert severity={severity} showClose {...props}>
      {msgTitle && <AlertTitle>{msgTitle}</AlertTitle>}
      {msg}
    </Alert>
  )
}

export default MsgAlert
