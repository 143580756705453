import { isFunction } from 'lodash'

const genSetter = prop => (state, { payload }) => {
  state[prop] = payload
}

const bindActionPayload = (actionCreator, payloadProvider) => reduxPayload =>
  actionCreator(
    isFunction(payloadProvider) ? payloadProvider() : payloadProvider
  )

export { genSetter, bindActionPayload }
