import { useSelector } from 'react-redux'
import { EncryptedCookies } from '@emerald-works-nova/components'
import { userSlice } from '../reducers'

const getSessionFromCookie = () => {
  try {
    const value = EncryptedCookies.getSession()

    return value ? JSON.parse(value) : undefined
  } catch (error) {
    return undefined
  }
}

const useProfileSession = () => {
  const profile = useSelector(userSlice.selectors.selectProfile)

  if (!profile) {
    const profileFromCookies = getSessionFromCookie()

    return { profile: profileFromCookies, isProfileCached: true }
  }

  return { profile, isProfileCached: false }
}

export default useProfileSession
