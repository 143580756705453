import name from './name'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bookmarked: [],
  loading: false,
  total: 0
}

const bookmarks = createSlice({
  name,
  initialState,
  reducers: {
    addBookmarked: (state, { payload }) => {
      state.bookmarked.push(payload)
    },
    removeBookmarked: (state, { payload }) => {
      const index = state.bookmarked.findIndex(item => item === payload)
      if (index >= 0) {
        state.bookmarked.splice(index, 1)
      }
    },
    setBookmarked: (state, { payload }) => {
      state.bookmarked = Array.from(new Set([...state.bookmarked, ...payload.content]))
      state.bookmarks = []
      state.content = []
      state.loading = false
      state.total = 0
    },
    setLoading: (state, { payload }) => {
      state.loading = true
    },
    setLoadingOff: (state, { payload }) => {
      state.loading = false
    },
    reset: state => initialState
  }
})

export const {
  addBookmarked,
  removeBookmarked,
  setBookmarked,
  setLoading,
  setLoadingOff,
  reset
} = bookmarks.actions

export default bookmarks.reducer
