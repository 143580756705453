/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react'
import { Box, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Container from '../container'
import { useStyles as useArticleStyles } from './../article/style'
import { BreadcrumbsLoading } from '../article/breadcrumbs'

const useStyles = makeStyles(({ breakpoints }) => ({
  contentGrid: {
    display: 'grid',
    [breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 660px 1fr'
    }
  }
}))

function randomIntFromInterval (min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
const _RandomSkeletonParagraph = ({ rows = randomIntFromInterval(2, 5) }) => {
  const arr = new Array(rows).fill(true)
  return (
    <Box mt={2} mb={2}>
      {arr.map((row, idx) => (
        <Skeleton width='100%' key={idx} height={20} />
      ))}
    </Box>
  )
}

const RandomSkeletonParagraph = React.memo(_RandomSkeletonParagraph)

export const ArticleBodyLoading = ({ marginTop = 8 }) => {
  return (
    <Box mt={marginTop}>
      <RandomSkeletonParagraph rows={1} />
      <RandomSkeletonParagraph rows={3} />
      <Skeleton variant='rectangular' width='100%' height={200} />
      <RandomSkeletonParagraph />
      <RandomSkeletonParagraph />
      <RandomSkeletonParagraph />
      <RandomSkeletonParagraph />
      <RandomSkeletonParagraph />
      <RandomSkeletonParagraph />
      <RandomSkeletonParagraph />
    </Box>
  )
}

export const ArticleLoading = props => {
  const { width, minInset } = props
  const classes = useArticleStyles(props)

  return (
    <Container
      maxContentWidth={width}
      inset={minInset}
      classes={{ content: classes.article }}
      className={classes.container}
    >
      <Skeleton height={18} width={200} />
      <Skeleton height={48} width='100%' />
      <Skeleton height={20} width='55%' />
      <Box display='flex' flex={1} mt={2} flexDirection='row' alignItems='center'>
        <Skeleton variant='circular' height={50} width={50} />
        <Box ml={2} display='flex' flex={1}>
          <Skeleton width={200} height={24} />
        </Box>
      </Box>
      <ArticleBodyLoading />
    </Container>
  )
}

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}

export const ArticleViewLoading = () => {
  const classes = useStyles()

  return (
    <>
      <BreadcrumbsLoading />
      <ScrollToTopOnMount />
      <div className={classes.contentGrid}>
        <div />
        <Box>
          <ArticleLoading
            width={668}
            minInset={2}
          />
        </Box>
        <Box mt={5} ml={2} display='flex' flexDirection='column' alignItems='center' />
      </div>
    </>
  )
}

export default ArticleViewLoading
