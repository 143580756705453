import React, { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 0
  }
}))

const SnackbarItem = ({ onClose, ...props }, ref) => {
  const classes = useStyles()

  return (
    <SnackbarContent
      ref={ref}
      onClick={onClose}
      className={classes.root}
      {...props}
    />
  )
}

export default forwardRef(SnackbarItem)
