import { difference } from 'lodash'

export const ADMIN = 'admin'
export const EDITOR = 'editor'
export const FREE = 'free'
export const CONSUMER = 'consumer'
export const SUSPENDED = 'suspended'
export const EW = 'ewUser'
export const MARKETING = 'marketing'
export const ENTERPRISE_READER = 'enterpriseReader'
export const CLIENT_MANAGER = 'clientManager'
export const BROWSER = 'browser'
export const CLIENT_EXPERIENCE = 'cx'

export const ALL_AUTHENTICATED = [
  ADMIN,
  FREE,
  CONSUMER,
  SUSPENDED,
  EW,
  ENTERPRISE_READER,
  CLIENT_MANAGER,
  MARKETING,
  EDITOR,
  CLIENT_EXPERIENCE
]

export const ALL_CONSUMER = [
  BROWSER,
  FREE,
  SUSPENDED,
  CONSUMER,
  MARKETING
]

export const ALL_AUTHENTICATED_CONSUMER = difference(
  ALL_CONSUMER,
  [BROWSER]
)

export const ALL_ADMIN = [
  ADMIN,
  MARKETING,
  CLIENT_MANAGER,
  EW
]

export const ALL_ENTERPRISE = [
  ENTERPRISE_READER,
  CLIENT_MANAGER,
  EW,
  EDITOR,
  CLIENT_EXPERIENCE
]
