import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette, spacing, breakpoints, typography }) => ({
  container: {
    height: '100%',
    minHeight: '100%', // Fixes issue with google adsense setting the container height:auto which then breaks the layout
    maxWidth: breakpoints.values.xl,
    margin: 'auto'
  },
  groups: {
    backgroundColor: palette.grey[100],
    [breakpoints.down('sm')]: {
      width: '100%'
    },
    [breakpoints.up('sm')]: {
      maxWidth: 300,
      minWidth: 300
    },
    padding: spacing(2)
  },
  rightPanel: {
    flex: 1,
    borderLeft: `solid ${palette.grey[300]} 1px`,
    padding: spacing(3),
    [breakpoints.up('sm')]: {
      overflow: 'hidden'
    }
  },
  menuButton: {
    justifyContent: 'flex-start',
    marginLeft: spacing(3),
    marginTop: spacing(2),
    marginBottom: spacing(2),
    opacity: 0.6,
    ...typography.body.m
  }
}))
