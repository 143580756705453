import name from './name'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  bonusMaterials: []
}

const bonusMaterials = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = true
    },
    setLoadingOff: (state, { payload }) => {
      state.isLoading = false
    },
    setBonusMaterials: (state, { payload }) => {
      state.bonusMaterials = payload
      state.isLoading = false
    }
  }
})

export const { setLoading, setLoadingOff, setBonusMaterials } = bonusMaterials.actions

export default bonusMaterials.reducer
