import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  label: {
    display: 'none'
  },
  adornment: ({ disabled }) => {
    return !disabled
      ? {
          color: palette.grey.shade[80]
        }
      : {}
  }
}))
