import makeStyles from '@mui/styles/makeStyles'

const getSize = (size, rem) => {
  switch (size) {
    case 'fullWidth':
      return { maxWidth: '100%' }
    case 'large':
      return { maxWidth: rem(384) }
    case 'mobile':
      return { maxWidth: rem(327) }
    default:
      return { maxWidth: rem(282) }
  }
}

export const useStyles = makeStyles(({ typography }) => ({
  root: ({ size }) => getSize(size, typography.pxToRem),
  title: ({ size }) => size === 'large' && typography.heading.h4
}))
