import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const signUp = createSlice({
  name,
  initialState: {
    signUp: {
      email: '',
      firstName: '',
      lastName: '',
      password: ''
    }
  },
  reducers: {
    setSignUpInfo: (state, { payload }) => {
      state.signUp = { ...state.signUp, ...payload }
    }
  }
})

export const { setSignUpInfo, resetEmail } = signUp.actions

export default signUp.reducer
