import React from 'react'
import {
  Box,
  Divider,
  Fade,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material'
import { CloseOutlined, Search as SearchIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import useFormFactor from '../hooks/useFormFactor'

const useStyles = makeStyles(({ typography }) => ({
  input: {
    marginTop: '0 !important',
    height: '36px',
    ...typography.body.s,
    color: '#212121'
  },
  screenReader: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    width: 1,
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap'
  },
  divider: {
    '&.MuiDivider-root': {
      marginRight: 0
    }
  },
  icon: {
    color: '#4f4f4f'
  },
  iconButton: {
    '& svg': {
      color: '#4f4f4f'
    },
    '&:disabled svg': {
      color: 'inherit'
    }
  }
}))

const SearchTextField = props => {
  const classes = useStyles(props)
  const { value, inputRef, showSuggestions, onChange, onEraseSearch, onKeyUp } =
    props

  const { formFactor } = useFormFactor()

  const id = 'topnav-search'
  const inputId = `${id}-text`

  if (formFactor === 'mobile' && !showSuggestions) return null

  return (
    <FormControl fullWidth data-test={id} variant='standard'>
      <InputLabel htmlFor={inputId} className={classes.screenReader}>
        input your search term
      </InputLabel>
      <Input
        id={inputId}
        autoComplete='off'
        placeholder='Search something'
        onKeyUp={onKeyUp}
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        autoFocus={formFactor === 'mobile'}
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        }
        endAdornment={
          showSuggestions && (
            <Fade in={showSuggestions}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Divider orientation='vertical' className={classes.divider} />
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='clear search'
                    disabled={value.length === 0}
                    onClick={onEraseSearch}
                    className={classes.iconButton}
                    size='large'
                  >
                    <CloseOutlined />
                  </IconButton>
                </InputAdornment>
              </Box>
            </Fade>
          )
        }
        disableUnderline
        className={classes.input}
      />
    </FormControl>
  )
}

export default SearchTextField
