import React from 'react'
import { Divider, Typography, Grid } from '@mui/material'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share'
import ShareButtons from './share-buttons'
import UnfurlPreview from '../unfurl-preview'
import ClipboardText from '../clipboard-text'
import { useStyles } from './style'
import { UserGroupPolicy } from '../user-group-policy'
import { ALLOW_SOCIAL_SHARE_GROUP, ALLOW_SHARE_GROUP } from '../user-group-policy/permissions'

const SharePanel = props => {
  const classes = useStyles(props)

  const { url, locationId, onCopy, from, title, subtitle, image } = props

  const iconSize = 26

  const buttons = [
    // Facebook and LinkedIn use internal plugins to avoid sharing localhost URLs
    <UserGroupPolicy
      key='facebook'
      accessGroup={ALLOW_SOCIAL_SHARE_GROUP}
      componentAllow={
        <Grid item>
          <FacebookShareButton url={url} data-test='share-facebook'>
            <FacebookIcon size={iconSize} />
            <Typography>
              Facebook
            </Typography>
          </FacebookShareButton>
        </Grid>
      }
    />,
    <UserGroupPolicy
      key='twitter'
      accessGroup={ALLOW_SOCIAL_SHARE_GROUP}
      componentAllow={
        <Grid item>
          <TwitterShareButton url={url} title={title} hashtags={['MindTools']} data-test='share-twitter'>
            <TwitterIcon size={iconSize} />
            <Typography>
              Twitter
            </Typography>
          </TwitterShareButton>
        </Grid>
      }
    />,
    <UserGroupPolicy
      key='linkedin'
      accessGroup={ALLOW_SOCIAL_SHARE_GROUP}
      componentAllow={
        <Grid item>
          <LinkedinShareButton url={url} data-test='share-linkedin'>
            <LinkedinIcon size={iconSize} />
            <Typography>
              LinkedIn
            </Typography>
          </LinkedinShareButton>
        </Grid>
      }
    />,
    <UserGroupPolicy
      key='email'
      accessGroup={ALLOW_SHARE_GROUP}
      componentAllow={
        <Grid item>
          <EmailShareButton url={url} subject={title} body={title} data-test='share-email'>
            <EmailIcon size={iconSize} />
            <Typography>
              Email
            </Typography>
          </EmailShareButton>
        </Grid>
      }
    />
  ]

  return (
    <div>
      <ShareButtons buttons={buttons} className={classes.buttons} />
      <Divider className={classes.divider} />
      <ClipboardText text={url} locationId={locationId} from={from} onCopy={onCopy} />
      <UnfurlPreview title={title} subtitle={subtitle} url={url} image={image} />
    </div>
  )
}

export default SharePanel
