import React from 'react'
import { useFormFactor } from '../../../hooks'
import { Box, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PromotedCardContainer from './container'
import PromotedContentCardContent from './card-content'
import usePromotedCardMethods, { PROMOTED_CARD_TYPES } from './use-promoted-card-methods'

const multiNoWrap = lines => {
  return {
    lineClamp: lines,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical'
  }
}

const imgContainerPercentage = '45%'
const contentContainerOverflow = '24px'

const mobileImgContainer = {
  width: '100%',
  height: '50%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0
}

const imgContainer = {
  height: '100%',
  width: imgContainerPercentage,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0
}

const mobileContentContainer = {
  width: 'calc(100% - 32px)',
  background: '#FFF',
  justifyContent: 'center',
  position: 'absolute',
  top: '35%',
  bottom: 0,
  right: '16px',
  left: '16px'
}

const contentContainer = {
  width: `calc(100% - ${imgContainerPercentage} + ${contentContainerOverflow})`,
  background: '#FFF',
  justifyContent: 'center',
  position: 'absolute',
  top: '26px',
  bottom: '26px',
  right: 0,
  paddingLeft: contentContainerOverflow,
  paddingRight: contentContainerOverflow,
  paddingTop: 0,
  paddingBottom: 0
}

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: {
    height: '100%'
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '379px'

  },
  imgContainer: props => props.isMobile ? { ...mobileImgContainer } : { ...imgContainer },
  contentContainer: props => props.isMobile ? { ...mobileContentContainer } : { ...contentContainer },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: '#000',
    ...multiNoWrap(2)
  },
  cardSummary: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#454545',
    ...multiNoWrap(4)
  },
  time: {
    color: palette.primary.main,
    fontWeight: typography.fontWeightBold
  },
  actions: {
    marginTop: spacing(1)
  }
}))

const PromotedContentMainCard = ({ content, position, testId, size, onAddToPlaylist, onLike, onBookmark, bookmarked, actionLoading, ...props }) => {
  const { isMobile } = useFormFactor()
  const classes = useStyles({ isMobile })

  const {
    contentImage,
    handleMouseEnter,
    handleMouseLeave,
    handleCardSelect,
    expanded,
    cardSize,
    hoverShadow,
    actions
  } = usePromotedCardMethods({ content, position, size, cardType: PROMOTED_CARD_TYPES.MAIN, onAddToPlaylist, onLike, onBookmark, bookmarked, actionLoading })

  return (
    <PromotedCardContainer
      expand={expanded}
      size={cardSize}
      hoverShadow={hoverShadow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      content={content}
      testId={testId}
    >
      <Box display='flex' flexDirection='row' className={classes.container}>
        <Box className={classes.imgContainer} onClick={handleCardSelect}>
          {
              content
                ? (
                  <img
                    src={contentImage}
                    title={content?.image?.altText}
                    className={classes.image}
                    data-test={`card-image-${content?.friendlyUrl}`}
                  />
                  )
                : (
                  <Skeleton variant='rectangular' className={classes.image} />
                  )
            }
        </Box>
        <PromotedContentCardContent
          content={content}
          actions={actions}
          onSelect={handleCardSelect}
          summaryLines={2}
          expand={expanded}
          subtitleMargin={1}
          overrides={{
            contentContainer: classes.contentContainer,
            actions: classes.actions
          }}
        />
      </Box>
    </PromotedCardContainer>
  )
}

export default PromotedContentMainCard
