import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root': {
      fontSize: 18
    }
  },
  text: {
    marginTop: 2,
    marginLeft: 4,
    textTransform: 'capitalize'
  }
})
const IconTag = ({ icon, text, ...props }) => {
  const classes = useStyles(props)
  return (
    <Box
      display='flex'
      alignItems='center'
      flexWrap='nowrap'
      className={classes.root}
    >
      {icon}
      <Typography variant='body1' noWrap align='left' className={classes.text}>
        {text}
        {/* {content?.type ?? <Skeleton width='80%' />} */}
      </Typography>
    </Box>
  )
}

export default IconTag
