import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  ({ breakpoints, palette, typography, spacing }) => ({
    root: {
      fontWeight: typography.weight.extra,
      color: palette.grey.shade[90],
      ...typography.heading.h1,
      [breakpoints.down('sm')]: {
        ...typography.heading.h3
      },
      marginBottom: spacing(3)
    }
  })
)

const Title = props => {
  const classes = useStyles()

  return (
    <Typography
      variant='h3'
      component='h1'
      align='center'
      className={classes.root}
      {...props}
    />
  )
}

export default Title
