import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ spacing, typography, palette, breakpoints }) => {
    return {
      root: {
        height: '100%',
        transition: 'filter 0.2s',
        width: '100%',
        // cursor: 'pointer',
        filter: props =>
          props?.hoverShadow && 'drop-shadow(0px 10px 10px #CCC9CF)'
      },
      imageRead: {
        marginTop: 2,
        width: 54,
        height: 40,
        objectFit: 'cover',
        borderRadius: '6px',
        marginLeft: 1
      },
      imageVideo: {
        gridArea: 'container',
        objectFit: 'cover'
      },
      imageVideoBase: {
        width: '100%',
        height: '100%',
        borderRadius: spacing(1)
      },
      videoIcon: {
        backgroundColor: '#1D2D38',
        color: 'white',
        gridArea: 'container',
        borderRadius: '80%',
        padding: spacing(1),
        fontSize: '2.5em',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      },
      videoContainer: {
        position: 'relative',
        height: '80px'
      },
      topic: ({ isConsumer }) => ({
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: isConsumer ? '#000' : palette.grey.shade[10],
        boxSizing: 'border-box',
        borderRadius: 8,
        marginRight: spacing(1),
        marginBottom: spacing(1),
        color: isConsumer ? palette.grey.shade[100] : palette.grey.shade[80],
        backgroundColor: isConsumer ? '#dadafe' : palette.common.white,
        fontWeight: typography.fontWeightRegular,
        ...typography.body.s,
        display: 'inline',
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '& b': {
          fontWeight: typography.fontWeightBold
        },
        '&:hover': {
          background: isConsumer ? palette.primary.shade[5] : palette.grey.shade[5]
        },
        maxWidth: '100%'
      }),
      itemContainer: ({ isConsumer }) => ({
        marginBottom: spacing(1),
        cursor: 'pointer',
        '&:hover': {
          background: isConsumer ? palette.primary.shade[5] : '#f6f6f6'
        },
        '& > *:not(:first-child)': {
          paddingLeft: spacing(2)
        }
      }),
      itemDescRead: {
        flex: '1 1 0'
      },
      titleRead: ({ isConsumer }) => ({
        fontSize: '14px',
        lineHeight: '20px',
        ...(isConsumer && { color: 'black' }),
        ...(!isConsumer && { fontWeight: typography.fontWeightMedium })
      }),
      subtitleRead: ({ isConsumer }) => ({
        textTransform: 'capitalize',
        fontSize: '14px',
        ...(isConsumer && { fontWeight: typography.fontWeightBold, color: 'black' })
      }),
      cardTitle: {
        fontSize: '16px',
        lineHeight: '24px'
      },
      card: {
        clipPath: cardShape,
        borderRadius: 0,
        width: '100%',
        height: '100%'
      },
      videoTitle: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px'
      },
      videoDivider: {
        height: '3px'
      },
      videoTime: {
        fontSize: '13px',
        color: palette.primary.main,
        fontWeight: 700
      },
      consumerTopicsCard: {
        background: palette.primary.main
      },
      consumerRelatedCard: {
        background: '#dadafe'
      },
      relatedSectionHeader: ({ isConsumer }) => ({
        ...(isConsumer && { color: 'black !important' })
      }),
      topicsSectionHeader: ({ isConsumer }) => ({
        ...(isConsumer && { color: 'white !important' })
      }),
      videoSectionHeader: ({ isConsumer }) => ({
        ...(isConsumer && { color: `${palette.white.off} !important` }),
        fontSize: `${typography.heading.h5.fontSize} !important`,
        lineHeight: `${typography.heading.h5.lineHeight} !important`
      }),
      videoSectionHeaderFirst: ({ isConsumer }) => ({
        padding: spacing(0, 2),
        ...(isConsumer && { backgroundColor: '#C54925' })
      }),
      videoSectionHeaderSecond: ({ isConsumer }) => ({
        padding: spacing(0, 2),
        ...(isConsumer && { backgroundColor: '#A42F20' })
      }),
      action: ({ isConsumer }) => ({
        ...(isConsumer && { color: 'palette.grey.shade[100]' })
      })
    }
  }
)

const cardShape =
  'polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0% 100%)'
