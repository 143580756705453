import React from 'react'
import { Grid, Typography } from '@mui/material'
import { usePanelStyles } from './style'

const text = `
Help your people reach their potential by finding out about Mindtools and other corporate learning products.
`

const GetMindToolsPanel = () => {
  const classes = usePanelStyles()

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      wrap='nowrap'
      className={classes.root}
    >
      <Grid item className={classes.title}>
        <Typography variant='h5' align='left' className={classes.text}>
          Get Mindtools for You and Your Colleagues
        </Typography>
      </Grid>
      <Grid item>
        <Typography align='left' className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default GetMindToolsPanel
