import makeStyles from '@mui/styles/makeStyles'
import { appBarHeight } from '../app-toolbar/style'

export const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      height: `calc(100% - ${appBarHeight}px)`, // Subtract the toolbar height
      top: appBarHeight,
      width: '100%',
      boxSizing: 'border-box',
      [breakpoints.up('sm')]: {
        width: 400 // Width for small screens and up
      },
      [breakpoints.up('md')]: {
        width: 550 // Width for medium screens and up
      },
      [breakpoints.up('lg')]: {
        width: 700 // Width for large screens and up
      }
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '16px',
    paddingBottom: '32px'
  },
  agent: {
    flexGrow: 1,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    boxSizing: 'border-box'
  }
}))
