import * as actions from './slice'

export const listSkillBites = {
  eventName: 'skillBiteList',
  onSuccess: {
    action: actions.setSkillBites,
    redux: true
  }
}

export const getSkillBiteLanguages = {
  eventName: 'getSkillBiteLanguages',
  onSuccess: {
    action: actions.setSkillBiteLanguages,
    redux: true
  }
}

export const subscribeUser = ({ onSuccess, onError }) => {
  return {
    eventName: 'skillBiteSubscribeUser',
    onSuccess: [
      { action: actions.setSkillBiteSubscription, redux: true },
      onSuccess
    ],
    onError
  }
}
