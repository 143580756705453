export const setMetaTag = (tagName, content) => {
  let tag = document.querySelector(`meta[name="${tagName}"]`)
  if (!tag) {
    tag = document.createElement('meta')
    tag.name = tagName
    document.querySelector('head').appendChild(tag)
  }
  tag.setAttribute('content', content)
}

/* Some <style /> tags are not added to the <head />, but directly to the CSSStylesheet
 * This functions ensures the tags are added are available in the html for prerender
 * https://github.com/cssinjs/jss/issues/1102#issuecomment-492776717
*/

export const generateCSSString = (elements) => {
  let styles = ''
  const stylesheets = document.styleSheets

  for (const stylesheet of stylesheets) {
    if (
      !stylesheet.href &&
        stylesheet.cssRules &&
        stylesheet.cssRules.length
    ) {
      for (const rule of stylesheet.cssRules) {
        styles += rule.cssText
      }
    }
  }

  const head = document.head

  const elStyle = document.createElement('style')
  elStyle.innerHTML = styles
  head.appendChild(elStyle)
}
