import React, { useEffect, useState } from 'react'
import { useFormFactor } from '../../../hooks'
import { DeleteForeverTwoTone } from '@mui/icons-material'
import { ActionButton, ToggleDescriptionButton } from '../../action-buttons'
import GeneralCard from '../general-card'
import { useStyles } from './styles'
const BookmarksCard = ({ content, testId, onRemove, loading, ...props }) => {
  const { isMobile } = useFormFactor()
  const classes = useStyles()

  const isFullWidth = !isMobile
  const [expanded, setExpanded] = useState(isFullWidth)
  const [hoverShadow, setHoverShadow] = useState(false)

  const handleToggleDescription = () => setExpanded(!expanded)
  const handleMouseEnter = () => {
    setHoverShadow(true)
    !isFullWidth && setExpanded(true)
  }
  const handleMouseLeave = () => {
    setHoverShadow(false)
    !isFullWidth && setExpanded(false)
  }

  useEffect(() => {
    setExpanded(isFullWidth)
  }, [isFullWidth])

  let actions = {
    leftActions: (
      <>
        <ActionButton
          variant='secondary'
          size='xs'
          label='Remove'
          loading={loading}
          onClick={onRemove}
          startIcon={<DeleteForeverTwoTone />}
        />
      </>
    )
  }

  if (!isFullWidth) {
    actions = {
      leftActions: (
        <ToggleDescriptionButton
          size='xs'
          toggled={expanded}
          onClick={handleToggleDescription}
        />
      ),
      rightActions: (
        <>
          <ActionButton
            variant='secondary'
            size='xs'
            label='Remove'
            loading={loading}
            onClick={onRemove}
            startIcon={<DeleteForeverTwoTone />}
          />
        </>
      )
    }
  }

  return (
    <GeneralCard
      fullWidth={isFullWidth}
      expand={expanded}
      size={!isFullWidth ? 'mobile' : 'large'}
      hoverShadow={hoverShadow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      actions={actions}
      content={content}
      testId={testId}
      overrides={{
        summary: isFullWidth && classes.summary,
        actions: isFullWidth && classes.actions,
        card: isFullWidth && classes.card
      }}
      right
      {...props}
    />
  )
}

export default BookmarksCard

// adapt this
