import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      // width: '100%'
    },
    avatar: {
      fontSize: typography.body1.fontSize,
      height: spacing(4),
      width: spacing(4)
    },
    comment: {
      backgroundColor: palette.comment.background,
      padding: spacing(2),
      borderRadius: spacing(1)
    },
    info: {
      '& .MuiButton-root': {
        padding: 0,
        minWidth: 0
      },
      '& .MuiTypography-root': {
        fontSize: typography.body.xs.fontSize
      }
    },
    textBtn: {
      color: palette.text.secondary
    },
    date: {
      color: palette.text.hint,
      marginTop: 1
    },
    name: {
      ...typography.body.s,
      color: palette.text.secondary
    },
    text: {
      ...typography.body.s,
      color: palette.text.primary
    }
  })
)
