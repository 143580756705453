import React, { forwardRef, createContext, useContext, useEffect } from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { userSlice, categorySlice, searchResultsSlice, campaignSlice, bonusMaterialSlice, onboardingSlice } from '../reducers'
import { useSession } from '@emerald-works/react-auth'
import { useTenant } from '@emerald-works-nova/auth'
import consts from '../consts'
import useGroup from './useGroup'
import { CONSUMER } from '../services/cognito/userGroups'
import useAuthEvents from './useAuthEvents'
import { ALLOW_PLAYLISTS_GROUP } from '../components/user-group-policy/permissions'

export const EventContext = createContext(null)

export const useEventContext = () => {
  return useContext(EventContext)
}

const EventContextProviderRef = ({ children }, ref) => {
  const { isInGroup } = useGroup()
  const tenant = useTenant()
  const isClub = tenant.pk === consts.DEFAULT_CLUB_PK
  const isConsumer = isInGroup(CONSUMER)
  const isClubConsumer = isClub && isConsumer
  const allowPlaylistOnboarding = isInGroup(ALLOW_PLAYLISTS_GROUP)

  const [getProfile] = useEvent([userSlice.eventBus.getProfile])
  const [onboardingGetUser] = useEvent([onboardingSlice.eventBus.onboardingGetUser])
  const [getCategoryDefaultHierarchy] = useEvent([categorySlice.eventBus.getCategoryDefaultHierarchy])
  const [getUserSearchHistory] = useEvent([searchResultsSlice.eventBus.getUserSearchHistory])
  const [listBonusMaterials] = useEvent([bonusMaterialSlice.eventBus.listBonusMaterials])
  const [getUserLoginCount] = useEvent([{
    eventName: 'getUserLoginCount',
    onSuccess: (count) => {
      if (count > 2) {
        if (window.hj && typeof window.hj === 'function') {
          console.log('sending to hotjar: user_has_logged_in_more_than_two_times', count)
          window.hj('event', 'user_has_logged_in_more_than_two_times')
        }
      }
    }
  }])
  const [getCampaignData] = useEvent([campaignSlice.eventBus.getCampaignData])

  const { isAuthenticated, user } = useSession()

  useAuthEvents()

  useEffect(() => {
    const email = user?.attributes?.email
    if (isAuthenticated && email && tenant?.pk === consts.DEFAULT_CLUB_PK) {
      if (window.dmPt) {
        window.dmPt('identify', email)
        window.dmPt('track')
      }
    }
  }, [isAuthenticated, user, tenant])

  useEventsOnViewLoad(() => {
    if (isClubConsumer && !listBonusMaterials.hasBeenTriggered) {
      listBonusMaterials.trigger()
    }
  }, [isClubConsumer])

  useEventsOnViewLoad(() => {
    if (isAuthenticated) {
      getProfile.trigger()
      getUserSearchHistory.trigger()
      getCategoryDefaultHierarchy.trigger()
      getUserLoginCount.trigger()
      if (allowPlaylistOnboarding) {
        onboardingGetUser.trigger()
      }
    }
    getCampaignData.trigger()
  }, [
    getProfile,
    getUserSearchHistory,
    getCategoryDefaultHierarchy,
    getUserLoginCount,
    getCampaignData,
    isAuthenticated,
    onboardingGetUser,
    allowPlaylistOnboarding
  ])

  return (
    <EventContext.Provider value={{ getProfile }}>
      {children}
    </EventContext.Provider>
  )
}

export const EventContextProvider = forwardRef(EventContextProviderRef)
