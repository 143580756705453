import React, { useCallback } from 'react'
import {
  Button,
  Hidden,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material'
import { FileCopyOutlined } from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useStyles } from './style'
import { useEvent } from '@emerald-works/react-event-bus-client'

const ClipboardText = ({
  text,
  locationId,
  from,
  onCopy,
  multiline,
  title = 'Copy to clipboard'
}) => {
  const cls = useStyles()
  const [contentLinkShared] = useEvent([{ eventName: 'contentLinkShared' }])

  const triggerLinkWasShared = useCallback(() => {
    if (contentLinkShared.hasBeenTriggered) return
    contentLinkShared.trigger({ ...(locationId && { locationId }), ...(from && { from }) })
  }, [contentLinkShared, locationId, from])

  return (
    <div>
      <TextField
        value={text}
        fullWidth
        multiline={multiline}
        variant='outlined'
        classes={{ root: cls.border }}
        inputProps={{
          className: cls.input,
          'aria-label': 'Text to be copied',
          'data-test': 'clipboard-copy-text'
        }}
        onClick={triggerLinkWasShared}
        InputProps={{
          endAdornment: (
            <Hidden smDown>
              <CopyToClipboard text={text} onCopy={() => { onCopy() }}>
                <Tooltip
                  title={title}
                  PopperProps={{ 'data-test': 'clipboard-copy-tooltip' }}
                >
                  <IconButton data-test='clipboard-copy-icon' size='large'>
                    <FileCopyOutlined />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Hidden>
          )
        }}
      />
      <CopyToClipboard text={text} onCopy={() => { onCopy(); triggerLinkWasShared() }}>
        <Button
          color='primary'
          className={cls.copyButton}
          data-test='clipboard-copy-button'
        >
          {title}
        </Button>
      </CopyToClipboard>
    </div>
  )
}

export default ClipboardText
