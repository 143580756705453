import name from './name'

const selectSliceData = state => state[name]

const selectShowToast = (state, id) =>
  selectSliceData(state).visibleToasts.includes(id)

const selectShowStackAlerts = state => selectSliceData(state).notistackAlerts

export { selectShowToast, selectShowStackAlerts }
