import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const listComments = {
  eventName: 'commentList',
  onStart: {
    action: actions.setCommentsLoadingOn,
    redux: true
  },
  onSuccess: [
    { action: actions.setComments, redux: true },
    { action: actions.setCommentsLoadingOff, redux: true }
  ],
  onError: [
    { action: actions.setCommentsLoadingOff, redux: true },
    { action: stackAlert('Unable to load comments', 'error'), redux: true }
  ]
}

export const commentAdded = {
  eventName: 'commentAdded',
  shouldTimeout: false,
  onSuccess: {
    action: actions.addComment,
    redux: true
  }
}

export const commentRemoved = {
  eventName: 'commentRemoved',
  shouldTimeout: false,
  onSuccess: {
    action: actions.deleteComment,
    redux: true
  }
}

export const commentUpdated = {
  eventName: 'commentUpdated',
  shouldTimeout: false,
  onSuccess: {
    action: actions.updateComment,
    redux: true
  }
}
