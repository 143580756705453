import { stackAlert } from '../toast/helpers'
import { bindActionPayload } from '../util'
import * as actions from './slice'

export const getProfile = {
  eventName: 'getProfile',
  onSuccess: {
    action: actions.setProfile,
    redux: true
  },
  onError: [
    {
      action: stackAlert('Unable to get the user profile', 'error'),
      redux: true
    },
    {
      action: bindActionPayload(actions.setProfileLoadError, true),
      redux: true
    }
  ]
}

export const updateProfile = {
  eventName: 'updateProfile',
  onSuccess: {
    action: actions.setProfile,
    redux: true
  }
}

export const updateLastAccessedBonusPage = {
  eventName: 'updateLastAccessedBonusPage',
  onSuccess: {
    action: actions.setProfile,
    redux: true
  }
}

export const userCookiesGenerated = {
  eventName: 'userCookiesGenerated',
  onSuccess: {
    action: actions.createCookie,
    redux: true
  }
}
