import React from 'react'
import { Outlet } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useStyles } from './style'
import { TopRectangles, useTenant } from '@emerald-works-nova/auth'

const SecondaryLayout = () => {
  const classes = useStyles()
  const tenant = useTenant()
  const isClubOrAdminTenant = ['www', 'admin'].includes(tenant?.tenantKey)

  return (
    <>
      {isClubOrAdminTenant && (
        <div className={classes.rectBarContainer}>
          <TopRectangles />
        </div>
      )}

      <Grid
        container
        direction='column'
        wrap='nowrap'
        className={classes.container}
      >
        <Grid item className={classes.mainContainer}>
          <main>
            <Outlet />
          </main>
        </Grid>
      </Grid>
    </>
  )
}

export default SecondaryLayout
