import i18n from '../../i18n'

export const getI18nLanguageCode = (language) => {
  const customIdentifier = {
    'zh-cn': 'zh_CN',
    'zh-tw': 'zh_TW'
  }
  return customIdentifier[language] ?? language
}

export const getI18nDateLanguageCode = (i18nLanguage) => {
  const customIdentifier = {
    zh_CN: 'zh-cn',
    zh_TW: 'zh-tw'
  }
  return customIdentifier[i18nLanguage] ?? i18nLanguage
}

export const updateI18nLanguage = async (language = 'en') => {
  const lang = getI18nLanguageCode(language)

  if (lang !== i18n.language) {
    await i18n.changeLanguage(lang)
  }
}
