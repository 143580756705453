import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const categoryMenu = [
  { id: 'divider-1', divider: true },
  { id: '100', name: 'Most Popular', noExpand: true, sk: 'most-popular', path: '/most-popular' }
]

const initialState = {
  groups: undefined,
  categories: undefined,
  trending: undefined,
  popular: undefined,
  related: [],
  newestReleases: undefined,
  recommended: undefined
}

export const explore = createSlice({
  name,
  initialState,
  reducers: {
    setHierarchy: (state, { payload }) => {
      const groups = payload.hierarchy.map(({ sk, name, staticUrl }) => ({
        sk,
        name,
        staticUrl
      }))

      const categories = payload.hierarchy.reduce(
        (categories, { sk, children }) => {
          const categoryChildren = children ?? []
          categories[sk] = categoryChildren
          return categories
        }, {}
      )
      state.groups = [...groups, ...categoryMenu]
      state.categories = categories
      state.hierarchy = payload.hierarchy
    },
    setDiscover: (state, { payload }) => {
      const { popular, newestReleases, trending, recommendForYou } = payload
      state.popular = popular
      state.trending = trending
      state.recommended = recommendForYou
      state.newestReleases = newestReleases
    },
    setNewestReleases: (state, { payload }) => {
      state.newestReleases = payload.content
    },
    setPopular: (state, { payload }) => {
      state.popular = payload
    },
    setTrending: (state, { payload }) => {
      state.trending = payload
    },
    setRecommended: (state, { payload }) => {
      state.recommended = payload
    },
    setRelated: (state, { payload }) => {
      state.related = payload
    },
    reset: () => initialState
  }
})

export const {
  setHierarchy,
  setDiscover,
  setPopular,
  setTrending,
  setNewestReleases,
  setRecommended,
  setRelated,
  reset
} = explore.actions

export default explore.reducer
