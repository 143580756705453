import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { ReactComponent as NotFound } from '../../pages/not-found/images/not-found.svg'
import { ReactComponent as Error } from '../../pages/error/images/error.svg'
import { createTheme } from '../../theme'
import { Desc, Image, ServicePage, Title } from '../service-page'

const configurePage = (error) => {
  switch (error?.kind) {
    case 'not-found':
      return {
        title: '404 - Page Not Found',
        desc: ["We can't find the page you're looking for."],
        image: NotFound
      }
    default:
      return {
        title: 'Oh no! Something went wrong',
        desc: ['We keep track of these errors, but feel free to contact us if',
          "refreshing doesn't fix things."],
        image: Error
      }
  }
}
const TenantError = ({ error }) => {
  const { title, desc, image } = configurePage(error)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme()}>
        <ServicePage maxContentWidth={1440} inset={3}>
          <Title>{title}</Title>
          <Desc text={desc} />
          <Image imageComponent={image} />
        </ServicePage>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default TenantError
