import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ palette, typography, spacing, breakpoints, props }) => ({
    root: {
      transition: 'filter 0.2s',
      width: props => (props.fullWidth ? '100%' : undefined),
      filter: props =>
        props?.hoverShadow && 'drop-shadow(0px 10px 10px #CCC9CF)'
    },
    card: props =>
      props.fullWidth
        ? {
            display: 'flex',
            clipPath: cardShape,
            borderRadius: 0
          }
        : {
            position: 'relative',
            width: '100%',
            paddingBottom: '100%',
            borderRadius: 0,
            clipPath: cardShape
          },
    edge: {
      width: '100%',
      height: '100%',
      paddingRight: 1,
      paddingBottom: 1,
      clipPath: cardShape,
      backgroundColor: palette.grey.shade[20]
    },
    actionAreaHighlighted: {
      background: props => (props.fullWidth ? 'transparent' : undefined)
    },
    stack: {
      boxSizing: 'border-box',
      height: 'inherit',
      clipPath: 'inherit',
      paddingRight: 5,
      paddingBottom: 5,
      backgroundColor: ({ number }) => {
        if (number === undefined) return
        const shades = Object.entries(palette.grey.shade)
        if (number >= shades.length) return
        return shades[number][1]
      }
    },
    image: props =>
      props.fullWidth
        ? {
            width: props.right ? '150px' : '288px',
            height: props.right ? '120px' : 'auto',
            margin: props.right ? 'auto' : 'inherit'
          }
        : {
            position: 'absolute',
            top: 0,
            left: 0,
            width: `calc(100% - ${spacing(3)})`,
            objectFit: 'cover',
            height: props.right ? '100%' : '50%',
            '&:hover': {
              cursor: 'pointer'
            }
          },
    summary: props =>
      props.fullWidth
        ? {
            width: props.right ? 'calc(100% - 210px)' : 'calc(100% - 288px)',
            marginTop: '17px',
            marginBottom: 0,
            background: '#FFF',
            marginLeft: props.right ? spacing(2) : '-32px',
            padding: '10px 0px 0px 16px',
            zIndex: 2
          }
        : {
            boxSizing: 'border-box',
            width: `calc(100% - ${spacing(2)})`,
            bottom: 0,
            right: 0,
            background: '#FFF',
            position: 'absolute',
            marginLeft: spacing(2),
            padding: spacing(2),
            paddingRight: spacing(3)
          },
    title: props =>
      props.fullWidth
        ? {
            ...typography.body.l,
            fontWeight: typography.weight.bold,
            ...multiNoWrap(1)
          }
        : {
            ...typography.body.l,
            fontWeight: typography.weight.bold,
            minHeight: `${typography.body.l.lineHeight * 2}em`,
            ...multiNoWrap(2)
          },
    subtitle: props =>
      props.fullWidth
        ? {
            marginTop: spacing(1),
            marginBottom: spacing(1),
            ...typography.body.m,
            fontWeight: typography.weight.r,
            ...multiNoWrap(1)
          }
        : {
            marginTop: spacing(1),
            ...typography.body.m,
            fontWeight: typography.weight.r,
            minHeight: props.isPlaylist ? `${typography.body.m.lineHeight * 3}em` : 'auto',
            ...multiNoWrap(3)
          },
    header: {
      maxWidth: '100%',
      marginBottom: spacing(1)
    },
    info: {
      color: palette.text.secondary,
      fontWeight: typography.fontWeightMedium,
      '& svg': {
        marginRight: spacing(0.5)
      }
    },
    time: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold
    },
    actions: {
      marginTop: props => (props.fullWidth ? 0 : spacing(3)),
      marginBottom: spacing(2)
    }
  })
)

const cardShape =
  'polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0% 100%)'

const multiNoWrap = lines => {
  return {
    lineClamp: lines,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical'
  }
}
