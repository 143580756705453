import React from 'react'
import Alert, { AlertTitle } from '../alert'
import { Link } from '..'
import { routes } from '../../routes'

const RemovedAlert = () => (
  <Alert severity='success' color='info' showClose>
    <AlertTitle>Successfully removed</AlertTitle>
  </Alert>
)

const AddedAlert = () => (
  <Alert severity='success' data-test='bookmark-success-alert'>
    <AlertTitle>Successfully bookmarked</AlertTitle>
    <Link
      to={routes.bookmarks.path}
      underline='always'
      variant='body1'
    >
      View Bookmarks page
    </Link>
  </Alert>
)
export { RemovedAlert, AddedAlert }
