import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  skillBite: null,
  session: null,
  currentSection: null,
  currentPage: {}
}

export const skillBite = createSlice({
  name,
  initialState,
  reducers: {
    setSkillBite: (state, { payload }) => {
      state.skillBite = payload
    },
    setSession: (state, { payload }) => {
      state.session = payload
      const currentSection = payload.sections[0]
      state.currentSection = currentSection
      state.currentPage = { ...state.currentPage, ...currentSection.pages[0] }
    },
    setCurrentSection: (state, { payload }) => {
      state.currentSection = payload
    },
    setCurrentPageWithBlocks: (state, { payload }) => {
      if (state.currentPage?.id !== payload.id) return state
      state.currentPage = payload
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload
    },
    nextSection: (state) => {
      const currentSectionIndex = state.session.sections.findIndex(section => section.id === state.currentSection.id)
      const nextSection = state.session.sections[currentSectionIndex + 1]
      state.currentSection = nextSection
      state.currentPage = nextSection.pages[0]
    },
    previousSection: (state) => {
      const currentSectionIndex = state.session.sections.findIndex(section => section.id === state.currentSection.id)
      const previousSection = state.session.sections[currentSectionIndex - 1]
      state.currentSection = previousSection
      state.currentPage = previousSection.pages[0]
    },
    changeSectionPage: (state, { payload }) => {
      const nextPage = state.currentSection.pages[payload.pageIndex]
      if (nextPage.id === state.currentPage.id) return state
      state.currentPage = nextPage
    },
    changeSection: (state, { payload }) => {
      const nextSection = state.session.sections.find(section => section.id === payload.sectionId)
      if (nextSection.id === state.currentSection.id) return state
      state.currentSection = nextSection
      state.currentPage = nextSection.pages[0]
    },
    reset: (state) => {
      state.skillBite = null
      state.session = null
      state.currentSection = null
      state.currentPage = {}
    }
  }
})

export const {
  setSkillBite,
  setSession,
  setCurrentSection,
  setCurrentPage,
  nextSection,
  previousSection,
  changeSectionPage,
  changeSection,
  setCurrentPageWithBlocks,
  reset
} = skillBite.actions

export default skillBite.reducer
