import { useEvent } from '@emerald-works/react-event-bus-client'
import { Hub } from '@aws-amplify/core'
import { useEffect } from 'react'
import { useSession } from '@emerald-works/react-auth'
import { getUserPrimaryIdentity } from '../helpers/checkForUserIdentities'
import store from '../reducers/store'
import { userSlice } from '../reducers'
import { useDispatch, useSelector } from 'react-redux'

/**
 * Listen to Amplify auth events and trigger events or other actions
 * depending on their success/faliure
 */
const EVENTS = {
  signIn: 'signIn',
  signIn_failure: 'signIn_failure',
  signOut: 'signOut'
}
// Hub needs to be initialised before hook
Hub.listen('auth', ({ payload }) => {
  if (EVENTS[payload.event]) {
    store.dispatch(userSlice.actions.addAuthEvent(JSON.stringify(payload)))
  }
})

const useAuthEvents = () => {
  const dispatch = useDispatch()
  const authEvents = useSelector(userSlice.selectors.selectAuthEvents)

  const [loginSucceed, loginFailed] = useEvent([
    { eventName: 'userLoginSucceeded' },
    { eventName: 'userLoginFailed' }
  ])
  const { isAuthenticated, user } = useSession()

  useEffect(() => {
    if (!authEvents.length) return

    const unprocessed = authEvents.filter(value => {
      const item = JSON.parse(value)

      switch (item.event) {
        case EVENTS.signIn:
          if (isAuthenticated) {
            let identity = getUserPrimaryIdentity(user)
            if (!identity) {
              identity = { providerType: 'username_password' }
            }
            loginSucceed.trigger({ identity })
            return false
          }
          return true
        case EVENTS.signOut:
          return false
        case EVENTS.signIn_failure: {
          const { data } = item
          loginFailed.trigger({ reason: data?.message, code: data?.code })
          return false
        }
        default:
          return false
      }
    })
    if (unprocessed.length !== authEvents.length) {
      dispatch(userSlice.actions.setAuthEvents(unprocessed))
    }
  }, [authEvents, dispatch, isAuthenticated, loginSucceed, loginFailed, user])
}

export default useAuthEvents
