import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const getCategoryHierarchy = {
  eventName: 'categoryGetDefaultHierarchy',
  onSuccess: {
    action: actions.setHierarchy,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get the category hierarchy', 'error'),
    redux: true
  }
}

// TODO: replace once trending/popular events are available
export const getDiscover = {
  eventName: 'contentGetExploreMenuContent',
  onSuccess: {
    action: actions.setDiscover,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get the discover content', 'error'),
    redux: true
  }
}
