import basePalette from './base-palette'
import * as helpers from './helpers'
import { darken, lighten } from '@mui/material'
import { merge as deepMerge } from 'lodash'

const createPalette = (options = {}) => {
  const main = options?.primary?.main || '#0075BC'

  let shade = {}
  if (main) {
    shade = {
      0: lighten(main, 0.95),
      5: lighten(main, 0.9),
      10: lighten(main, 0.8),
      20: lighten(main, 0.6),
      30: lighten(main, 0.4),
      40: lighten(main, 0.2),
      50: main,
      60: darken(main, 0.2),
      70: darken(main, 0.4),
      80: darken(main, 0.6),
      90: darken(main, 0.8),
      100: darken(main, 0.9)
    }
  }

  return deepMerge(
    { ...basePalette, helpers },
    deepMerge(options, {
      primary: {
        main,
        light: shade[5],
        dark: shade[60],
        shade
      }
    })
  )
}

// const getContrast

export { createPalette }
