import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const getPlaylistSearchResults = {
  eventName: 'playlistSearch',
  onSuccess: {
    action: actions.setResults,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get playlists', 'error'),
    redux: true
  }
}
