import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  fullScreenWrapper: {
    [breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: spacing(2),
      background: 'white'
    }
  },
  container: ({ bps }) => ({
    position: 'relative',
    [breakpoints.down(bps.tablet)]: {
      width: 0,
      height: 0,
      position: 'absolute',
      top: -50
    }
  }),
  searchRoot: ({ bps }) => ({
    [breakpoints.up(bps.largeDesktop)]: {
      // Avoids the search input being ridiculously wide in ridiculously wide windows.
      maxWidth: '45rem'
    }
  }),
  search: {
    width: '100%',
    clipPath: searchBarShape,
    background: '#F1F3F4',
    padding: '2px 8px',
    '& .MuiInputAdornment-root': {
      color: '#4F4F4F'
    },
    '& .MuiDivider-root': {
      height: 20,
      color: palette.grey.shade[5],
      marginRight: spacing(1)
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '&:hover': {
        borderRadius: 0
      }
    }
  },
  searchOnClick: ({ bps }) => ({
    [breakpoints.up('xs')]: {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      width: '70vw',
      minWidth: '300px',
      height: '36px'
    },
    [breakpoints.up('sm')]: {
      maxWidth: '60vw'
    },
    [breakpoints.up('md')]: {
      background: 'white',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.18)',
      width: 'unset'
    }
  })
}))

const searchBarShape =
  'polygon(0% 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0% 100%)'
