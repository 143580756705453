import name from './name'
import { createSlice } from '@reduxjs/toolkit'
import { genSetter } from '../util'

const initialState = {
  error: undefined
}

const error = createSlice({
  name,
  initialState,
  reducers: {
    setError: genSetter('error')
  }
})

export const { setError } = error.actions

export default error.reducer
