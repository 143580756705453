import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography, customShadows }) => ({
    root: {
      padding: spacing(4),
      boxShadow: customShadows.main
    },
    header: {
      ...typography.heading.h5,
      fontWeight: typography.weight.bold
    },
    sorter: {
      borderRadius: spacing(0.5),
      border: `1px solid ${palette.comment.sorterColor}`,
      '& .MuiToggleButton-root': {
        color: palette.common.black,
        border: 'none',
        backgroundColor: palette.comment.sorterColor,
        paddingInline: spacing(1.5),
        paddingBlock: spacing(0.5),
        '&:hover': {
          backgroundColor: palette.grey.shade[5]
        }
      },
      '& .Mui-selected': {
        backgroundColor: palette.white.main,
        boxSizing: 'border-box'
      },
      '& .MuiToggleButton-label': {
        textTransform: 'none'
      }
    },
    loadMore: {
      backgroundColor: palette.comment.loadMoreBackgroundColor,
      '& .MuiTypography-root:hover': {
        textDecoration: 'underline'
      }
    },
    comments: {
      overflow: 'hidden'
    }
  })
)
