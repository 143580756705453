/* eslint-disable indent */
import React, { cloneElement } from 'react'
import { IconButton, Snackbar } from '@mui/material'
import { Close } from '@mui/icons-material'
import ToastContext from './context'

const defaultAnchor = {
  vertical: 'top',
  horizontal: 'right'
}

const Toast = ({
  open,
  message,
  children,
  onClose,
  autoHideDuration = 3000,
  anchorOrigin = defaultAnchor,
  showClose
}) => {
  return (
    <ToastContext.Provider value={{ onClose }}>
      <Snackbar
        open={open}
        onClose={onClose}
        message={message}
        autoHideDuration={autoHideDuration}
        anchorOrigin={anchorOrigin}
        action={
          <IconButton
            size='small'
            aria-label='close'
            onClick={onClose}
            color='inherit'
            data-test='toast-close'
          >
            <Close fontSize='small' />
          </IconButton>
        }
      >
        <div>
          {!children
            ? null
            : showClose
            ? cloneElement(children, { onClose })
            : children}
        </div>
      </Snackbar>
    </ToastContext.Provider>
  )
}

export default Toast
