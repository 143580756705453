/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import AddToPlaylistView from './view'
import State from './state'
import { useAlert } from '../../hooks'

const pageSize = 4

const AddToPlaylistDialog = ({
  article,
  backText,
  onClose,
  onContentInserted,
  onPlaylistCreate: handlePlaylistCreate,
  open
}) => {
  const [term, setTerm] = useState('')
  const [results, setResults] = useState([])
  const [filtered, setFiltered] = useState([])
  const [selectedPlaylist, setSelectedPlaylist] = useState()
  const [state, setState] = useState(State.idle)

  const { enqueueMsg } = useAlert()

  const playlists = term ? filtered : results

  const [playlistSearch, playlistInsertContent] = useEvent([
    {
      eventName: 'playlistSearch',
      onSuccess: ({ items }) => setResults(items),
      onError: () => enqueueMsg('Playlist search failed', 'error')
    },
    {
      eventName: 'playlistInsertContent',
      onSuccess: ({ playlist }) => onContentInserted({ playlist }),
      onError: () => enqueueMsg('Adding article to playlist failed', 'error')
    }
  ])

  useEventsOnViewLoad(() => {
    playlistSearch.trigger({
      filters: {
        isCreator: true
      },
      size: 100,
      attributes: ['pk', 'title']
    })
  }, [playlistSearch])

  useEffect(() => {
    setState(
      playlistSearch.isWorking
        ? State.searching
        : playlistInsertContent.isWorking
        ? State.inserting
        : State.idle
    )
  }, [playlistSearch.isWorking, playlistInsertContent.isWorking])

  const handleChange = ({ target: { value } }) => {
    setTerm(value)
    handleSearch(value.trim())
  }
  const handleSearch = term => {
    const filtered = results.filter(playlist =>
      playlist.title.toLowerCase().includes(term.toLowerCase())
    )
    setFiltered(filtered)
  }

  const handleContentInsert = () => {
    playlistInsertContent.trigger({
      playlistId: selectedPlaylist.pk,
      locationId: article.locationId
    })
  }

  const handlePlaylistChange = playlist => {
    setSelectedPlaylist(current =>
      playlist !== current ? playlist : undefined
    )
  }

  return (
    <>
      {open && (
        <AddToPlaylistView
          onClose={onClose}
          items={
            playlistSearch.isWorking
              ? new Array(pageSize).fill(null)
              : playlists
          }
          onSearch={() => {}}
          selectedPlaylist={selectedPlaylist}
          state={state}
          onPlaylistChange={handlePlaylistChange}
          onPlaylistCreate={handlePlaylistCreate}
          onContentInsert={handleContentInsert}
          searchInputProps={{
            value: term,
            onChange: handleChange
          }}
        />
      )}
    </>
  )
}

export default AddToPlaylistDialog
