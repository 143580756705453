import React from 'react'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.label
})

const getLabel = (option) => {
  if (option.translatedLabel) {
    return `${option.translatedLabel} (${option.label})`
  }

  if (option.label) {
    return option.label
  }

  return 'Language Settings'
}

const Menu = ({ value: currentValue, onChange, languages, width = '100%', isLoading, label = 'Language' }) => (
  <Autocomplete
    id='article-translate-autocomplete'
    sx={{ maxWidth: width }}
    disabled={isLoading}
    fullWidth
    value={currentValue}
    options={languages}
    getOptionLabel={getLabel}
    filterOptions={filterOptions}
    onChange={(_event, newValue) => onChange(newValue)}
    renderInput={params => (
      <TextField
        {...params}
        margin='dense'
        label={label}
        inputProps={{ ...params.inputProps, 'data-test': 'article-translate-selector' }}
        SelectProps={{ SelectDisplayProps: { 'data-test': 'article-translate-menu' } }}
      />
    )}
  />
)

export default Menu
