const checkForUserIdentities = user => {
  try {
    if (user.attributes?.identities) {
      return JSON.parse(user.attributes.identities)
    }
    return null
  } catch (err) {
    console.log('error parsing user identities', err)
    return null
  }
}

export const getUserPrimaryIdentity = user => {
  const identities = checkForUserIdentities(user)
  if (identities && identities.length) {
    const primary = identities.find(identity => identity.primary) || identities[0]
    const { providerName, providerType } = primary
    return { providerName, providerType }
  }
  return null
}
