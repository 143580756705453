import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@mui/material'
import { WarningTwoTone } from '@mui/icons-material'
import { ActionButton } from '../../components'
import { Variant, useStyles } from './style'

const ConfirmDialog = ({
  name = 'confirm',
  title = 'Are you sure?',
  variant = 'info',
  description,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  open,
  onClose,
  onConfirm,
  DialogProps = {}
}) => {
  const classes = useStyles({ variant })

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...DialogProps}
      aria-label={`${name} dialog`}
      data-test={`${name}-dialog`}
      PaperProps={{ className: classes.root }}
    >
      <DialogContent>
        <Grid container alignItems='flex-start' spacing={2}>
          <Grid item>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              className={classes.iconContainer}
            >
              <WarningTwoTone fontSize='medium' className={classes.icon} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Typography
                  className={classes.title}
                  data-test={`${name}-dialog-title`}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.text}
                  data-test={`${name}-dialog-description`}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.actions}
        data-test={`${name}-dialog-actions`}
      >
        <ActionButton
          variant='ghost'
          label={cancelText}
          onClick={onClose}
          data-test={`${name}-dialog-cancel`}
        />
        <ActionButton
          variant={Variant[variant].color}
          label={confirmText}
          onClick={handleConfirm}
          data-test={`${name}-dialog-confirm`}
        />
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  /** Name of the dialog, e.g Delete Comment */
  name: PropTypes.string.isRequired,
  /** Appearence of the dialog */
  variant: PropTypes.oneOf(['info', 'warning']),
  /** Text to appear on the button (also used for ```aria-label``` if not provided) */
  title: PropTypes.string.isRequired,
  /** Text to appear on the button (also used for ```aria-label``` if not provided) */
  description: PropTypes.string,
  /** Text displayed on the cancel button */
  cancelText: PropTypes.string,
  /** Text displayed on the confirm button */
  confirmText: PropTypes.string,
  /** Control the visibility of the dialog */
  open: PropTypes.bool.isRequired,
  /** Action performed when the dialog is closed or canceled */
  onClose: PropTypes.func,
  /** Action performed when the confirm button is pressed */
  onConfirm: PropTypes.func
}

export default ConfirmDialog
