import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { playlistSlice } from '..'

const initialState = {
  results: [],
  total: 0,
  counts: {
    // @@@ Currently no times in the data so use this.
    time: {
      labels: ['Start Time', 'End Time'],
      values: [0, 20],
      unit: 'mins'
    }
  },
  localState: undefined
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setResults: (state, { payload: { items, total, counts } }) => {
      // Local state is a playlist created or deleted in this UI. It takes
      // a while for these changes to be reflected in the api response so
      // if needed we patch the api response in here.
      const { localState: { playlist, type } = {} } = state
      if (!type) {
        // No local state
        state.results = items
        state.total = total
      } else {
        const index = items.findIndex(({ pk }) => playlist.pk === pk)
        const isApiCurrent =
          (type === 'create' && index >= 0) || (type === 'delete' && index < 0)
        if (isApiCurrent) {
          state.results = items
          state.total = total
          state.localState = undefined
        } else {
          if (type === 'create') {
            state.results = [playlist, ...items]
            state.total = total + 1
          } else if (type === 'delete') {
            state.results = [
              ...items.slice(0, index),
              ...items.slice(index + 1)
            ]
            state.total = total - 1
          }
        }
      }
      // Counts will probably be wrong if we have reflected local state in the api results.
      state.counts = { ...state.counts, ...counts }
    },
    setLocalState: (state, { payload }) => {
      state.localState = payload
    },
    toggleLike: (state, { payload: { playlistId, status, byMostLiked } }) => {
      const sortAsCurrent = () => 0
      const sortLikeDesc = (a, b) => b.likeCount - a.likeCount

      const index = state.results.findIndex(({ pk }) => pk === playlistId)
      if (index < 0) return
      const playlist = state.results[index]
      state.results = [
        ...state.results.slice(0, index),
        {
          ...playlist,
          likeCount: (playlist.likeCount += status === 'liked' ? 1 : -1),
          hasLiked: status === 'liked'
        },
        ...state.results.slice(index + 1)
      ].sort(byMostLiked ? sortLikeDesc : sortAsCurrent)
    },
    reset: state => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(playlistSlice.actions.setReadTimes, (state, { payload }) => {
        state.results = state.results.map(item => {
          const playlist = payload.find((playlistItem) => playlistItem.playlistId === item.pk)
          item.readTime = playlist?.readTime || 0
          return item
        })
      })
  }
})

export const { setResults, setLocalState, toggleLike, reset } = content.actions

export default content.reducer
