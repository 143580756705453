import { useGroup } from '.'
import { ALL_CONSUMER } from '../services/cognito/userGroups'

const consumerContact = {
  email: 'mtecustomerservices@mindtools.com',
  phone: '+44 0131 344 4560'
}

const enterpriseContact = {
  email: 'mtfbsupport@mindtools.com'
}

const useContactDetails = () => {
  const { isInGroup } = useGroup()

  return isInGroup(ALL_CONSUMER) ? consumerContact : enterpriseContact
}

export default useContactDetails
