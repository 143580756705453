import React, { forwardRef } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const IconMenu = forwardRef(({ options, icon, onChange, id, ...props }, ref) => {
  const menuId = `${id}-menu`
  return (
    <PopupState variant='popover' popupId={id}>
      {popupState => (
        <>
          <IconButton
            data-test={`${menuId}-button`}
            {...props}
            {...bindTrigger(popupState)}
            size='large'
          >
            {icon}
          </IconButton>
          <Menu id={menuId} data-test={menuId} {...bindMenu(popupState)}>
            {options.map(({ label, value }, index) => (
              <MenuItem
                onClick={() => {
                  popupState.close()
                  onChange(value)
                }}
                key={value}
                data-test={`${menuId}-item-${index}`}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  )
})

IconMenu.displayName = 'IconMenu'

export default IconMenu
