import React from 'react'
import { Typography, Grid, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    ...typography.heading.h5,
    fontWeight: typography.weight.medium,
    color: palette.grey.shade[100]
  },
  close: {
    color: '#323232'
  }
}))

const Header = ({ onClose, disabled, id, children }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      wrap='nowrap'
    >
      <Grid item>
        <Typography
          id={id}
          variant='h5'
          component='h1'
          className={classes.header}
        >
          {children}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          aria-label='close add to playlist dialog'
          onClick={onClose}
          disabled={disabled}
          data-test='addtoplaylist-close'
          className={classes.close}
          size='large'
        >
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default Header
