import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Avatar, Typography, Zoom, Skeleton } from '@mui/material'
import { LikeButton } from '../../action-buttons'
import { ActionButton, ConfirmDialog } from '../../'
import { formatDistance } from 'date-fns'
import { useStyles } from './style'
import { commentSlice, contentSlice } from '../../../reducers'
import {
  useEvent, useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import { useDispatch, useSelector } from 'react-redux'
import { ALLOW_DELETE_COMMENTS_GROUP } from '../../user-group-policy/permissions'
import { useAlert, useGroup } from '../../../hooks'

const COMPONENT_STATE = {
  BASE: 'base',
  LOADING: 'loading'
}

/** Displays a user's comment */
const Comment = ({
  comment = {},
  isReply = false,
  onReply,
  isLiked = false,
  onLike,
  onReport,
  onDelete
}) => {
  const { id, author, text, likeCount, date, owner, hasLiked } = comment
  const classes = useStyles({ comment, isReply, onReply, isLiked, onLike, onReport, onDelete })
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [componentState, setComponentState] = useState(COMPONENT_STATE.LOADING)
  const { locationId } = useSelector(contentSlice.selectors.selectContent)

  const temporaryId = id ?? 'loading'
  const { isInGroup } = useGroup()

  const { givenName = '', familyName = '' } = author || {}
  const authorName = `${givenName} ${familyName}`
  const authorSlug = `${givenName?.charAt(0)}${familyName?.charAt(0)}`

  const { enqueueMsg } = useAlert()

  const [likeComment, deleteComment] = useEvent([
    {
      eventName: 'commentToggleLike',
      onError: () => {
        dispatch(commentSlice.actions.toggleCommentLike({ comment }))
        enqueueMsg(
          `Unable to ${comment.hasLiked ? 'unlike' : 'like'} comment`,
          'error'
        )
      }
    },
    {
      eventName: 'commentRemove',
      onStart: () => setComponentState(COMPONENT_STATE.LOADING),
      onStop: () => setComponentState(COMPONENT_STATE.BASE),
      onSuccess: (payload) => {
        dispatch(commentSlice.actions.deleteComment(payload))
      },
      onError: () => enqueueMsg('Unable to delete comment', 'error')
    }
  ])

  const [commentUpdated] = useEvent([
    commentSlice.eventBus.commentUpdated
  ])

  const handleLike = async () => {
    dispatch(commentSlice.actions.toggleCommentLike({ comment }))
    likeComment.trigger({ commentSk: id, locationId, isLike: !hasLiked })
  }

  const handleDeleteOnModal = () => {
    deleteComment.trigger({ commentSk: id, locationId, type: isReply ? 'reply' : 'comment' })
  }

  useEventsOnViewLoad(() => {
    if (id) {
      commentUpdated.subscribeToEvent({ id })
    }
  }, [id, commentUpdated])

  useEffect(() => {
    if (!Object.keys(comment).length) {
      setComponentState(COMPONENT_STATE.LOADING)
    } else {
      setComponentState(COMPONENT_STATE.BASE)
    }
  }, [comment])

  if (componentState === COMPONENT_STATE.LOADING) {
    return <LoadingState temporaryId={temporaryId} classes={classes} />
  }

  return (
    <>
      <ConfirmDialog
        title='Delete comment'
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleDeleteOnModal}
        description='Would you like to delete this comment?'
        data-test='comment-confirm-delete-dialog'
      />
      <Zoom in duration={200}>
        <Grid
          container
          data-test={`comment-${id}`}
          spacing={2}
          wrap='nowrap'
          className={classes.root}
        >
          <Grid item>
            <Avatar
              alt={authorName}
              src={author?.image}
              name={authorName}
              className={classes.avatar}
              data-test={`comment-author-avatar-${id}`}
            >
              {authorSlug}
            </Avatar>
          </Grid>
          <Grid container item direction='column' spacing={1}>
            <Grid container direction='column' className={classes.comment}>
              <Grid item>
                <Typography
                  className={classes.name}
                  data-test={`comment-author-name-${temporaryId}`}
                >
                  {authorName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.text}
                  data-test={`comment-text-${temporaryId}`}
                >
                  {text}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              wrap='nowrap'
              justifyContent='space-between'
              alignItems='center'
              className={classes.info}
            >
              <Grid
                container
                item
                wrap='nowrap'
                spacing={1}
                alignItems='center'
              >
                <Grid item>
                  <LikeButton
                    color={hasLiked ? 'primary' : 'inherit'}
                    size='xs'
                    variant='none'
                    likes={likeCount}
                    onClick={() => handleLike(id)}
                    data-test={`comment-like-button-${temporaryId}`}
                  />
                </Grid>
                {!isReply && (
                  <Grid item>
                    <ActionButton
                      variant='none'
                      size='xs'
                      label='Reply'
                      onClick={onReply}
                      className={classes.textBtn}
                      data-test={`comment-reply-button-${temporaryId}`}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Typography> &middot; </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.date}
                    noWrap
                    data-test={`comment-date-${temporaryId}`}
                  >
                    {formatDistance(new Date(date), new Date(), {
                      addSufix: true
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                wrap='nowrap'
                spacing={1}
                justifyContent='flex-end'
              >
                {(owner || isInGroup(ALLOW_DELETE_COMMENTS_GROUP)) && (
                  <Grid item>
                    <ActionButton
                      variant='none'
                      size='xs'
                      label='Delete'
                      onClick={() => setOpen(true)}
                      className={classes.textBtn}
                      data-test={`comment-delete-button-${temporaryId}`}
                    />
                  </Grid>
                )}
                {/* todo: add back in when api support is added.
                  {!owner && (
                  <Grid item>
                    <ActionButton
                      variant='none'
                      size='xs'
                      label='Report'
                      onClick={handleReport}
                      className={classes.textBtn}
                      data-test={`comment-report-button-${temporaryId}`}
                    />
                  </Grid>
                )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Zoom>
    </>
  )
}

const LoadingState = ({ classes, temporaryId }) => (
  <Zoom in duration={200}>
    <Grid container spacing={2} wrap='nowrap' className={classes.root}>
      <Grid item>
        <Skeleton variant='circular' className={classes.avatar} />
      </Grid>
      <Grid container item direction='column' spacing={1}>
        <Grid container direction='column' className={classes.comment}>
          <Grid item>
            <Typography
              className={classes.name}
              data-test={`comment-author-name-${temporaryId}`}
            >
              <Skeleton width='20%' />
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.text}
              data-test={`comment-text-${temporaryId}`}
            >
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Zoom>
)

export default Comment

Comment.propTypes = {
  /** Details of the comment (author, text, likeCount, date) */
  comment: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string
    }),
    text: PropTypes.string,
    likeCount: PropTypes.number,
    date: PropTypes.any
  }),
  /** Has the current user liked this comment? */
  isLiked: PropTypes.bool,
  /** If true the comment will not have a reply button */
  isReply: PropTypes.bool,
  /** Callback fired when the 'Like' button is clicked */
  onLike: PropTypes.func,
  /** Callback fired when the 'Reply' button is clicked */
  onReply: PropTypes.func,
  /** Callback fired when the 'Report' button is clicked */
  onReport: PropTypes.func,
  /** Callback fired when the 'Delete' button is clicked */
  onDelete: PropTypes.func
}
