import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import { CampaignActionBtn } from './campaign-btn'
import { ColourSchemes } from './campaign-util'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  verticalBannerCtn: {
    width: 280,
    minHeight: 250,
    backgroundColor: ({ bg }) => bg
  },
  verticalBannerTxtCtn: ({ imageAtTop }) => ({
    padding: imageAtTop ? spacing(0) : spacing(3.5, 2.75)
  }),
  verticalBannerTitle: {
    color: ({ text }) => text,
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2)
  },
  verticalBannerDescription: {
    color: ({ text }) => text,
    ...typography.body.l,
    marginBottom: spacing(3)
  },
  verticalBannerImg: {
    width: '100%',
    maxHeight: '40%',
    objectFit: 'cover',
    marginBottom: spacing(3)
  },
  verticalBannerCard: {
    display: 'flex',
    borderRadius: ({ rounded }) => rounded ? 10 : 0,
    width: '100%',
    margin: 'auto',
    backgroundColor: ({ bg }) => bg
  },
  verticalBannerEdge: {
    paddingRight: 1,
    paddingBottom: 1,
    width: '100%',
    backgroundColor: ({ bg }) => bg
  },
  otherThanImgCtn: ({ imageAtTop }) => ({
    ...(imageAtTop && { padding: spacing(0, 2.75, 3.5, 2.75) })
  })
}))

export const MobileVerticalCampaignBanner = ({ campaign, imageAtTop, rounded }) => {
  const { colourScheme, squareImg, title, body } = campaign || {}
  const { bg, text } = ColourSchemes[colourScheme] || {}
  const classes = useStyles({ bg, text, imageAtTop, rounded })

  if (!campaign) return null
  return (
    <Grid className={classNames(classes.verticalBannerCtn, classes.verticalBannerCard)} container direction='row'>
      <Grid item xs={12} className={classes.verticalBannerTxtCtn}>
        {imageAtTop &&
          <Grid item xs={12}>
            <img src={squareImg?.imgUrl} className={classes.verticalBannerImg} />
          </Grid>}
        <div className={classes.otherThanImgCtn}>
          <Typography className={classes.verticalBannerTitle} variant='h3'>{title}</Typography>
          <Typography
            variant='body1'
            className={classes.verticalBannerDescription}
            dangerouslySetInnerHTML={{ __html: body }}
          />
          {!imageAtTop &&
            <Grid item xs={12}>
              <img src={squareImg?.imgUrl} className={classes.verticalBannerImg} />
            </Grid>}
          <CampaignActionBtn campaign={campaign} fullWidth size='large' />
        </div>
      </Grid>
    </Grid>
  )
}
