import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  results: [],
  suggestions: [],
  history: [],
  total: undefined
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setResults: (state, { payload: { content, total, counts, term } }) => {
      state.results = content
      state.total = total
      state.counts = { ...state.counts, ...counts }

      if (term) {
        const possiblePositionInHistory = state.history.indexOf(term)
        if (possiblePositionInHistory === -1) {
          if (state.history.length >= 3) {
            state.history.pop()
          }
          state.history.unshift(term)
        } else {
          state.history.splice(possiblePositionInHistory, 1)
          state.history.unshift(term)
        }
      }
    },
    setSuggestions: (state, { payload: { suggestions } }) => {
      state.suggestions = suggestions
    },
    setHistory: (state, { payload: { history } }) => {
      state.history = history
    },
    reset: state => ({ ...initialState, history: state.history })
  }
})

export const { setResults, setSuggestions, setHistory, reset } = content.actions

export default content.reducer
