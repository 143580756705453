import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { ASSESSMENT_STATUS } from '../../consts'

const initialState = {
  externalId: '',
  metaSkills: new Array(4).fill({}),
  assessmentStatus: ASSESSMENT_STATUS.NOT_STARTED,
  metaSkillsStatus: {},
  isTrial: false,
  isBrilliantAssessmentsDown: false
}

export const assessments = createSlice({
  name,
  initialState,
  reducers: {
    setAssessmentDetails: (state, { payload }) => {
      const { assessmentDetails } = payload
      state.metaSkills = assessmentDetails.metaSkills
      state.isTrial = assessmentDetails.isTrial
      state.externalId = assessmentDetails.isTrial
        ? assessmentDetails.trialAssessmentId
        : assessmentDetails.sk
    },
    setProgress: (state, { payload }) => {
      state.assessmentStatus = payload.userProgress.assessmentStatus
      state.metaSkillsStatus = payload.userProgress.metaSkillsStatus
      state.isBrilliantAssessmentsDown = payload.isBrilliantAssessmentsDown || false
    },
    setStartedStatus: (state, { payload }) => {
      state.assessmentStatus = payload.userProgress.assessmentStatus
      state.metaSkillsStatus = payload.userProgress.metaSkillsStatus
    }
  }
})

export const {
  setAssessmentDetails,
  setProgress,
  setStartedStatus
} = assessments.actions

export default assessments.reducer
