import React, { useCallback, useMemo } from 'react'
import {
  Add,
  DeleteTwoTone,
  SlideshowTwoTone,
  ThumbUpTwoTone,
  VisibilityOffTwoTone,
  VisibilityTwoTone,
  FactCheck
} from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import ActionButton from './action-button'
import MoreActions from './more-actions'
import { ALLOW_BOOKMARKS_GROUP, ALLOW_PLAYLISTS_GROUP } from '../user-group-policy/permissions'
import { UserGroupPolicy } from '../user-group-policy'
import { useSession } from '@emerald-works/react-auth'
import { useGroup } from '../../hooks'
import useFreeAccountCta from '../../hooks/useFreeAccountCta'

export const StartPlaylistButton = ({ ...props }) => {
  return (
    <UserGroupPolicy
      accessGroup={ALLOW_PLAYLISTS_GROUP}
      componentAllow={
        <ActionButton
          variant='primary'
          startIcon={<SlideshowTwoTone />}
          label='Open'
          {...props}
        />
      }
    />
  )
}

export const AddPlaylistToLibraryButton = ({ ...props }) => {
  return (
    <UserGroupPolicy
      accessGroup={ALLOW_PLAYLISTS_GROUP}
      componentAllow={
        <ActionButton
          variant='secondary'
          startIcon={<Add />}
          label='Add to Library'
          {...props}
        />
      }
    />
  )
}

export const RemoveFromPlaylistButton = ({ ...props }) => {
  return (
    <UserGroupPolicy
      accessGroup={ALLOW_PLAYLISTS_GROUP}
      componentAllow={
        <ActionButton
          variant='secondary'
          startIcon={<DeleteTwoTone />}
          label='Remove'
          {...props}
        />
      }
    />
  )
}

export const ResumePlaylistButton = ({ ...props }) => {
  return (
    <UserGroupPolicy
      accessGroup={ALLOW_PLAYLISTS_GROUP}
      componentAllow={
        <ActionButton
          variant='grey'
          startIcon={<SlideshowTwoTone />}
          label='Resume'
          {...props}
        />
      }
    />
  )
}

export const RemoveFromLibraryButton = ({ ...props }) => {
  return <ActionButton variant='ghost' label='Remove from Library' {...props} />
}

export const LikeButton = ({ likes, hasLiked, onClick, ...props }) => {
  const { isAuthenticated } = useSession()
  const { openFreeAccountModalForLike } = useFreeAccountCta()

  const handleClick = useCallback(() => {
    if (!isAuthenticated) {
      return openFreeAccountModalForLike()
    }

    if (onClick) onClick()
  }, [onClick, openFreeAccountModalForLike, isAuthenticated])

  return (
    <ActionButton
      variant={hasLiked ? 'secondary' : 'secondary-noselect'}
      startIcon={<ThumbUpTwoTone />}
      label={likes.toString()}
      onClick={handleClick}
      {...props}
    />
  )
}
export const BookmarkButton = ({ bookmarked, onClick, ...props }) => {
  const { isInGroup } = useGroup()
  const canBookmark = useMemo(() => isInGroup(ALLOW_BOOKMARKS_GROUP), [isInGroup])
  const { isAuthenticated } = useSession()
  const { openFreeAccountModalForAddContentToBookmark } = useFreeAccountCta()

  const handleClick = useCallback(() => {
    if (!isAuthenticated) {
      return openFreeAccountModalForAddContentToBookmark()
    }

    if (onClick) onClick()
  }, [onClick, openFreeAccountModalForAddContentToBookmark, isAuthenticated])

  // Hide if user isn't on bookmark group and already have an account
  if (!canBookmark && isAuthenticated) return null

  return (
    <ActionButton
      variant='none'
      label={bookmarked ? 'Bookmarked' : 'Bookmark'}
      {...props}
      onClick={handleClick}
    />
  )
}

export const AddToPlaylistButton = ({ ...props }) => {
  return (
    <UserGroupPolicy
      accessGroup={ALLOW_PLAYLISTS_GROUP}
      componentAllow={
        <ActionButton variant='none' label='Add to Playlist' {...props} />
      }
    />
  )
}

export const ToggleDescriptionButton = ({ toggled, ...props }) => {
  return (
    <ActionButton
      variant='secondary'
      label={!toggled ? 'See Description' : 'Close Description'}
      startIcon={!toggled ? <VisibilityTwoTone /> : <VisibilityOffTwoTone />}
      {...props}
    />
  )
}

export const ToggleViewedButton = ({ viewed, onClick, loadingIndicator, ...props }) => {
  const { isInGroup } = useGroup()
  const canAccessPlaylist = useMemo(() => isInGroup(ALLOW_PLAYLISTS_GROUP), [isInGroup])
  const { isAuthenticated } = useSession()

  if (!canAccessPlaylist && isAuthenticated) return null

  return (
    <ActionButton
      variant={viewed ? 'contained' : 'outlined'}
      color={viewed ? 'success' : 'secondaryGrey'}
      label={viewed ? 'Viewed' : 'Mark as viewed'}
      {...props}
      endIcon={loadingIndicator && <CircularProgress size={16} />}
      onClick={onClick}
      disabled={viewed}
      startIcon={viewed ? <FactCheck /> : null}
    />
  )
}

export { ActionButton, MoreActions }
