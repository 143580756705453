import React, { useRef, useState, useEffect } from 'react'
import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField
} from '@mui/material'
import { Search, Clear } from '@mui/icons-material'
import { useStyles } from './style'

const SimpleSearch = props => {
  const classes = useStyles(props)

  const {
    onSearch,
    disabled,
    initialValue = '',
    className = '',
    ...textFieldProps
  } = props

  const inputRef = useRef()

  const [value, setValue] = useState(initialValue)
  const [invokedSearch, setInvokedSearch] = useState()
  const invokedSearchRef = useRef(invokedSearch)

  const execSearch = value => onSearch(value.trim())

  useEffect(() => {
    invokedSearchRef.current = invokedSearch
  })

  useEffect(() => {
    if (!disabled && invokedSearchRef.current) {
      inputRef.current.focus()
      setInvokedSearch(false)
    }
  }, [disabled])

  const handleSearch = () => {
    setInvokedSearch(true)
    execSearch(value)
  }

  const handleChange = ({ target: { value } }) => setValue(value)

  const handleClear = () => {
    inputRef.current.focus()
    setValue('')
  }

  const handleKeyDown = ({ key }) => {
    if (document.activeElement === inputRef.current && key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <>
      <InputLabel id='search-term-label' className={classes.label}>
        Search term
      </InputLabel>
      <TextField
        title='search text'
        variant='outlined'
        id='playlist-search-text'
        placeholder='Search'
        disabled={disabled}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' className={classes.adornment}>
              <Search />
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position='end'>
              <IconButton
                onClick={handleClear}
                disabled={disabled}
                aria-label='Clear search text'
                data-test='playlist-search-term-clear'
                size='large'
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }}
        inputProps={{
          'aria-labelledby': 'search-term-label'
        }}
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        className={className}
        data-test='playlist-search-input'
        {...textFieldProps}
      />
    </>
  )
}

export default SimpleSearch
