import React from 'react'
import { Skeleton } from '@mui/material'

const WithLoading = ({
  loading,
  wrapper: Wrapper = Skeleton,
  children,
  ...props
}) => (loading ? <Wrapper {...props}>{children}</Wrapper> : children)

export default WithLoading
