import React, { cloneElement } from 'react'
import { Grid } from '@mui/material'
import { defaultColumns } from './params'
import { useStyles } from './style'

const List = ({ results, renderItem, columns = defaultColumns }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} data-test='list-container'>
      {results.map((item, index) => (
        <Grid
          item
          key={item?.friendlyUrl ?? index}
          xs={columns.xs}
          sm={columns.sm}
          md={columns.md}
          lg={columns.lg}
          xl={columns.xl}
          data-test={`list-item-${index}`}
        >
          {cloneElement(renderItem(item), { className: classes.card })}
        </Grid>
      ))}
    </Grid>
  )
}

export default List
