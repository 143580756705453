import React from 'react'
import { Box, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PromotedCardContainer from './container'
import PromotedContentCardContent from './card-content'
import usePromotedCardMethods, { PROMOTED_CARD_TYPES } from './use-promoted-card-methods'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%'
  },
  container: {
    width: '100%',
    alignItems: 'flex-start',
    paddingTop: '8px'
  },
  imageContanier: {
    height: '80px',
    width: '107px',
    marginLeft: spacing(2)
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  contentContainer: {
    height: '100%',
    justifyContent: 'flex-start',
    paddingBottom: 0,
    paddingTop: 0
  },
  cardTitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '24px'
  },
  cardSummary: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  cardContentType: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  actions: {
    marginTop: 'auto',
    marginBottom: '16px'
  },
  time: {
    fontSize: '14px',
    lineHeight: '20px'
  }
}))

const PromotedContentGenericCard = ({ content, position, testId, size, onAddToPlaylist, onLike, onBookmark, bookmarked, actionLoading, ...props }) => {
  const classes = useStyles()
  const {
    contentImage,
    handleMouseEnter,
    handleMouseLeave,
    handleCardSelect,
    expanded,
    cardSize,
    hoverShadow,
    actions
  } = usePromotedCardMethods({ content, position, size, cardType: PROMOTED_CARD_TYPES.GENERIC, onLike, onAddToPlaylist, onBookmark, bookmarked, actionLoading })

  return (
    <PromotedCardContainer
      expand={expanded}
      size={cardSize}
      hoverShadow={hoverShadow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      actions={actions}
      content={content}
      testId={testId}
    >
      <Box display='flex' flexDirection='row' className={classes.container}>
        <Box className={classes.imageContanier} onClick={handleCardSelect}>
          {
              content
                ? (
                  <img
                    src={contentImage}
                    title={content?.image?.altText}
                    className={classes.image}
                    data-test={`card-image-${content?.friendlyUrl}`}
                  />
                  )
                : (
                  <Skeleton variant='rectangular' className={classes.image} />
                  )
            }
        </Box>
        <PromotedContentCardContent
          content={content}
          actions={actions}
          defaultSectionMargin={1}
          onSelect={handleCardSelect}
          expand={expanded}
          overrides={{
            contentContainer: classes.contentContainer,
            cardTitle: classes.cardTitle,
            cardSummary: classes.cardSummary,
            cardContentTypeText: classes.cardContentType,
            actions: classes.actions,
            time: classes.time
          }}
        />
      </Box>
    </PromotedCardContainer>
  )
}

export default PromotedContentGenericCard
