import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  popper: {
    background: '#0070BD',
    zIndex: 1201,
    color: palette.common.white,
    width: 375,
    maxWidth: 375,
    borderRadius: 5,
    padding: spacing(2),
    position: 'absolute!important', // override inline popper position
    inset: '0px auto auto 0px',
    margin: 0,
    transform: 'translate(-137px, 44px)',

    [breakpoints.down('md')]: {
      display: 'none'
    }
  },
  arrow: {
    position: 'absolute',
    top: -10,
    left: 'calc(50% - 10px)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #0070BD'
  },
  closeButton: {
    marginTop: 0,
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    color: palette.common.white
  },
  closeIcon: {
    fontSize: '1rem'
  },
  title: {
    fontWeight: 700,
    marginBottom: spacing(1)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  dismiss: {
    padding: 0,
    paddingTop: spacing(1),
    minWidth: 0,
    width: '80px',
    color: palette.common.white,
    textDecoration: 'underline'
  }
}))
