import React from 'react'
import { Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.grey.shade[30],
    height: '4ex',
    marginLeft: 5,
    marginRight: spacing(2)
  }
}))

const VerticalDivider = props => {
  const classes = useStyles()

  return <Divider orientation='vertical' className={classes.root} />
}

export default VerticalDivider
