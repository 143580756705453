import name from './name'
import { createSlice } from '@reduxjs/toolkit'
import { genSetter } from '../util'

const initialState = {
  emailSent: false
}

const entry = createSlice({
  name,
  initialState,
  reducers: {
    emailSent: genSetter('emailSent')
  }
})

export const { emailSent } = entry.actions

export default entry.reducer
