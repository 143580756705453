import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing }) => ({
  title: {
    paddingTop: spacing(5)
  },
  close: {
    textTransform: 'capitalize'
  }
}))
