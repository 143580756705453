import { useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
/**
 * Allow a function to be run before a view is unmounted or unloaded
 * Accounts for views exited by closing a tab or window by adding a listener to the window
 * Computes the duration the page has been loaded and passes it to the unload function
 * @param {Function} onBeforeUnload  Runs as as an effect cleanup and before the page is unloaded
 * @param {Array} depedencies When these change the cleanup function will run
 */
const useEventsOnViewUnload = (onBeforeUnload, depedencies = []) => {
  useEventsOnViewLoad(() => {
    const startDate = new Date()

    const handleBeforeUnload = () => {
      const endDate = new Date()
      const duration = endDate.getTime() - startDate.getTime()
      onBeforeUnload(duration)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      handleBeforeUnload()
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, depedencies)
}

export default useEventsOnViewUnload
