import { darken } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ palette, spacing, breakpoints, typography }) => {
    const listItem = {
      cursor: 'pointer',
      '& .MuiListItemIcon-root': {
        minWidth: 30
      },
      '&:hover': {
        background: '#F2F2F2'
      }
    }
    const textColor = '#212121'

    return {
      searchSuggestions: ({ bps }) => ({
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        background: palette.common.white,
        color: '#4F4F4F',
        borderTop: '1px solid #BDBDBD',
        zIndex: 11,
        [breakpoints.up('xs')]: {
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.18)',
          width: 'calc(100% - 10px)'
        },
        [breakpoints.up('md')]: {
          width: '100%'
        }
      }),
      searchSuggestionsList: {
        paddingBottom: 0,
        '& .MuiListItem-divider': {
          borderBottom: `1px ${palette.grey.shade[5]} solid`
        }
      },
      searchSuggestionsTitle: {
        padding: spacing(0.945, 2),
        '& span': {
          ...typography.body.s,
          fontWeight: typography.fontWeightBold,
          color: textColor
        }
      },
      suggestedContentItem: {
        padding: spacing(0.825, 2),
        ...listItem
      },
      historyItem: {
        ...listItem
      },
      historyAction: {
        '&.MuiIconButton-edgeEnd': {
          marginRight: -18
        }
      },
      seeAllResultsButton: {
        background: '#ecf1f9',
        padding: spacing(1.32, 2),
        border: '1px #cccccc solid',
        '& span': {
          fontWeight: typography.fontWeightBold,
          ...typography.body.s,
          color: textColor
        },
        '&:hover, &:focus': {
          background: darken('#ecf1f9', 0.05)
        }
      },
      divider: {
        margin: spacing(1, 0),
        backgroundColor: palette.grey.shade[20]
      },
      itemText: {
        color: textColor
      },
      historyItemText: {
        color: textColor,
        maxWidth: '90%'
      },
      secondaryItemText: {
        color: '#666666'
      },
      lastSuggestedItem: {
        paddingBottom: spacing(0.95)
      },
      historyIcon: {
        color: '#323232'
      },
      historyActionIcon: {
        color: '#666666'
      }
    }
  }
)
