import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  title: {
    [breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  subtitle: {
    marginBottom: spacing(3)
  },
  menu: {
    [breakpoints.down('sm')]: {
      maxHeight: '40vh'
    },
    [breakpoints.up('sm')]: {
      marginBottom: spacing(10)
    }
  }
}))
