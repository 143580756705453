import React, { useEffect, useState } from 'react'
import { useFormFactor, useGroup } from '../../../hooks'
import { UserGroupPolicy } from '../../user-group-policy'
import {
  Bookmark,
  BookmarkBorder,
  CollectionsBookmarkTwoTone,
  ThumbUpTwoTone,
  ThumbUp
} from '@mui/icons-material'
import {
  AddToPlaylistButton,
  BookmarkButton,
  MoreActions,
  ActionButton,
  ToggleDescriptionButton,
  LikeButton
} from '../../action-buttons'
import GeneralCard from '../general-card'
import { ALLOW_PLAYLISTS_GROUP } from '../../user-group-policy/permissions'
import { useStyles } from './style'
import { ALL_CONSUMER } from '../../../services/cognito/userGroups'
import { useDisplayAddToPlaylist } from '../../../hooks/useDisplayAddToPlaylist'

const HomeCard = ({
  content,
  testId,
  onAddToPlaylist,
  onBookmark,
  size,
  bookmarked,
  onLike,
  actionLoading,
  ...props
}) => {
  const { isDesktop, isLargeTablet } = useFormFactor()
  const cardSize = size || (isDesktop ? 'large' : 'mobile')
  const isFullWidth = isDesktop || isLargeTablet
  const [expanded, setExpanded] = useState(isFullWidth)
  const [hoverShadow, setHoverShadow] = useState(false)
  const { isInGroup } = useGroup()
  const isConsumer = isInGroup(ALL_CONSUMER)
  const classes = useStyles({ isConsumer })
  const displayAddToPlaylist = useDisplayAddToPlaylist()

  const handleToggleDescription = () => setExpanded(!expanded)
  const handleMouseEnter = () => {
    setHoverShadow(true)
    !isFullWidth && setExpanded(true)
  }
  const handleMouseLeave = () => {
    setHoverShadow(false)
    !isFullWidth && setExpanded(false)
  }

  const handleAddToPlaylist = () => {
    onAddToPlaylist(content)
  }

  const handleLike = () => onLike(content)

  useEffect(() => {
    setExpanded(isFullWidth)
  }, [isFullWidth])

  let actions = {
    leftActions: (
      <>
        <LikeButton
          variant={isConsumer ? 'none' : 'secondary'}
          size='xs'
          label={content?.hasRecommend ? 'Liked' : 'Like'}
          startIcon={content?.hasRecommend ? <ThumbUp /> : <ThumbUpTwoTone />}
          onClick={handleLike}
          likes={content?.likeCount ?? 0}
          loading={actionLoading?.like}
          className={classes.action}
        />
        <BookmarkButton
          variant={isConsumer ? 'none' : 'secondary'}
          size='xs'
          bookmarked={bookmarked}
          onClick={onBookmark}
          startIcon={bookmarked ? <Bookmark /> : <BookmarkBorder />}
          loading={actionLoading?.bookmark}
          className={classes.action}
        />
        {displayAddToPlaylist && (
          <UserGroupPolicy
            accessGroup={ALLOW_PLAYLISTS_GROUP}
            componentAllow={
              <ActionButton
                variant={isConsumer ? 'none' : 'secondary'}
                size='xs'
                label='Add to Playlist'
                startIcon={<CollectionsBookmarkTwoTone />}
                onClick={handleAddToPlaylist}
                className={classes.action}
              />
            }
          />
        )}
      </>
    )
  }

  if (!isFullWidth) {
    actions = {
      leftActions: (
        <ToggleDescriptionButton
          size='xs'
          variant={isConsumer ? 'none' : 'secondary'}
          toggled={expanded}
          onClick={handleToggleDescription}
          className={classes.action}
        />
      ),
      rightActions: (
        <>
          <MoreActions
            variant={isConsumer ? 'none' : 'secondary'}
            size='xs'
            className={classes.action}
            loading={
              actionLoading && Object.values(actionLoading).some(val => !!val)
            }
            actions={actions => [
              [
                <LikeButton
                  key='Like'
                  variant='none'
                  label={content?.hasRecommend ? 'Liked' : 'Like'}
                  loading={actionLoading?.like}
                  likes={content?.likeCount ?? 0}
                  onClick={() => {
                    handleLike()
                    actions.onClose()
                  }}
                  className={actions.className}
                />
              ],
              [
                <BookmarkButton
                  key='Bookmark'
                  className={actions.className}
                  loading={actionLoading?.bookmark}
                  onClick={() => {
                    onBookmark()
                    actions.onClose()
                  }}
                  bookmarked={bookmarked}
                />
              ],
              ...(isInGroup(ALLOW_PLAYLISTS_GROUP) && displayAddToPlaylist
                ? [
                  <AddToPlaylistButton
                    key='AddToPlaylist'
                    className={actions.className}
                    onClick={() => {
                      handleAddToPlaylist()
                      actions.onClose()
                    }}
                  />
                  ]
                : [])
            ]}
          />
        </>
      )
    }
  }

  return (
    <GeneralCard
      fullWidth={isFullWidth}
      expand={expanded}
      size={cardSize}
      hoverShadow={hoverShadow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      actions={actions}
      content={content}
      testId={testId}
      {...props}
    />
  )
}

export default HomeCard
