import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { bookmarksSlice } from '../../reducers'
import { isEqual } from 'lodash'
import { useAlert } from '../../hooks'
import { RemovedAlert, AddedAlert } from './alerts'
import { useSession } from '@emerald-works/react-auth'

const useAddToBookmarks = (articles = []) => {
  const [activeArticle, setActiveArticle] = useState([])
  const [articleIds, setArticleIds] = useState([])

  const dispatch = useDispatch()
  const { isAuthenticated } = useSession()

  const { enqueueMsg, enqueueAlert } = useAlert()

  const bookmarkedContent = useSelector(
    bookmarksSlice.selectors.selectBookmarked
  )
  const loading = useSelector(bookmarksSlice.selectors.selectLoading)

  const [getBookmarked] = useEvent([bookmarksSlice.eventBus.getBookmarked])

  const [insertBookmark] = useEvent([
    {
      eventName: 'bookmarksInsert',
      onSuccess: payload => {
        setActiveArticle(items =>
          items.filter(({ locationId }) => locationId !== payload.content.sk)
        )
        dispatch(bookmarksSlice.actions.addBookmarked(payload.content.sk))
        enqueueAlert(<AddedAlert />)
      },
      onError: payload => {
        const sent = payload.error?.sent || payload?.sent
        if (sent.item) {
          setActiveArticle(items =>
            items.filter(
              ({ locationId }) => locationId !== sent.item
            )
          )
        } else setActiveArticle([])
        enqueueMsg('Unable to add to bookmarks', 'error')
      }
    }
  ])

  const [removeBookmark] = useEvent([
    {
      eventName: 'bookmarksRemove',
      onSuccess: payload => {
        setActiveArticle(items =>
          items.filter(({ locationId }) => locationId !== payload.item)
        )
        dispatch(bookmarksSlice.actions.removeBookmarked(payload.item))
        enqueueAlert(<RemovedAlert />)
      },
      onError: payload => {
        const sent = payload.error?.sent || payload?.sent
        if (sent?.item) {
          setActiveArticle(items =>
            items.filter(
              ({ locationId }) => locationId !== sent.item
            )
          )
        } else setActiveArticle([])
        enqueueMsg('Unable to remove from bookmarks', 'error')
      }
    }
  ])

  useEffect(() => {
    if (articles.length) {
      const newIds = articles.map(({ locationId }) => locationId)
      setArticleIds(current => (isEqual(current, newIds) ? current : newIds))
    }
  }, [articles])

  useEffect(() => {
    if (articleIds.length && isAuthenticated) {
      getBookmarked.trigger({ locationIds: articleIds })
    }
  }, [articleIds, getBookmarked, isAuthenticated])

  const isBookmarked = content =>
    content ? bookmarkedContent.includes(content.locationId) : false

  const handleAddBookmark = ({ locationId: contentId }) => {
    insertBookmark.trigger({ contentId })
  }

  const handleRemoveBookmark = ({ locationId: contentId }) => {
    removeBookmark.trigger({ contentId })
  }

  const handleBookmark = article => {
    setActiveArticle([...activeArticle, article])
    isBookmarked(article)
      ? handleRemoveBookmark(article)
      : handleAddBookmark(article)
  }

  const bookmarking = ({ locationId }) =>
    (activeArticle.length > 0 &&
      activeArticle.findIndex(x => x.locationId === locationId) >= 0) ||
    loading

  return { isBookmarked, handleBookmark, bookmarking }
}

export default useAddToBookmarks
