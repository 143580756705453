import { useCallback } from 'react'

const sortFilterMap = {
  new: 'createdAt:desc'
}

const useEventFilters = () => {
  const getEventFilters = useCallback(
    ({ type, sort, contentType, start, subcat, ...rest }) => {
      const eventSort = sortFilterMap[sort?.toLowerCase()] || ''
      return { types: type, sort: eventSort, ...rest }
    },
    []
  )

  return { getEventFilters }
}

export default useEventFilters
