import React, { cloneElement } from 'react'
import { useMediaQuery, Dialog, Grid, Hidden } from '@mui/material'
import GetMindToolsPanel from './get-mind-tools-panel'

const GetMindToolsDialog = ({ open, onClose, pane }) => {
  const desktop = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={desktop ? 'md' : 'sm'}
      fullWidth
      aria-labelledby='dialog-title'
    >
      <Grid container>
        <Hidden lgDown>
          <Grid item xs={5}>
            <GetMindToolsPanel />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={7}>
          {cloneElement(pane, { onClose })}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default GetMindToolsDialog
