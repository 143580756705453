export const ColourSchemes = {
  'dark-blue': {
    value: 'dark-blue',
    bg: '#2B3B4C',
    text: '#FFF',
    btn: '#E08330',
    btnText: '#FFF'
  },
  'dark-red': {
    value: 'dark-ed',
    bg: '#A32F20',
    text: '#FFF',
    btn: '#FFF',
    btnText: '#636469'
  },
  yellow: {
    value: 'yellow',
    bg: '#D9A638',
    text: '#FFF',
    btn: '#2B3B4C',
    btnText: '#FFF'
  },
  'light-blue': {
    value: 'light-blue',
    bg: '#B2CCEB',
    text: '#18191A',
    btn: '#2B3B4C',
    btnText: '#FFF'
  },
  light: {
    value: 'light',
    bg: '#ECEAE6',
    text: '#18191A',
    btn: '#2B3B4C',
    btnText: '#FFF'
  },
  black: {
    value: 'black',
    bg: 'black',
    text: 'white',
    btn: 'white',
    btnText: 'black'
  },
  purple: {
    value: 'purple',
    bg: '#DADAFE',
    text: 'black',
    btn: '#5D5BED',
    btnText: 'white'
  },
  green: {
    value: 'green',
    bg: '#F2FCC0',
    text: 'black',
    btn: '#D5E82C',
    btnText: 'black'
  },
  orange: {
    value: 'orange',
    bg: '#FFF2CF',
    text: 'black',
    btn: '#FDBC00',
    btnText: 'black'
  }
}
