import { Box, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import { useFormFactor } from '../../hooks'
import { CampaignActionBtn } from './campaign-btn'
import { ColourSchemes } from './campaign-util'
import { MobileVerticalCampaignBanner } from './mobile-vertical-banner'

const cardShape = 'polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0% 100%)'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  exploreMenuBannerCtn: {
    backgroundColor: ({ bg }) => bg
  },
  exploreMenuBannerCard: {
    clipPath: cardShape,
    display: 'flex',
    borderRadius: 0,
    backgroundColor: ({ bg }) => bg
  },
  exploreMenuBannerEdge: {
    paddingRight: 1,
    paddingBottom: 1,
    clipPath: cardShape,
    backgroundColor: ({ bg }) => bg
  },
  exploreMenuImgCtn: {
    width: ({ vertical }) => vertical ? '100%' : 250
  },
  exploreMenuBannerTxtCtn: {
    padding: spacing(3.5, 2.75)
  },
  exploreMenuBannerTitle: {
    color: ({ text }) => text,
    marginBottom: spacing(2)
  },
  exploreMenuBannerDescription: {
    color: ({ text }) => text,
    marginBottom: spacing(3)
  },
  exploreMenuBannerImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}))

const DesktopExploreMenuCampaignBanner = ({ campaign, vertical }) => {
  const { colourScheme, squareImg, title, body } = campaign || {}
  const { bg, text } = ColourSchemes[colourScheme] || {}
  const classes = useStyles({ bg, text, vertical })

  if (!campaign) return null
  return (
    <Box className={classes.exploreMenuBannerEdge}>
      <Grid className={classNames(classes.exploreMenuBannerCtn, classes.exploreMenuBannerCard)} container>
        <Grid item className={classes.exploreMenuImgCtn}>
          <img src={squareImg?.imgUrl} className={classes.exploreMenuBannerImg} />
        </Grid>
        <Grid item xs className={classes.exploreMenuBannerTxtCtn}>
          <Typography className={classes.exploreMenuBannerTitle} variant='h5'>{title}</Typography>
          <Typography
            className={classes.exploreMenuBannerDescription}
            variant='body2'
            dangerouslySetInnerHTML={{ __html: body }}
          />
          <CampaignActionBtn campaign={campaign} />
        </Grid>
      </Grid>
    </Box>
  )
}

export const ExploreMenuCampaignBanner = props => {
  const { isMobile } = useFormFactor()
  return isMobile ? <MobileVerticalCampaignBanner {...props} imageAtTop rounded /> : <DesktopExploreMenuCampaignBanner {...props} />
}
