import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  content: {
    border: '1px solid black',
    borderRadius: 4,
    padding: spacing(1)
  },
  citation: {
    overflow: 'hidden',
    overflowWrap: 'break-word'
  },
  copy: {
    textTransform: 'none'
  },
  icon: {
    color: palette.text.secondary
  },
  actions: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3)
  }
}))
