import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  Typography
} from '@mui/material'
import LanguageMenu from './menu'
import { useStyles } from './style'

const TranslateDialog = ({
  open,
  heading,
  subheading,
  formTip,
  onClose,
  language,
  allLanguages,
  onTranslate,
  defaultLanguage,
  label = 'Language',
  applyBtnText = 'Apply',
  cancelBtnText = 'Cancel',
  handleSetDefaultLanguage
}) => {
  const classes = useStyles()

  const findMatchingLanguage = useCallback((value) => {
    if (!value) return null
    return allLanguages.find(language => language.value === value) || null
  }, [allLanguages])

  const [lang, setLang] = useState({})
  const [isDefault, setIsDefault] = useState(false)

  useEffect(() => {
    setIsDefault(defaultLanguage && (lang.value === defaultLanguage))
  }, [lang, defaultLanguage])

  useEffect(() => {
    const selected = findMatchingLanguage(defaultLanguage) ?? language
    if (selected) {
      setLang(selected)
    }
  }, [findMatchingLanguage, defaultLanguage, language])

  const handleChange = value => {
    if (value) {
      setLang(value)
    }
  }

  const handleApply = () => {
    if (!lang?.value) {
      console.warn('Language is missing')
      return
    }
    onTranslate(lang.value)
    if (isDefault && handleSetDefaultLanguage) {
      handleSetDefaultLanguage(lang.value)
    }
    onClose()
  }

  const handleDefaultChange = event => {
    setIsDefault(event.target.checked)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      aria-labelledby='dialog-title'
    >
      <DialogTitle id='dialog-title' className={classes.title}>
        {heading}
      </DialogTitle>
      <DialogContent>
        <Hidden smDown>
          <Typography
            variant='subtitle2'
            color='textSecondary'
            className={classes.subtitle}
          >
            {subheading}
          </Typography>
        </Hidden>
        <LanguageMenu
          label={label}
          value={lang}
          onChange={handleChange}
          languages={allLanguages}
        />
        {formTip && (<Typography sx={{ marginY: 3 }}>{formTip}</Typography>)}
        {handleSetDefaultLanguage && (
          <Box display='flex' alignItems='center' ml={0} mt={0}>
            <Checkbox
              style={{ opacity: 0.7 }}
              checked={!!isDefault}
              onChange={handleDefaultChange}
            />
            <Typography
              variant='subtitle2'
              style={{ opacity: 0.7 }}
            >
              Set as the default content language
            </Typography>
          </Box>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test='translate-dialog-cancel'>
          {cancelBtnText}
        </Button>
        <Button
          onClick={handleApply}
          color='primary'
          data-test='translate-dialog-apply'
        >
          {applyBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TranslateDialog
