import React from 'react'
import { Typography, Card, CardMedia, CardContent, Box } from '@mui/material'
import { useStyles } from './style'
import MtLogo from '../logos/mindtools_logo_primary_rgb.svg'
import { useFormFactor } from '../../hooks'

const UnfurlPreview = ({ className, title, subtitle, url, image }) => {
  const classes = useStyles()
  const { host } = new URL(url)
  const { isMobile } = useFormFactor()

  let contentImage = MtLogo

  if (image) {
    contentImage = image + (isMobile ? 'h=400&w=400' : 'h=150&w=150')
  }

  return (
    <Card className={classes.card}>
      <CardMedia
        data-test='preview-image'
        component='img'
        className={classes.cardMedia}
        image={contentImage}
        alt={title}
      />
      <Box className={classes.box}>
        <CardContent>
          <Typography className={classes.title} data-test='preview-title'>
            {title}
          </Typography>
          <Typography className={classes.subtitle} data-test='preview-subtitle'>
            {subtitle}
          </Typography>
          <Typography className={classes.url} data-test='preview-url'>
            {host}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}

export default UnfurlPreview
