import React from 'react'
import { Grid, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { ActionButton } from '../../components'
import { routes } from '../../routes'
import { useFormFactor, useContactDetails } from '../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import { useSession } from '@emerald-works/react-auth'
import { useTenant } from '@emerald-works-nova/auth'
import consts from '../../consts'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    width: 'auto',
    '& > *': {
      width: 200,
      [breakpoints.down('sm')]: {
        width: 264,
        maxWidth: '100%'
      },
      [breakpoints.down(300)]: {
        width: 'auto'
      }
    }
  },
  contact: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
    width: '100%'
  },
  home: {
    width: '100%'
  }
}))

const Actions = () => {
  const classes = useStyles()
  const { isMobile } = useFormFactor()
  const { email: contactEmail } = useContactDetails()
  const tenant = useTenant()
  const { isAuthenticated } = useSession()
  const isClubBrowserUser = tenant.pk === consts.DEFAULT_CLUB_PK && !isAuthenticated

  const backToHomeProps = isClubBrowserUser
    ? { href: window.location.origin + '/', component: Link }
    : { to: routes.home.path, component: RouterLink }

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      alignItems='center'
      wrap='nowrap'
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <ActionButton
          variant='primary'
          label='Back to Homepage'
          size='l'
          className={classes.home}
          {...backToHomeProps}
        />
      </Grid>
      <Grid item>
        <ActionButton
          variant='ghost'
          size='l'
          component={Link}
          href={`mailto:${contactEmail}`}
          target='_blank'
          rel='noreferrer'
          label='Contact Us'
          className={classes.contact}
        />
      </Grid>
    </Grid>
  )
}

export default Actions
