import name from './name'
import { createSlice } from '@reduxjs/toolkit'
import { genSetter } from '../util'

const initialState = {
  isBusy: false
}

const busy = createSlice({
  name,
  initialState,
  reducers: {
    setBusy: genSetter('isBusy')
  }
})

export const { setBusy } = busy.actions

export default busy.reducer
