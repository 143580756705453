import React from 'react'
import { Grid, Button, Box, Skeleton } from '@mui/material'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'
import { categoryUrl } from '../../../helpers/url'
import SectionHeader from './section-header'
import WithLoading from '../../with-loading'
import { isEmpty } from 'lodash'
import HeaderUnderline from '../../header-underline'

const TopicsHeader = props => {
  const { isConsumer } = props
  const classes = useStyles(props)
  return (
    <SectionHeader classes={{ root: classes.topicsSectionHeader }} underline={isConsumer && <HeaderUnderline color='primary' width={48} />}>
      <Box component='span' fontWeight='fontWeightBold'>Trending Categories</Box>
    </SectionHeader>
  )
}

const TopicItem = props => {
  const navigate = useNavigate()
  const classes = useStyles(props)
  const {
    topic: { category, pathToRoot }
  } = props

  const handleNavigate = () => {
    navigate(categoryUrl(category, pathToRoot))
  }
  return (
    <Button
      onClick={handleNavigate}
      className={classes.topic}
      data-test={`topic-${category.sk}`}
    >
      {category.name}
    </Button>
  )
}
/* eslint-disable indent */
const TopicsContent = props => {
  const classes = useStyles(props)
  const {
    content,
    isConsumer
  } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WithLoading loading={isEmpty(content)}>
          <TopicsHeader isConsumer={isConsumer} />
        </WithLoading>
      </Grid>
      <Grid item xs={12}>
        {content?.length > 0
          ? content.map((x) => {
              return x.category
                ? <TopicItem key={x.category.sk} topic={x} isConsumer={isConsumer} />
                : null
            })
          : [...Array(4)].map((x, i) => (
            <Skeleton key={i} className={classes.ghost}>
              <Button />
            </Skeleton>
            ))}
      </Grid>
    </Grid>
  )
}

export default TopicsContent
