import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import playlistLanding from '../playlist-landing'

const initialState = {
  detail: undefined,
  content: [],
  loading: false,
  back: undefined,
  readTimes: [],
  playlistIds: []
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setDetail: (state, { payload }) => {
      state.detail = payload.playlist
      state.playlistIds = [payload.playlist.pk]
    },
    setContent: (state, { payload }) => {
      state.content = payload.content
      state.loading = false
    },
    setReadTimes: (state, { payload }) => {
      state.readTimes = payload
    },
    addContent: (state, { payload }) => {
      state.content.push(payload)
    },
    setLoading: (state, { payload }) => {
      state.loading = true
    },
    toggleLike: (state, { payload }) => {
      state.detail.likeCount += payload.status === 'liked' ? 1 : -1
      state.detail.hasLiked = payload.status === 'liked'
    },
    setBack: (state, { payload }) => {
      state.back = payload
    },
    reset: state => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(playlistLanding.actions.setResults, (state, { payload }) => {
        if (!payload.items) return
        const playlistIds = payload.items.map(playlist => {
          return playlist.pk
        })
        if (playlistIds.length) {
          state.playlistIds = playlistIds
        }
      })
  }
})

export const {
  setBack,
  setDetail,
  setContent,
  addContent,
  setLoading,
  toggleLike,
  setReadTimes,
  reset
} = content.actions

export default content.reducer
