import React from 'react'
import { Box, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PromotedCardContainer from './container'
import PromotedContentCardContent from './card-content'
import usePromotedCardMethods, { PROMOTED_CARD_TYPES } from './use-promoted-card-methods'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    width: '100%',
    paddingBottom: 0,
    paddingTop: 0
  },
  cardTitle: {
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px'
  },
  cardSummary: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  imageContainer: {
    width: '100%',
    height: '138px',
    marginTop: spacing(2),
    marginBottom: spacing(1)
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: '8px',
    objectFit: 'cover'
  }
}))

const PromotedContentSecondaryCard = ({ content, position, testId, size, onAddToPlaylist, onBookmark, onLike, bookmarked, actionLoading, ...props }) => {
  const classes = useStyles()

  const {
    contentImage,
    handleMouseEnter,
    handleMouseLeave,
    handleCardSelect,
    expanded,
    cardSize,
    hoverShadow,
    actions
  } = usePromotedCardMethods({ content, position, size, cardType: PROMOTED_CARD_TYPES.SECONDARY, onAddToPlaylist, onLike, onBookmark, bookmarked, actionLoading })

  return (
    <PromotedCardContainer
      expand={expanded}
      size={cardSize}
      hoverShadow={hoverShadow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      actions={actions}
      content={content}
      testId={testId}
    >
      <PromotedContentCardContent
        content={content}
        actions={actions}
        expand={expanded}
        onSelect={handleCardSelect}
        overrides={{
          contentContainer: classes.contentContainer,
          cardTitle: classes.cardTitle,
          cardSummary: classes.cardSummary
        }}
      >
        <Box className={classes.imageContainer}>

          {
            content
              ? (
                <img
                  src={contentImage}
                  title={content?.image?.altText}
                  className={classes.image}
                  data-test={`card-image-${content?.friendlyUrl}`}
                />
                )
              : (
                <Skeleton variant='rectangular' className={classes.image} />
                )
          }
        </Box>
      </PromotedContentCardContent>
    </PromotedCardContainer>
  )
}

export default PromotedContentSecondaryCard
