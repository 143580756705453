import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { generateCSSString, setMetaTag } from './util'
import { usePrerender } from '../../hooks'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

/* Context to handle control of prerender.io caching
 * On pages that use the useSEO() hook conditions can be passed
 * to tell prerender.io whether it should cache the page yet.
 * This ensures that all conditions on a page are respected even if they are
 * in seperate components.
 */

const SEOContext = createContext()

const SEOProvider = ({ children }) => {
  const [conditions, setConditions] = useState({})
  const [redirectLocation, setRedirectLocation] = useState(null)
  const hasConditions = Object.keys(conditions).length > 0
  const { isPrerender } = usePrerender()
  const { pathname } = useLocation()

  const addCondition = useCallback((hookRef, condition) => {
    setConditions(prev => ({ ...prev, [hookRef]: condition }))
  }, [])

  const removeCondition = useCallback(hookRef => {
    setConditions(prev => {
      const state = prev
      delete state[hookRef]
      return state
    })
  }, [])

  const redirect = useCallback(location => setRedirectLocation(location), [])

  const prerenderReady = hasConditions
    ? Object.values(conditions).every(c => c)
    : undefined

  useEffect(() => {
    if (isPrerender || process.env.REACT_APP_DEBUG_PRERENDER) {
      if (redirectLocation && prerenderReady) {
        setMetaTag('prerender-status-code', 301)
        setMetaTag('prerender-header', redirectLocation)
        console.debug(window.performance.now(), 'Set page to redirect to', redirectLocation)
        window.prerenderReady = true
        console.debug(window.performance.now(), 'PrerenderReady is true.')
      } else if (prerenderReady) {
        generateCSSString()
        window.prerenderReady = true
        setMetaTag('prerender-status-code', 200)
        console.debug(window.performance.now(), 'PrerenderReady is true.')
      } else if (prerenderReady === false) {
        window.prerenderReady = false
        setMetaTag('prerender-status-code', 504)
        console.debug(window.performance.now(), 'PrerenderReady is false.')
      } else {
        window.prerenderReady = undefined
        console.debug(window.performance.now(), 'PrerenderReady is undefined.')
      }
    }
  }, [prerenderReady, isPrerender, redirectLocation])

  return (
    <SEOContext.Provider value={{ addCondition, removeCondition, redirect }}>
      <Helmet>
        <link rel='canonical' href={window.location.origin + pathname} />
      </Helmet>
      {children}
    </SEOContext.Provider>
  )
}

let uniqueId = 0
const getUniqueId = () => uniqueId++

const useSEO = (condition) => {
  const hookRef = useRef(getUniqueId())
  const id = hookRef.current
  const { addCondition, removeCondition, redirect } = useContext(SEOContext)

  useEffect(() => {
    addCondition(id, condition)
  }, [addCondition, condition, id])

  useEffect(() => {
    return () => removeCondition(id)
  }, [removeCondition, id])

  return { redirect }
}

export { SEOProvider, useSEO }
