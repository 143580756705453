import React from 'react'
import { useSelector } from 'react-redux'
import { categoryUrl } from '../../helpers/url'
import { exploreSlice } from '../../reducers'
import Link from '../link'

const CategoryLink = ({ category }) => {
  const { path, parent, name } = category
  const groups = useSelector(exploreSlice.selectors.selectGroups)

  if (path) return <Link to={path} underline='none'>{name}</Link>

  if (parent) {
    const group = groups.find(({ sk }) => sk === parent.sk)
    const path = [category, group]

    return <Link to={categoryUrl(category, path)} underline='none'>{name}</Link>
  }

  return name
}

export default CategoryLink
