import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useSession } from '@emerald-works/react-auth'
import { routes } from '../routes'
import useExternalApp from './useExternalApp'

const useHome = () => {
  const { isAuthenticated } = useSession()
  const { getMtUrl } = useExternalApp()
  const home = isAuthenticated ? routes.home.path : getMtUrl()
  const linkProps = isAuthenticated
    ? { to: home, component: RouterLink }
    : { href: home, component: Link }

  return { linkProps }
}

export default useHome
