import React from 'react'
import { Grid, Typography } from '@mui/material'
import Logos from './logos'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  ({ palette, breakpoints, spacing, typography }) => {
    const narrow = 375
    return {
      root: {
        border: `1px solid ${palette.grey.shade[10]}`,
        background: 'linear-gradient(180deg, #FAFAFA 0%, #EEEFF1 100%)',
        minHeight: 200,
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        [breakpoints.down(narrow)]: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1)
        }
      },
      tagline: {
        marginTop: spacing(4),
        [breakpoints.down(narrow)]: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1)
        },
        color: palette.grey.shade[80],
        ...typography.body.s
      },
      logos: {
        maxWidth: 365,
        marginTop: spacing(2)
      },
      legal: {
        marginTop: 'auto',
        marginBottom: spacing(2.5),
        [breakpoints.down(narrow)]: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1)
        },
        color: palette.grey.shade[100],
        ...typography.micro
      }
    }
  }
)

const Footer = props => {
  const classes = useStyles(props)
  const year = new Date().getFullYear()

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      wrap='nowrap'
      className={classes.root}
    >
      <Typography variant='body1' align='center' className={classes.tagline}>
        We keep leaders prepared for the now and next.
      </Typography>
      <Logos classes={{ root: classes.logos }} />
      <Typography variant='body2' align='center' className={classes.legal}>
        © Mind Tools Ltd {year}. All rights reserved.
      </Typography>
    </Grid>
  )
}

export default Footer
