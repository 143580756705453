import name from './name'

const selectSliceData = state => state[name]

const selectSkillBite = state => selectSliceData(state).skillBite

const selectSession = state => selectSliceData(state).session

const selectCurrentSection = state => selectSliceData(state).currentSection

const selectCurrentPage = state => selectSliceData(state).currentPage

export { selectSkillBite, selectSession, selectCurrentSection, selectCurrentPage }
