import React from 'react'
import { Helmet } from 'react-helmet'

const DEFAULT_FAVICON = 'favicon.ico'

const Favicon = () => {
  const favName = DEFAULT_FAVICON
  const favHref = `${process.env.PUBLIC_URL}/${favName}`

  return (
    <Helmet>
      <link rel='icon' href={favHref} />
    </Helmet>
  )
}

export default Favicon
