import React from 'react'
import { Button, IconButton, Breadcrumbs as MuiBreadcrumbs, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { HouseTwoTone } from '@mui/icons-material'
import { routes } from '../../routes'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    color: palette.grey[70],
    marginLeft: spacing(-1),
    '& .MuiButton-root': {
      color: palette.grey.shade[70],
      textTransform: 'capitalize',
      fontSize: typography.breadcrumb.fontSize,
      fontWeight: typography.fontWeightMedium,
      padding: spacing(0.75, 1)
    }
  },
  active: {
    color: `${palette.primary.main} !important`
  }
}))

const SimpleBreadcrumbs = ({
  isLoading,
  crumbs = []
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <MuiBreadcrumbs
      data-test='breadcrumbs'
      classes={{ root: classes.root }}
      separator='>'
    >
      <IconButton
        data-test='breadcrumbs-home'
        color='inherit'
        aria-label='Home'
        onClick={() => navigate(routes.home.path)}
        size='large'
      >
        <HouseTwoTone />
      </IconButton>
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1
        return (
          <Button
            key={index}
            data-test={`breadcrumbs-crumb-${crumb.name}`}
            aria-current={isLast && 'page'}
            onClick={crumbs.handleClick}
            className={isLast && classes.active}
          >
            {isLoading ? <Skeleton width='4.5em' /> : crumb.name}
          </Button>
        )
      })}

    </MuiBreadcrumbs>
  )
}

export default SimpleBreadcrumbs
