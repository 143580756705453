import React from 'react'
import { Badge, Box, Link as MuiLink, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Link } from 'react-router-dom'
import { ReactComponent as GiftLogo } from './images/bonus.svg'
import { routes } from '../../../routes'
import { useBonusMaterial, useLocationScroll } from '../../../hooks'

const useStyles = makeStyles(({ typography }) => ({
  headerText: {
    ...typography.body.l
  },
  bodyText: {
    ...typography.body.s
  }
}))

const BonusMaterialCard = () => {
  const classes = useStyles()
  const { newestBonusMaterialCount } = useBonusMaterial()

  return (
    <Box>
      <Typography className={classes.headerText}>New <b>Members</b></Typography>
      <Box display='flex' flex={1} flexDirection='row' mt={2}>
        <Badge
          badgeContent={newestBonusMaterialCount} color='primary' anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          overlap='circular'
        >
          <GiftLogo />
        </Badge>
        <Box display='flex' flex={1} ml={3} mr={3} flexDirection='column'>
          <Typography className={classes.bodyText}>Bonus Material</Typography>
          <MuiLink
            to={routes['bonus-material'].path}
            component={Link}
            state={{ [useLocationScroll.SCROLLTOP]: true }}
            underline='hover'
          >
            <Typography className={classes.bodyText}>
              Check your bonuses
            </Typography>
          </MuiLink>
        </Box>

      </Box>
    </Box>
  )
}

export default BonusMaterialCard
