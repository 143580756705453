import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }
  },
  paper: {
    overflow: 'hidden'
  }
}))
