import makeStyles from '@mui/styles/makeStyles'
import { appBarHeight } from '../app-toolbar/style'

const bottomGap = 8

export const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& .MuiBackdrop-root': {
      background: `linear-gradient(transparent, transparent ${appBarHeight}px, white ${appBarHeight}px)`
    },
    '& .MuiDialog-paper': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: appBarHeight,
      margin: 0,
      maxWidth: 'inherit',
      [breakpoints.up('md')]: {
        height: `calc(100% - ${appBarHeight + bottomGap}px)`,
        width: 'calc(100% - 64px)'
      },
      borderRadius: 0,
      boxShadow: `0px 1px 2px 0px #1818180F,
                  0px 1px 3px 0px #1818181A`
    }
  }
}))
