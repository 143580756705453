import name from './name'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  appendSubscribeFreeNewsletter: false,
  message: ''
}

const freeAccountCta = createSlice({
  name,
  initialState,
  reducers: {
    openCtaModal: (state, { payload }) => {
      state.open = true
      state.message = payload.message
      state.appendSubscribeFreeNewsletter = payload.appendSubscribeFreeNewsletter
    },
    closeCtaModal: (state) => {
      state.open = false
      state.message = ''
      state.appendSubscribeFreeNewsletter = false
    }
  }
})

export const { openCtaModal, closeCtaModal } = freeAccountCta.actions

export default freeAccountCta.reducer
