import React from 'react'
import { Button, Typography, Divider, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ChevronRight } from '@mui/icons-material'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    /** This has been removed to increase the compatibility with custom fontsizes */
    // maxHeight: 32
  },
  divider: {
    marginTop: 7,
    marginInline: spacing(1)
  },
  link: {
    paddingInline: spacing(1),
    paddingTop: 0,
    paddingBottom: 1,
    justifyContent: 'space-between',
    color: '#7A7A80',
    '& .MuiSvgIcon-root': {
      fontSize: 24,
      marginRight: spacing(0.5)
    }
  },
  heading: {
    ...typography.body.l,
    fontWeight: typography.weight.medium,
    color: palette.grey.shade[80],
    marginLeft: spacing(1)
  }
}))

const MenuHeader = ({ title, link, onClick }) => {
  const classes = useStyles()
  return (
    <>
      <Box
        className={classes.root}
      >
        {link
          ? (
            <Button
              size='large'
              fullWidth
              onClick={() => onClick({ friendlyUrl: 'all' })}
              data-test='menu-header-link'
              endIcon={<ChevronRight />}
              className={classes.link}
            >
              <Typography variant='body2' noWrap>{link.text}</Typography>
            </Button>
            )
          : (
            <Typography component='h2' className={classes.heading} noWrap>
              {title}
            </Typography>
            )}
      </Box>
      <Divider className={classes.divider} />
    </>
  )
}

export default MenuHeader
