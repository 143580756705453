import { useFilteredQuery } from '../../../hooks'
import { handlers, arrayNames, filterNames } from '../params'

const useSearchQuery = () =>
  useFilteredQuery({
    handlers,
    arrayNames,
    filterNames
  })

export default useSearchQuery
