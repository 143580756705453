import React from 'react'
import useContentType from '../../hooks/useContentType'
import IconTag from '../icon-tag'

const ContentTypeBadge = ({
  primaryType,
  dataTest,
  ...props
}) => {
  const { formatIcon, label } = useContentType({ primaryType })

  return (
    <IconTag
      icon={formatIcon}
      text={label}
      data-test={dataTest}
      {...props}
    />
  )
}

export default ContentTypeBadge
