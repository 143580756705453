/* eslint-disable multiline-ternary */
import useFormFactor from '../hooks/useFormFactor'

/**
 * Custom Hidden component that works from the appbar breakpoints. These differ from
 * the breakpoints used in the rest of the app which is why this is needed.
 *
 * Shamelessly stolen from material-ui's Hidden component (JS implementation).
 * The css implementation adds an extra div which broke our layout.
 * https://github.com/mui/material-ui/blob/v4.12.4/packages/material-ui/src/Hidden/HiddenJs.js
 */
const Hidden = ({ children, ...props }) => {
  const { formFactor, breakpoints } = useFormFactor()
  const keys = Object.keys(breakpoints)

  const isWidthUp = (key, formFactor, inclusive = true) => {
    const result = inclusive
      ? keys.indexOf(key) <= keys.indexOf(formFactor)
      : keys.indexOf(key) < keys.indexOf(formFactor)
    return result
  }

  const isWidthDown = (key, formFactor, inclusive = true) => {
    const result = inclusive
      ? keys.indexOf(formFactor) <= keys.indexOf(key)
      : keys.indexOf(formFactor) < keys.indexOf(key)
    return result
  }

  const checkHidden = () => {
    if (!formFactor) {
      return
    }
    const hidden = keys.some(key => {
      const up = `${key}Up`
      const down = `${key}Down`
      return (
        (props[up] && isWidthUp(key, formFactor)) ||
        (props[down] && isWidthDown(key, formFactor))
      )
    })
    return hidden
  }

  return checkHidden() ? null : children
}

export default Hidden
