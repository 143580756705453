import React from 'react'
import { List } from '@mui/material'
import PlaylistResult from './playlist-result'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    height: '31ex',
    overflow: 'auto'
  }
}))

const PlaylistSearchResults = ({
  playlists = [],
  onChange: handleChange,
  value,
  disabled
}) => {
  const classes = useStyles()

  return (
    <List aria-label='playlists' disabled={disabled} className={classes.root}>
      {playlists.map((playlist, index) => (
        <PlaylistResult
          playlist={playlist}
          key={playlist?.pk ?? index}
          onSelect={handleChange}
          selected={playlist === value}
          disabled={disabled}
        />
      ))}
    </List>
  )
}

export default PlaylistSearchResults
