import React from 'react'
import { Grid } from '@mui/material'
import { Container } from '..'
import makeStyles from '@mui/styles/makeStyles'
import Footer from './footer'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    minHeight: '100vh',
    width: '100hw',
    [breakpoints.up('lg')]: {
      paddingTop: spacing(10)
    },
    [breakpoints.down('md')]: {
      paddingTop: spacing(7)
    },
    [breakpoints.down('sm')]: {
      paddingTop: spacing(5)
    }
  },
  container: {
    marginTop: 0
  },
  footer: {
    marginTop: spacing(7)
  }
}))

const ServicePage = ({ maxContentWidth, inset, children }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      wrap='nowrap'
      className={classes.root}
    >
      <Container
        maxContentWidth={maxContentWidth}
        inset={inset}
        className={classes.container}
      >
        <Grid container direction='column' wrap='nowrap' alignItems='center'>
          {children}
        </Grid>
      </Container>
      <Footer classes={{ root: classes.footer }} />
    </Grid>
  )
}

export default ServicePage
