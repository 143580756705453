import React from 'react'
import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material'
import { Alert as MuiAlert } from '@mui/material'

const iconMapping = {
  success: <CheckCircle fontSize='inherit' />,
  info: <Info fontSize='inherit' />,
  error: <Cancel fontSize='inherit' />,
  warning: <Warning fontSize='inherit' />
}

const Alert = ({ showClose, ...props }) => (
  <MuiAlert
    iconMapping={iconMapping}
    onClose={showClose && (() => {})}
    {...props}
  />
)

export default Alert
