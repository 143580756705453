import makeStyles from '@mui/styles/makeStyles'

export const Variant = {
  info: { color: 'primary' },
  warning: { color: 'alert' }
}

export const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  root: {
    padding: spacing()
  },
  title: {
    fontWeight: typography.fontWeightRegular,
    color: palette.grey.shade[90],
    fontSize: '1.125rem'
  },
  text: {
    color: palette.grey.shade[60]
  },
  iconContainer: {
    backgroundColor: ({ variant }) => palette[Variant[variant].color].shade[5],
    borderRadius: '50%',
    width: 40,
    height: 40
  },
  icon: {
    color: ({ variant }) => palette[Variant[variant].color].shade[60]
  },
  actions: {
    marginTop: spacing(3)
  }
}))
