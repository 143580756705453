/* eslint-disable multiline-ternary */
import React from 'react'
import { ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import { FolderOpenTwoTone } from '@mui/icons-material'

const PlaylistResult = ({ playlist, onSelect, selected, disabled }) => {
  const handleClick = () => {
    if (!disabled) onSelect(playlist)
  }

  return (
    <li>
      <ListItem button selected={selected} onClick={handleClick}>
        <ListItemIcon>
          {playlist ? (
            <FolderOpenTwoTone />
          ) : (
            <Skeleton width='50%' height={30} />
          )}
        </ListItemIcon>
        <ListItemText>
          {playlist?.title ?? <Skeleton width='75%' />}
        </ListItemText>
      </ListItem>
    </li>
  )
}

export default PlaylistResult
