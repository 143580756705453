import makeStyles from '@mui/styles/makeStyles'

export const usePanelStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.primary.main,
    paddingTop: spacing(8),
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    height: '100%'
  },
  text: {
    color: palette.common.white
  },
  title: {
    marginBottom: spacing(2)
  }
}))
