import React from 'react'
import { Grid, Typography, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ContentTypeBadge from '../../content-type-badge'
import { contentUrl } from '../../../helpers/url'
import Link from '../../link'

export const useStyles = makeStyles(
  ({ palette, spacing, typography, breakpoints }) => ({
    category: {
      textTransform: 'capitalize',
      '& p': {
        fontSize: '0.75rem !important',
        marginLeft: spacing(0.5)
      },
      '& svg': {
        fontSize: '0.75rem !imporant'
      }
    },
    title: {
      ...typography.body.s,
      fontWeight: typography.weight.medium
    },
    subtitle: {
      ...typography.body.s,
      color: '#454545'
    },
    bottomSpacing: {
      marginBottom: spacing(2)
    }
  })
)

const ArticleSummary = props => {
  const classes = useStyles(props)

  const {
    article: { friendlyUrl, title, subtitle, primaryType } = {},
    className = ''
  } = props

  const CardType = () => {
    if (!primaryType || !primaryType.length) {
      return <Skeleton width='20%' />
    }

    return (
      <ContentTypeBadge
        primaryType={primaryType}
        dataTest={`article-card-type-${friendlyUrl}`}
      />
    )
  }

  return (
    /* eslint-disable multiline-ternary */
    <Grid container direction='column' spacing={1} className={className}>
      <Grid item>
        <Typography
          variant='body1'
          component='h3'
          className={classes.title}
          data-test={`article-card-title-${friendlyUrl}`}
        >
          <Link to={title ? contentUrl(props.article) : ''}>
            {title ?? <Skeleton width='80%' />}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.subtitle}
          data-test={`article-card-subtitle-${friendlyUrl}`}
        >
          {subtitle?.html ?? (
            <>
              <Skeleton width='100%' />
              <Skeleton width='100%' />
            </>
          )}
        </Typography>
      </Grid>
      <Grid item className={classes.bottomSpacing}>
        <Typography
          variant='caption'
          color='textSecondary'
          className={classes.category}
          data-test={`article-card-type-${friendlyUrl}`}
        >
          <CardType />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ArticleSummary
