import name from './name'

const selectSliceData = state => state[name]

const selectGroups = state => selectSliceData(state).groups

const selectCategories = state => selectSliceData(state).categories

const selectPopular = state => selectSliceData(state).popular

const selectTrending = state => selectSliceData(state).trending

const selectRecommended = state => selectSliceData(state).recommended

const selectRelated = state => selectSliceData(state).related

const selectNewestReleases = state => selectSliceData(state).newestReleases

export {
  selectGroups,
  selectCategories,
  selectPopular,
  selectTrending,
  selectRecommended,
  selectNewestReleases,
  selectRelated
}
