import PropTypes from 'prop-types'
import { ActionButton } from '..'
import { MoreVertTwoTone } from '@mui/icons-material'
import { Popover } from '@mui/material'
import React, { useState } from 'react'
import { useStyles } from './style'

/** Popup menu providing a list of actions */
const MoreActions = ({ actions, loading, ...props }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ActionButton
        loading={loading}
        disabled={!actions}
        variant='secondary'
        startIcon={<MoreVertTwoTone />}
        label='Actions'
        onClick={handleClick}
        data-test='more-actions-button'
        {...props}
      />
      <Popover
        id='more-actions-menu'
        getContentAnchorEl={undefined}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: -5, horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
        data-test='more-actions-popover'
      >
        {actions({ className: classes.action, onClose: handleClose })}
      </Popover>
    </>
  )
}

MoreActions.propTypes = {
  /** Render function returning the contents of the menu
   * e.g
   * ``` actions => [<MyAction className={actions.className}>] ```
   */
  actions: PropTypes.func
}

export default MoreActions
