import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import playlistLanding from '../playlist-landing'
import playlist from '../playlist'

const initialState = {
  progressData: [],
  playlistIds: [],
  loadingIndicator: false
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setProgress: (state, { payload }) => {
      state.playlistIds = []
      state.progressData = payload
    },
    togglePlaylistContentRead: (state, { payload }) => {
      state.loadingIndicator = false
      state.playlistIds = [payload.playlistId]
    },
    setLoadingIndicator: (state, { payload }) => {
      state.loadingIndicator = payload.visible
    },
    reset: state => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(playlistLanding.actions.setResults, (state, { payload }) => {
        if (!payload.items) return
        const playlistIds = payload.items.map(playlist => {
          return playlist.pk
        })
        if (playlistIds.length) {
          state.playlistIds = playlistIds
        }
      })
      .addCase(playlist.actions.setDetail, (state, { payload: { playlist: { pk } } }) => {
        if (!pk) return
        state.playlistIds = [pk]
      })
  }
})

export const { setProgress, togglePlaylistContentRead, setLoadingIndicator } = content.actions

export default content.reducer
