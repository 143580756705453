import React from 'react'
import { ContentFormats } from '@emerald-works-nova/components'
import { CollectionsBookmarkTwoTone, Link } from '@mui/icons-material'

const useContentType = ({ primaryType }) => {
  const { formatIcon, formatLabel, typeLabel } = React.useMemo(() => {
    const [formatName, typeName] = primaryType

    if (formatName === 'playlist') {
      return {
        formatLabel: 'Playlist',
        formatIcon: <CollectionsBookmarkTwoTone />
      }
    }

    if (formatName === 'external') {
      return {
        formatLabel: 'External Link',
        formatIcon: <Link />
      }
    }

    const format = ContentFormats.find(f => f.name === formatName)
    const metadata = {
      formatLabel: format?.label,
      formatIcon: format?.icon
    }
    if (typeName) {
      const type = format?.types.find(t => t.name === typeName)
      metadata.typeLabel = type.label
    }
    return metadata
  }, [primaryType])

  return {
    formatIcon,
    formatLabel,
    typeLabel,
    label: typeLabel || formatLabel || 'Content'
  }
}

export default useContentType
