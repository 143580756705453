import React from 'react'
import { useStyles } from './style'

const EdgePanel = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.edge} />
      {children}
    </div>
  )
}

export default EdgePanel
