import { useState } from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { isFunction } from 'lodash'
import { useAlert } from '../../hooks'

const useLike = ({ onSuccess, onError, onStart, from } = {}) => {
  const [currentlyLiking, setCurrentlyLiking] = useState([])

  const { enqueueMsg } = useAlert()

  const [toggleRecommendation] = useEvent([
    {
      eventName: 'contentLikeToggled',
      onSuccess: payload => {
        stopLiking(payload.locationId)
        onSuccess(payload)
      },
      onError: payload => {
        const { sent: { locationId } = {} } = payload
        stopLiking(locationId)
        if (isFunction(onError)) {
          onError(payload)
        } else {
          enqueueMsg('Unable to toggle like', 'error')
        }
      }
    }
  ])

  const startLiking = locationId =>
    setCurrentlyLiking(current => [...current, locationId])

  const stopLiking = locationId =>
    setCurrentlyLiking(current => current.filter(item => item !== locationId))

  const handleLike = ({ locationId, hasRecommend }, isLike) => {
    startLiking(locationId)
    toggleRecommendation.trigger({ locationId, isLike: !hasRecommend, ...(from !== undefined && { from }) })
    if (isFunction(onStart)) {
      onStart(locationId)
    }
  }

  const liking = ({ locationId }) => currentlyLiking.includes(locationId)

  return { handleLike, liking }
}

export default useLike
