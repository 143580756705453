import { useEffect } from 'react'

const useAppbarEvent = ({ open, onClick }) => {
  useEffect(() => {
    const appbarEl = document.querySelector('#appbar-container')

    const handleClick = evt => {
      onClick(evt)
    }

    const addListeners = () => {
      if (appbarEl) {
        appbarEl.addEventListener('click', handleClick)
      }
    }

    const removeListeners = () => {
      if (appbarEl) {
        appbarEl.removeEventListener('click', handleClick)
      }
    }

    if (open) {
      addListeners()
    }

    return removeListeners
  }, [open, onClick])
}

export default useAppbarEvent
