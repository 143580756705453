import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  title: {
    marginBottom: spacing(2)
  },
  content: {
    '& > *': {
      marginBottom: spacing(3)
    }
  },
  divider: {
    backgroundColor: palette.grey.shade[5],
    marginBottom: spacing(3)
  },
  actions: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
    justifyContent: 'space-between',
    paddingBottom: spacing(3),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      '& > :not(:first-child)': {
        marginLeft: 0
      },
      '& button': {
        marginBottom: spacing(1)
      }
    }
  }
}))
