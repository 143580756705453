import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  showDialog: false
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    showDialog: (state, { payload }) => {
      state.showDialog = payload
    },
    reset: state => initialState
  }
})

export const { showDialog, reset } = content.actions

export default content.reducer
