import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CloseTwoTone } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(2),
    float: 'right'
  }
}))

const CloseButton = ({ onClick }) => {
  const classes = useStyles()
  return (
    <IconButton
      title='close menu'
      onClick={onClick}
      className={classes.root}
      size='large'
    >
      <CloseTwoTone />
    </IconButton>
  )
}

export default CloseButton
