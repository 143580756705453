export const routes = {
  article: {
    path: '/:locationId/*'
  },
  register: {
    path: '/signup'
  },
  signin: {
    path: '/signin'
  },
  internalLogin: {
    path: '/admin/internal-login'
  },
  b2cFreeRegister: {
    path: '/signup/free'
  },
  b2cRegister: {
    path: '/signup/:plan'
  },
  b2cSocialSignUpRedirect: {
    path: '/social-signup/redirect/:plan'
  },
  b2cRegisterSuccess: {
    path: '/b2c/sign-up/success/:username/:planCode'
  },
  b2cFreeRegisterSuccess: {
    path: '/b2c/sign-up/success/free'
  },
  login: {
    path: '/login'
  },
  'verify-email': {
    path: '/verify-email/:username/:code'
  },
  'resend-email': {
    path: '/verify-email'
  },
  'recovery-password': {
    path: '/password-recovery'
  },
  'recovered-password': {
    path: '/password-recovered'
  },
  'reset-password-end': {
    path: '/password-reset-end'
  },
  'reset-password': {
    path: '/password-reset/:username/:code'
  },
  'change-password': {
    path: '/change-password'
  },
  bookmarks: {
    path: '/bookmarks'
  },
  serp: {
    path: '/search-results'
  },
  profile: {
    path: '/profile'
  },
  retention: {
    path: '/profile/retention'
  },
  playlists: {
    path: '/playlists'
  },
  playlist: {
    path: '/playlists/:playlistId'
  },
  'playlist-edit': {
    path: '/playlists/:playlistId/edit'
  },
  'playlist-create': {
    path: '/playlists/new'
  },
  'skill-bites': {
    path: '/skill-bites'
  },
  'skill-bite': {
    path: '/skill-bites/:skillBiteId/'
  },
  'skill-bite-session': {
    path: '/skill-bites/:skillBiteId/:sessionId/'
  },
  'assessment-details': {
    path: '/assessments/:assessmentId/'
  },
  'assessment-organization-overview': {
    path: '/assessments/:assessmentId/organization-overview'
  },
  'privacy-policy': {
    path: '/policies/privacy'
  },
  'cookie-policy': {
    path: '/policies/cookie'
  },
  tandc: {
    path: '/policies/tandc'
  },
  'a11y-statement': {
    path: '/policies/a11y'
  },
  'newest-releases': {
    path: '/newest-releases'
  },
  'most-popular': {
    path: '/most-popular'
  },
  maintenance: {
    path: 'maintenance'
  },
  'bonus-material': {
    path: '/bonus-material'
  },
  home: {
    path: '/home'
  },
  index: {
    path: '/'
  },
  indexHtml: {
    path: '/index.html'
  },
  '*': {
    path: '*'
  }
}
