import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const defaultImage = '/logo512.png'

const MetaTags = ({
  title,
  description,
  canonicalLink = location.href,
  metaTags,
  ogTags = {},
  twitterTags = {},
  children
}) => {
  const openGraphTags = {
    'og:title': title,
    'og:description': description,
    'og:url': canonicalLink,
    'og:image': process.env.PUBLIC_URL + defaultImage,
    'og:type': 'website',
    ...ogTags
  }
  const twitterCardTags = {
    'twitter:card': 'summary',
    'twitter:site': '@Mind_Tools',
    ...twitterTags
  }

  return (
    <Helmet>
      <link rel='canonical' href={canonicalLink} />
      <title>{title}</title>
      <meta name='description' content={description} />
      {metaTags?.map(tag => (
        <meta key={tag.name} name={tag.name} content={tag.content} />
      ))}
      {Object.entries(openGraphTags).map(([key, value]) => {
        if (!value) return null
        if (key === 'og:image' && value !== defaultImage) {
          value += 'fit=crop&w=1200&h=627'
        }
        return <meta key={key} property={key} content={value} />
      })}
      {Object.entries(twitterCardTags).map(([key, value]) => (
        value ? <meta key={key} name={key} content={value} /> : null
      ))}
      {children}
    </Helmet>
  )
}

export default MetaTags

MetaTags.propTypes = {
  /** Content of `<title />` */
  title: PropTypes.string,
  /** Content of `<meta name='description' />`  */
  description: PropTypes.string,
  /** Content of `<link rel='canonical' />` */
  canonicalLink: PropTypes.string,
  /** Additional meta tags to add to the page e.g `noindex`, `nofollow` */
  metaTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string
    })
  )
}
