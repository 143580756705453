import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles'
import { createPalette } from './palette'
import { createTypography } from './typography'
import { createShadow } from './shadow'

const createTheme = (options = {}) => {
  const {
    palette: paletteInput = {},
    typography: typographyInput = {},
    shadow: shadowInput = {},
    ...others
  } = options

  const palette = createPalette(paletteInput)
  const typography = createTypography(typographyInput)
  const customShadows = createShadow(shadowInput)

  let theme = createMuiTheme({
    palette,
    typography,
    customShadows,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        },
        defaultProps: {
          color: 'inherit'
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '& .MuiListItem-selected': {
              backgroundColor: 'inherit',
              border: `1px solid ${palette.primary.main}`
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            wordBreak: 'break-word'
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderTop: '1px solid #DDDBE0',
            borderBottom: '1px solid #DDDBE0',
            boxShadow: 'none',
            '&:not(:last-child)': {
              borderBottom: 0
            },
            '&:before': {
              display: 'none'
            },
            '& .MuiAccordion-expanded': {
              margin: 'auto'
            }
          },
          expanded: {}
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            marginBottom: -1,
            minHeight: 56,
            '& .MuiAccordionSummary-expanded': {
              minHeight: 56
            }
          },
          expandIcon: {
            '& > .MuiIconButton-label': {
              backgroundColor: palette.primary.main,
              borderRadius: '50%',
              color: '#FFF'
            }
          },
          content: {
            '& .MuiAccordionSummary-expanded': {
              margin: '16px 0'
            }
          },
          expanded: {}
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 16
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            maxWidth: 290,
            '& > .MuiAlert-message': {
              width: '100%'
            },
            '&.MuiAlert-standardSuccess .MuiAlert-icon': {
              color: palette.success.shade[50]
            },
            '&.MuiAlert-standardInfo .MuiAlert-icon': {
              color: palette.accent.shade[50]
            }
          },
          standardSuccess: {
            backgroundColor: palette.success.shade[0],
            color: palette.success.shade[60],
            '& .MuiAlert-icon': {
              color: palette.success.shade[50]
            },
            '& .MuiAlert-title': {
              color: palette.success.shade[80]
            },
            '& .MuiAlert-actions': {
              color: palette.success.shade[80]
            }
          },
          standardInfo: {
            backgroundColor: '#d2eafe', // @@@ secondary/5
            color: palette.accent.shade[50]
          },
          standardError: {
            backgroundColor: palette.alert.shade[0],
            color: palette.alert.shade[60],
            '& .MuiAlert-icon': {
              color: palette.alert.shade[60]
            },
            '& .MuiAlert-title': {
              color: palette.alert.shade[70]
            },
            '& .MuiAlert-actions': {
              color: palette.alert.shade[80]
            }
          },
          action: {
            alignItems: 'baseline',
            paddingTop: 4
          },
          icon: {
            fontSize: '1.125rem'
          }
        }
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            fontSize: '0.875rem',
            textTransform: 'capitalize'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.grey.shade[50]
            }
          }
        }
      }
    },
    ...others
  })

  // Make font-size of Typography elements responsive:
  // https://mui.com/customization/typography/#responsive-font-sizes
  theme = responsiveFontSizes(theme)

  return theme
}

export { createTheme }
