import name from './name'

const selectSliceData = state => state[name]

const selectAll = state => selectSliceData(state)

const selectComments = state => selectSliceData(state).comments

const selectCommentsTotal = state => selectSliceData(state).total

const selectCommentsLoading = state => selectSliceData(state).loading

export {
  selectAll,
  selectComments,
  selectCommentsLoading,
  selectCommentsTotal
}
