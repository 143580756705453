const tabs = [
  { label: 'All', value: 'all' },
  { label: 'Content', value: 'content' },
  { label: 'Playlists', value: 'playlists' }
]

const sorters = ['Relevance', 'New', 'Popular']

const arrayNames = ['type', 'keywords']

const filterNames = ['type', 'keywords', 'time']

const strValue = value => value.toLowerCase()

const isIn = (value, values) => values.includes(strValue(value))

const isCommaSepList = value => /^\s*(\S+\s*,?)+\s*$/.test(value)

const cleanParams = values => ({
  start: value => parseInt(value, 10) || 1,
  sort: value => (isIn(value, values.sort) ? strValue(value) : values.sort[0]),
  contenttype: value =>
    isIn(value, values.contenttype) ? strValue(value) : values.contenttype[0],
  type: value => (isCommaSepList(value) ? value : ''),
  keywords: value => (isCommaSepList(value) ? value : '')
})

const handlers = cleanParams({
  contenttype: tabs.map(({ value }) => value),
  sort: sorters.map(strValue)
})

export { tabs, sorters, arrayNames, filterNames, handlers }
