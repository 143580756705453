import template from './template'
import * as helpers from './helpers'

export default {
  ...template,
  micro: {
    fontSize: helpers.pxToRem(12),
    lineHeight: 1.333
  },
  body: {
    xs: {
      fontSize: helpers.pxToRem(13),
      lineHeight: 16 / 13
    },
    s: {
      fontSize: helpers.pxToRem(14),
      lineHeight: 20 / 14
    },
    m: {
      fontSize: helpers.pxToRem(16),
      lineHeight: 24 / 16
    },
    l: {
      fontSize: helpers.pxToRem(18),
      lineHeight: 24 / 18
    }
  },
  heading: {
    h1: {
      fontSize: helpers.pxToRem(40),
      lineHeight: 1
    },
    h2: {
      fontSize: helpers.pxToRem(32),
      lineHeight: 40 / 32
    },
    h3: {
      fontSize: helpers.pxToRem(28),
      lineHeight: 32 / 28
    },
    h4: {
      fontSize: helpers.pxToRem(24),
      lineHeight: 32 / 24
    },
    h5: {
      fontSize: helpers.pxToRem(20),
      lineHeight: 24 / 20
    }
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700,
    extra: 900
  }
}
