import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(() => ({
  root: {
  },
  gridList: {
    flexWrap: props => props.listDirection === 'row' ? 'nowrap' : 'wrap'
  },
  item: {
    flex: 1
  }
}))
