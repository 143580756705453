import { useEffect } from 'react'
import { useGoogleAdsense } from '../../hooks'

const GoogleAdsWrapper = () => {
  const shouldBeAds = useGoogleAdsense()

  useEffect(() => {
    const script = document.querySelector('script[src*="adsbygoogle.js"]')

    if (!shouldBeAds && script) {
      // Remove the script from the head
      script.remove()
      return
    }

    if (shouldBeAds && !script) {
      try {
        const script = document.createElement('script')
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_GOOGLE_ADSENSE_CLIENT}`
        script.crossorigin = 'anonymous'
        script.onload = () => {
          // Trigger googleadsense to reprocess the ads in the page.
          (window.adsbygoogle = window.adsbygoogle || []).push({})
        }
        document.head.appendChild(script)
      } catch (error) {
        console.debug('*** Google Adsense - ERROR LOADING', error)
      }
    }
  }, [shouldBeAds])

  return null
}

export default GoogleAdsWrapper
