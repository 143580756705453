import React from 'react'
import { routes } from '../../routes'
import { useLocationScroll } from '../../hooks'
import {
  CookiesPolicyLink as SharedCookiesPolicyLink,
  ContactLink as SharedContactLink,
  TandCLink as SharedTandCLink,
  PrivacyPolicyLink as SharedPrivacyPolicyLink,
  A11yStatementLink as SharedA11yStatementLink,
  B2BPrivacyPolicyLink as SharedB2BPrivacyPolicyLink,
  B2BCookiesPolicyLink as SharedB2BCookiesPolicyLink,
  B2BTandCLink as SharedB2BTandCLink,
  B2BContactLink as SharedB2BContactLink
} from '../policy-links'

const PrivacyPolicyLink = () => (
  <SharedPrivacyPolicyLink data-test='footer-privacy-policy-link' />
)

const TandCLink = () => (
  <SharedTandCLink
    to={routes.tandc.path}
    state={{ [useLocationScroll.SCROLLTOP]: true }}
    data-test='footer-tandc-link'
  >
    Terms and Conditions
  </SharedTandCLink>
)

const ContactLink = () => (
  <SharedContactLink data-test='footer-contact-link'>
    Contact Us
  </SharedContactLink>
)

const CookiesPolicyLink = () => (
  <SharedCookiesPolicyLink data-test='footer-cookies-policy-link' />
)

const A11yStatementLink = () => (
  <SharedA11yStatementLink data-test='footer-a11y-statement-link' />
)

const B2BPrivacyPolicyLink = () => (
  <SharedB2BPrivacyPolicyLink data-test='footer-privacy-policy-link' />
)

const B2BCookiesPolicyLink = () => (
  <SharedB2BCookiesPolicyLink data-test='footer-cookies-policy-link' />
)

const B2BTandCLink = () => (
  <SharedB2BTandCLink data-test='footer-tandc-link' />
)

const B2BContactLink = () => (
  <SharedB2BContactLink data-test='footer-contact-link'>
    Contact Us
  </SharedB2BContactLink>
)

export {
  ContactLink,
  CookiesPolicyLink,
  PrivacyPolicyLink,
  TandCLink,
  A11yStatementLink,
  B2BPrivacyPolicyLink,
  B2BCookiesPolicyLink,
  B2BTandCLink,
  B2BContactLink
}
