import name from './name'
import * as selectors from './selectors'
import reducer, * as actions from './slice'
import * as eventBus from './event-bus'

export default {
  actions,
  name,
  reducer,
  selectors,
  eventBus
}
