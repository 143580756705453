import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },
  article: {
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain'
    }
  },
  contentMetadata: {
    marginBottom: spacing(1),
    fontSize: '0.75rem',
    color: '#79848B',
    '& svg': {
      width: 16,
      height: 16,
      verticalAlign: 'sub',
      marginRight: 4
    }
  },
  title: {
    marginBottom: spacing(2)
  },
  subtitle: {
    marginBottom: spacing(2),
    fontSize: '0.9rem'
  },
  header: {
    marginBottom: spacing(6)
  },
  body: {
    marginBottom: spacing(6)
  },
  divider: {
    marginBottom: spacing(2)
  }
}))
