import name from './name'

const selectSliceData = state => state[name]

const selectPlaylist = state => selectSliceData(state)

const selectContentUrls = state =>
  selectSliceData(state).content.map(({ sk }) => sk)

const selectBack = state => selectSliceData(state).back || {}

const selectContentCount = state => selectSliceData(state).content?.length ?? 0

const selectPlaylistIds = state => selectSliceData(state).playlistIds

const selectContentReadTimes = state => selectSliceData(state).readTimes

export { selectPlaylist, selectContentUrls, selectBack, selectContentCount, selectPlaylistIds, selectContentReadTimes }
