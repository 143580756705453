import React from 'react'
import { Box, Grid, Typography, Skeleton } from '@mui/material'
import { useStyles } from './style'
import { contentUrl } from '../../../helpers/url'
import { useNavigate } from 'react-router-dom'
import Link from '../../link'
import SectionHeader from './section-header'
import { isEmpty } from 'lodash'
import WithLoading from '../../with-loading'

const RelatedHeader = props => {
  const classes = useStyles(props)
  return (
    <SectionHeader classes={{ root: classes.relatedSectionHeader }}>
      <span>Related to what you </span>
      <Box component='span' display='block' fontWeight='fontWeightBold'>
        Last Read
      </Box>
    </SectionHeader>
  )
}

const RelatedItem = props => {
  const classes = useStyles(props)
  const navigate = useNavigate()
  const { content } = props

  let contentImage = ''

  if (content?.image?.imgUrl) {
    contentImage = content?.image?.imgUrl + 'fit=crop&w=327&h=184'
  }
  return (
    <Grid container item xs={12} onClick={() => navigate(contentUrl(content))} className={classes.itemContainer} data-test='related-last-read-card-image-container'>
      <Grid item>
        {
            content
              ? (
                <img
                  src={contentImage}
                  title={content?.image?.altText}
                  data-test={`card-image-${content?.friendlyUrl}`}
                  className={classes.imageRead}
                />
                )
              : (
                <Skeleton variant='rectangular' className={classes.imageRead} />
                )
        }
      </Grid>
      <Grid item className={classes.itemDescRead}>
        <Typography className={classes.titleRead}>
          {content ? <Link to={contentUrl(content)}>{content.title}</Link> : <Skeleton width='100%' />}
        </Typography>
        <Typography className={classes.subtitleRead} color='textSecondary'>
          {content ? content.primaryType[0] : <Skeleton width='50%' />}
        </Typography>
      </Grid>
    </Grid>
  )
}
const RelatedContent = props => {
  const { content, isConsumer } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WithLoading loading={isEmpty(content)}>
          <RelatedHeader isConsumer={isConsumer} />
        </WithLoading>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        {content?.length > 0
          ? content.map((x, i) => <RelatedItem key={i} content={x} isConsumer={isConsumer} />)
          : [...Array(4)].map((x, i) => <RelatedItem key={i} />)}
      </Grid>
    </Grid>
  )
}

export default RelatedContent
