import React from 'react'
import { CardMedia, Grid, Skeleton } from '@mui/material'
import ArticleSummary from './article-summary'
import ArticleCardContainer from './article-card-container'
import makeStyles from '@mui/styles/makeStyles'
import { image } from '../../placeholder'

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  image: {
    width: 66,
    height: 50
  }
}))

const ArticleCard = ({ onSelect, article, showImage = true }) => {
  const classes = useStyles()

  return (
    <ArticleCardContainer article={article} onSelect={onSelect}>
      <Grid container wrap='nowrap' alignItems='center' spacing={3}>
        {showImage && (
          <Grid item>
            {article
              ? <CardMedia
                  image={(article.image?.imgUrl ?? image) + 'h=50&w=66'}
                  title='alt text here'
                  className={classes.image}
                  data-test={`article-card-image-${article?.friendlyUrl}`}
                />
              : <Skeleton variant='rectangular' className={classes.image} />}
          </Grid>
        )}
        <Grid container item>
          <ArticleSummary article={article} />
        </Grid>
      </Grid>
    </ArticleCardContainer>
  )
}

export default ArticleCard
