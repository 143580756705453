import React from 'react'
import { AddToPlaylist, Container, SimpleBreadcrumbs, MetaTags } from '../../components'
import { Box, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { HomeCard } from '../../components/general-card'
import { useAddToPlaylist } from '../add-to-playlist-dialog'
import { useLike } from '../like'
import { useAddToBookmarks } from '../add-to-bookmarks'

const gridContainerLayout = {
  xs: 12,
  sm: 6,
  md: 12,
  lg: 12,
  xl: 12
}

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  title: {
    fontWeight: typography.fontWeightBold,
    color: palette.grey.shade[100],
    marginBottom: spacing(5)
  }
}))

const PAGE_SIZE = 10

const ContentByTypeLanding = ({ crumbs, isLoading, items, handleContentLike, handleCardSelect, pageTitle, pageId }) => {
  const { showAddToPlaylist, getPlaylistArticle } = useAddToPlaylist()

  const { handleLike, liking } = useLike({
    onSuccess: payload => handleContentLike && handleContentLike(payload),
    from: pageId
  })

  const { isBookmarked, handleBookmark, bookmarking } = useAddToBookmarks(
    items
  )

  const classes = useStyles()

  return (
    <Container maxContentWidth={1200} inset={3}>
      <MetaTags title={pageTitle} />
      <AddToPlaylist article={getPlaylistArticle()} backText='Back to Home' />
      <Box mt={2} mb={2}>
        <Box mb={2}>
          <SimpleBreadcrumbs isLoading={false} crumbs={crumbs} />
          <Typography variant='h4' component='h1' className={classes.title}>
            {pageTitle}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid
              item
              {...gridContainerLayout}
              key={item?.friendlyUrl ?? index}
            >
              <HomeCard
                onSelect={() => handleCardSelect(item)}
                onAddToPlaylist={showAddToPlaylist}
                onLike={() => handleLike(item)}
                actionLoading={{
                  like: liking(item),
                  bookmark: bookmarking(item)
                }}
                content={item}
                testId={`home-card-${index}`}
                onBookmark={() => handleBookmark(item)}
                bookmarked={isBookmarked(item)}
              />
            </Grid>
          ))}
          {isLoading && (
            new Array(PAGE_SIZE).fill(null).map((item, idx) => (
              <Grid
                item
                {...gridContainerLayout}
                key={idx}
              >
                <HomeCard
                  onSelect={() => handleCardSelect(item)}
                  content={item}
                  testId={`content-card-${idx}`}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>

    </Container>
  )
}

export default ContentByTypeLanding
