import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './style'

export const Edge = ({ children }) => {
  const classes = useStyles({ width: 2 })

  return (
    <Box className={classes.edge}>
      {children}
    </Box>
  )
}

export const Stack = ({ number, children }) => {
  const classes = useStyles({ number })
  if (number === 0) { return <Edge>{children}</Edge> }
  return (
    <Edge>
      <Box className={classes.stack}>
        <Stack number={number - 1}>
          {children}
        </Stack>
      </Box>
    </Edge>
  )
}
