import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addToPlaylistSlice } from '../../reducers'

const useAddToPlaylist = () => {
  const dispatch = useDispatch()

  const [article, setArticle] = useState()

  const showAddToPlaylist = article => {
    setArticle(article)
    dispatch(addToPlaylistSlice.actions.showDialog(true))
  }

  const getPlaylistArticle = useCallback(() => article, [article])

  return { showAddToPlaylist, getPlaylistArticle }
}

export default useAddToPlaylist
