import * as actions from './slice'

export const getSkillBiteDetail = {
  eventName: 'skillBiteGetWithSessionsSubscription',
  onSuccess: {
    action: actions.setSkillBite,
    redux: true
  }
}

export const subscribeUser = ({ onSuccess, onError }) => {
  return {
    eventName: 'skillBiteSubscribeUser',
    onSuccess: [
      { action: actions.setSkillBiteSubscription, redux: true },
      onSuccess
    ],
    onError
  }
}

export const unsubscribeUser = ({ onSuccess, onError }) => {
  return {
    eventName: 'skillBiteUnsubscribeUser',
    onSuccess: [
      { action: actions.setSkillBiteSubscription, redux: true },
      onSuccess
    ],
    onError
  }
}

export const pauseUserEmails = ({ onSuccess, onError }) => {
  return {
    eventName: 'skillBitePauseEmails',
    onSuccess,
    onError
  }
}
