import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing }) => ({
  buttons: ({ variant }) => ({
    marginBottom: variant === 'dense' ? 0 : spacing(2)
  }),
  divider: {
    marginBottom: spacing(4)
  }
}))
