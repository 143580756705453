import name from './name'

const selectSliceData = state => state[name]
const selectBookmarked = state => selectSliceData(state).bookmarked
const selectLoading = state => selectSliceData(state).loading
const selectTotal = state => selectSliceData(state).total

export {
  selectBookmarked,
  selectLoading,
  selectTotal
}
