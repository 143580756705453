import React from 'react'
import { Grid, ListItem, ListItemText, Typography } from '@mui/material'
import { useStyles } from './style'
import clsx from 'clsx'

const SuggestedContent = ({ input, suggestions, onInputChange }) => {
  const classes = useStyles()
  const testAttr = 'search-related-list'
  return (
    <>
      <ListItem divider className={classes.searchSuggestionsTitle}>
        <ListItemText
          primaryTypographyProps={{ 'data-test': `${testAttr}-title` }}
        >
          Recommended Keywords
        </ListItemText>
      </ListItem>
      {suggestions.map((value, idx) => (
        <ListItem
          button
          component='li'
          onClick={() => onInputChange(value, 'search-suggestion', idx)}
          divider={idx < suggestions.length - 1}
          key={idx}
          className={clsx(
            classes.suggestedContentItem,
            idx === suggestions.length - 1 && classes.lastSuggestedItem
          )}
          data-test={`${testAttr}-item-${idx}`}
        >
          <ListItemText disableTypography>
            <Grid
              container
              wrap='nowrap'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}
            >
              <Grid item>
                <Typography
                  variant='body2'
                  className={classes.itemText}
                  data-test={`${testAttr}-item-${idx}-text`}
                >
                  {value}
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      ))}
    </>
  )
}

export default SuggestedContent
