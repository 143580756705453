import React from 'react'
import { Dialog, Slide } from '@mui/material'
import { useStyles } from './style'

const transitionTimeout = 400
const transitionProps = { direction: 'right', timeout: transitionTimeout }

/**
 * A dialog that takes up all of the viewport apart from the application's
 * app bar.
 */
const FullPageDialog = ({ TransitionProps, ...props }) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      open={open}
      className={classes.root}
      TransitionComponent={Slide}
      TransitionProps={{ ...transitionProps, ...TransitionProps }}
      {...props}
    />
  )
}

export default FullPageDialog
