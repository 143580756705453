import React from 'react'
import { Typography } from '@mui/material'
import { Actions, Desc, ServicePage, Title } from '../../components/service-page'
import { ReactComponent as MaintenanceImage } from './images/maintenance.svg'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  ({ breakpoints, typography, palette, spacing }) => ({
    subTitle: {
      fontWeight: typography.fontWeightBold,
      color: palette.grey.shade[90],
      marginBottom: spacing(4),
      [breakpoints.up('xs')]: {
        ...typography.heading.h5
      },
      [breakpoints.up('sm')]: {
        maxWidth: 550
      },
      [breakpoints.up('lg')]: {
        ...typography.heading.h3,
        maxWidth: 480
      }
    },
    image: {
      marginBottom: spacing(3)
    }
  })
)

const title = 'Ooops!'

// Confirm with PO on Planning - Deelte or change it to Mind Tools Team.
const desc = [
  'We expect to be back up and running in a few minutes.',
  'If you need to contact us in the meantime please email mtfbsupport@mindtools.com.',
  'We apologise for any inconvience this may cause.'
]

const subTitle = 'This page is currently under going some routine maintenance'

const MaintenancePage = props => {
  const classes = useStyles()

  return (
    <ServicePage maxContentWidth={1440} inset={3}>
      <Title>{title}</Title>
      <Typography align='center' className={classes.subTitle}>
        {subTitle}
      </Typography>
      <MaintenanceImage className={classes.image} />
      <Desc text={desc} />
      <Actions />
    </ServicePage>
  )
}

export default MaintenancePage
