/* eslint-disable indent */
import { useSession } from '@emerald-works/react-auth'
import { BROWSER } from '../services/cognito/userGroups'
import { useCallback } from 'react'

const useGroup = () => {
  const { session } = useSession()

  const groups = session?.accessToken?.payload['cognito:groups']

  /**
   * allowed groups can be single group or array of groups
   */
  const isInGroup = useCallback(allowedGroups => {
    if (!Array.isArray(allowedGroups)) {
      allowedGroups = [allowedGroups]
    }
    return groups
      ? groups.some(group => {
        return allowedGroups.includes(group)
      })
      : allowedGroups.includes(BROWSER)
  }, [groups])

  return { groups, isInGroup }
}

export default useGroup
