import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  categoryDefaultHierarchy: [],
  pathToRoot: [],
  childrenCategories: undefined
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setCategory: (state, { payload }) => {
      const update = payload
      if (update.itemsCount) {
        update.category.itemsCount = update.itemsCount
      }

      return { ...state, ...update }
    },
    setCategoryDefaultHierarchy: (state, { payload }) => {
      state.categoryDefaultHierarchy = payload.hierarchy
    },
    setCategoryDefaultHierarchyError: (state, { payload }) => {
      state.categoryDefaultHierarchyError = payload
    },
    reset: () => initialState
  }
})

export const {
  setCategory,
  setCategoryDefaultHierarchy,
  setCategoryDefaultHierarchyError,
  reset
} = content.actions

export default content.reducer
