/* eslint-disable indent */
import React, { useState } from 'react'
import { Box, Typography, TextField, CircularProgress, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CheckCircleSharp, MailOutline, Error
} from '@mui/icons-material'
import { ActionButton } from '../action-buttons'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { newsletterSlice } from '../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    maxWidth: 800,
    gap: props => props.isCard ? 20 : 40,
    display: 'flex',
    flexDirection: props => props.isCard ? 'column' : undefined,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 10
    }
  },
  cardInput: {
    '& .MuiInputBase-root': {
      background: '#FFF'
    },
    '& .MuiFormHelperText-root': {
      background: 'transparent',
      margin: 0,
      marginTop: 4
    }
  },
  title: {
    fontWeight: '700'
  },
  statusContainer: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  successIcon: {
    fontSize: 40
  },
  errorIcon: {
    fontSize: 40
  },
  errorMessage: {
    fontSize: '0.8rem',
    color: '#F44336',
    fontWeight: '600'
  }
}))

const COMPONENT_STATES = {
  FORM: 'form',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
}

const KNOWN_ERRORS_CODE = ['ERR_MAX_SUBSCRIPTIONS_PER_IP', 'ERR_MAX_SUBSCRIPTIONS_PER_CONNECTION']

const SubscribeToNewsletterSchema = yup.object({
  email: yup
    .string()
    .email('Please, provide a valid email address')
    .required('E-mail field is required')
})

export const SubscribeToNewsletterForm = ({ mode, afterSuccess }) => {
  const isCard = mode === 'CARD'
  const classes = useStyles({ isCard })
  const isSubscribed = useSelector(newsletterSlice.selectors.selectIsSubscribedToNewsletter)
  const dispatch = useDispatch()
  const [componentState, setComponentState] = useState(COMPONENT_STATES.FORM)
  const [error, setError] = useState(null)

  const [subscribeToFreeNewsletter] = useEvent([{
    eventName: 'subscribeToFreeNewsletter',
    onStart: () => {
      setComponentState(COMPONENT_STATES.LOADING)
    },
    onSuccess: (payload) => {
      setComponentState(COMPONENT_STATES.SUCCESS)
      dispatch(newsletterSlice.actions.setNewsletterSubscribed())
      afterSuccess && afterSuccess()
    },
    onError: (error) => {
      setComponentState(COMPONENT_STATES.ERROR)
      setError(error)
    }
  }])

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(SubscribeToNewsletterSchema),
    defaultValues: {
      email: ''
    }
  })

  function renderDynamicComponent () {
    if (componentState === COMPONENT_STATES.SUCCESS || isSubscribed) {
      return (
        <Box className={classes.statusContainer} gap='10px'>
          <CheckCircleSharp className={classes.successIcon} htmlColor='#00D13B' />
          <Typography>Successfully subscribed to the newsletter</Typography>
        </Box>
      )
    }

    if (componentState === COMPONENT_STATES.FORM) {
      const inputProps = isCard
        ? {
            variant: 'outlined',
            className: classes.cardInput,
            size: 'small',
            InputProps: {
              startAdornment: (
                <InputAdornment position='start'>
                  <MailOutline />
                </InputAdornment>
              )
            }
            }
        : {}

      return (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit((data) => {
            subscribeToFreeNewsletter.trigger({ email: data.email, from: mode === 'CARD' ? 'card' : 'popover' })
          })}
          >
            <Controller
              name='email'
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder='Email address'
                  fullWidth
                  required
                  error={fieldState.error}
                  helperText={fieldState.error?.message}
                  type='email'
                  {...inputProps}
                  {...field}
                />
              )}
            />
            <Box mt={2} mb={2}>
              <ActionButton
                label='Subscribe now'
                variant='primary'
                type='submit'
                fullWidth={isCard}
                disabled={!form.formState.isValid}
              />
            </Box>
          </form>
        </FormProvider>
      )
    }

    if (componentState === COMPONENT_STATES.ERROR) {
      const message = KNOWN_ERRORS_CODE.includes(error?.code) ? error?.description : 'An unexpected error happened. Please, try again later.'
      return (
        <Box className={classes.statusContainer} gap='10px'>
          <Error className={classes.errorIcon} htmlColor='#B00020' />
          <Typography className={classes.errorMessage}>{message}</Typography>
        </Box>
      )
    }

    if (componentState === COMPONENT_STATES.LOADING) {
      return (
        <Box className={classes.statusContainer} gap='20px'>
          <CircularProgress color='primary' />
          <Typography>Subscribing...</Typography>
        </Box>
      )
    }
  }

  return (
    <Box className={classes.root} id='newsletter-form'>
      <Box flex={1}>
        <Typography className={classes.title}>Sign-up to our newsletter</Typography>
        <Typography>Subscribing to the Mindtools newsletter will keep you up-to-date with our latest updates and newest resources.</Typography>
      </Box>
      <Box flex={1}>
        {renderDynamicComponent()}
      </Box>
    </Box>
  )
}
