import { useCallback } from 'react'
import { ALLOW } from './permissions'
import { useGroup } from '../../../hooks'
import useFormFactor from './useFormFactor'

const useAccessByContext = () => {
  const { isInGroup } = useGroup()
  const { formFactor } = useFormFactor()

  const dataInContext = useCallback(
    (data, context, id) => {
      if (!formFactor) {
        return
      }
      const formFactorData = data[formFactor]
      if (!formFactorData) {
        return
      }
      const contextData = formFactorData[context]
      if (!contextData) {
        return
      }
      return contextData[id]
    },
    [formFactor]
  )

  const permissionForContext = useCallback(
    (context, key, permissions) => {
      const perms = dataInContext(permissions, context, key)
      return isInGroup(perms)
    },
    [dataInContext, isInGroup]
  )

  const accessInContext = useCallback(
    (context, key) => permissionForContext(context, key, ALLOW),
    [permissionForContext]
  )

  return { accessInContext }
}

export default useAccessByContext
