import { useSession } from '@emerald-works/react-auth'
import { Collapse, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from '../../../hooks'
import usePromiseEvent from '../../../hooks/usePromiseEvent'
import { contentSlice, commentSlice } from '../../../reducers'
import Comment from '../comment'
import CommentInput from '../comment-input'
import Replies from './replies'

/** Displays a top level comment with its replies and reply input <br/>
 * Only the first reply is shown until the 'View more replies' button is clicked
 */
const CommentGroup = props => {
  const { comment, ...other } = props
  const { locationId } = useSelector(contentSlice.selectors.selectContent)
  const dispatch = useDispatch()
  const temporaryId = comment?.id ?? 'loading'
  const [replyOpen, setReplyOpen] = useState(false)
  const addComment = usePromiseEvent('commentInsert')
  const { enqueueMsg } = useAlert()
  const { user: sessionUser } = useSession()
  const { attributes: { given_name: givenName, family_name: familyName, ...rest } } = sessionUser || { attributes: {} }
  const user = { givenName, familyName, ...rest }

  const handleReply = async value => {
    try {
      dispatch(commentSlice.actions.preAddComment({ parent: comment.id }))
      const result = await addComment({ locationId, comment: value, parent: comment.id, type: 'reply' })
      dispatch(commentSlice.actions.addComment(result))
      setReplyOpen(false)
    } catch (error) {
      dispatch(commentSlice.actions.cancelPreAddComment({ parent: comment.id }))
      enqueueMsg('Unable to reply to this comment', 'error')
    }
  }

  return (
    <Grid
      container
      direction='column'
      spacing={2}
      data-test={`comment-group-${temporaryId}`}
      {...other}
    >
      <Grid item>
        <Comment
          comment={comment}
          onReply={() => setReplyOpen(true)}
        />
      </Grid>
      <Grid
        container
        item
        direction='column'
        spacing={2}
        style={{ paddingLeft: 48 }}
      >
        <Grid item>
          <Collapse in={replyOpen}>
            <CommentInput
              isReply
              author={user}
              onSubmit={handleReply}
              onCancel={() => setReplyOpen(false)}
            />
          </Collapse>
        </Grid>
        {comment && comment.replies && comment?.replies?.length !== 0 && (
          <Replies
            replies={comment.replies}
            data-test={`comment-replies-${temporaryId}`}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default CommentGroup
