import name from './name'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visibleToasts: [],
  notistackAlerts: []
}

const toast = createSlice({
  name,
  initialState,
  reducers: {
    showToast: ({ visibleToasts }, { payload }) => {
      visibleToasts.push(payload)
    },
    hideToast: ({ visibleToasts }, { payload }) => {
      const index = visibleToasts.findIndex(item => item === payload)
      if (index >= 0) {
        visibleToasts.splice(index, 1)
      }
    },
    stackAlert: ({ notistackAlerts }, { payload }) => {
      notistackAlerts.push(payload)
    },
    unstackAlerts: (state, { payload }) => {
      state.notistackAlerts = state.notistackAlerts.filter(
        ({ id }) => !payload.includes(id)
      )
      // const index = notistackAlerts.findIndex(({ id }) => id === payload)
      // if (index >= 0) {
      //   notistackAlerts.splice(index, 1)
      // }
    }
  }
})

export const { showToast, hideToast, stackAlert, unstackAlerts } = toast.actions

export default toast.reducer
