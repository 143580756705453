import React from 'react'
import { Typography, Grid, IconButton, Hidden, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    ...typography.heading.h5,
    fontWeight: typography.weight.medium
  }
}))

const Header = ({ title, onClose }) => {
  const classes = useStyles()
  return (
    <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
      <Grid item>
        <Box ml={1}>
          <Typography variant='h5' className={classes.header}>
            {title}
          </Typography>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item>
          <IconButton
            aria-label='close navigation menu'
            onClick={onClose}
            data-test='nav-close-modal'
            size='large'
          >
            <Close />
          </IconButton>
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default Header
