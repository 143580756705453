import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  summary: {
    padding: 0,
    marginTop: 0,
    marginLeft: spacing(2),
    marginRight: 0
  },
  actions: {
    marginBottom: 0
  },
  card: {
    paddingTop: spacing(2),
    paddingBottom: spacing(4)
  }
}))
