import React from 'react'
import PropTypes from 'prop-types'
import {
  AddToPlaylistButton,
  BookmarkButton,
  ToggleDescriptionButton,
  MoreActions
} from '../../action-buttons'
import { useFormFactor, useGroup } from '../../../hooks'
import BaseContentCard from './base-content-card'
import { ALLOW_PLAYLISTS_GROUP } from '../../user-group-policy/permissions'
import { useCardExpand } from '../hooks'
import { useDisplayAddToPlaylist } from '../../../hooks/useDisplayAddToPlaylist'

/** Card for displaying content such as articles, videos and audio etc */
const ContentCard = ({
  expand,
  onAddToPlaylist,
  onBookmark,
  bookmarked,
  actionLoading,
  ...props
}) => {
  const { isDesktop } = useFormFactor()
  const displayAddToPlaylist = useDisplayAddToPlaylist()

  const {
    expanded,
    handleToggleDescription,
    handleMouseEnter,
    handleMouseLeave
  } = useCardExpand({ expand })

  const { isInGroup } = useGroup()

  const actions = {
    leftActions: (
      <>
        {!isDesktop && (
          <ToggleDescriptionButton
            size='xs'
            toggled={expanded}
            onClick={handleToggleDescription}
          />
        )}
        <MoreActions
          size='xs'
          loading={actionLoading}
          actions={actions => [
            <BookmarkButton
              key='Bookmark'
              className={actions.className}
              onClick={() => {
                onBookmark()
                actions.onClose()
              }}
              bookmarked={bookmarked}
            />,
            ...(isInGroup(ALLOW_PLAYLISTS_GROUP) && displayAddToPlaylist
              ? [
                <AddToPlaylistButton
                  key='AddToPlaylist'
                  onClick={() => {
                    onAddToPlaylist(props.content)
                    actions.onClose()
                  }}
                  className={actions.className}
                />
                ]
              : [])
          ]}
        />
      </>
    )
  }
  return (
    <BaseContentCard
      actions={actions}
      expand={expanded}
      hoverShadow={expanded}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    />
  )
}

ContentCard.propTypes = {
  /** Should the card always be expanded (show description)?  */
  expand: PropTypes.bool
}
ContentCard.defaultProps = {
  size: 'small',
  expand: false
}
export default ContentCard
