import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ErrorDialog, Busy } from '../../components'
import { errorSlice, busySlice } from '../../reducers'
import { isString } from 'lodash'

const PageContainer = ({ children }) => {
  const error = useSelector(errorSlice.selectors.selectError)
  const isBusy = useSelector(busySlice.selectors.selectIsBusy)

  const dispatch = useDispatch()

  return (
    <>
      <ErrorDialog
        open={Boolean(error)}
        message={isString(error) ? error : 'An error has occurred'}
        onClose={() => dispatch(errorSlice.actions.setError())}
      />
      <Busy open={isBusy} />
      {children}
    </>
  )
}

export default PageContainer
