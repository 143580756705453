/* eslint-disable multiline-ternary */
import { List, ListItem } from '@mui/material'
import React, { useContext } from 'react'
import useFormFactor from '../hooks/useFormFactor'
import LinkBar from '../links/link-bar'
import makeStyles from '@mui/styles/makeStyles'
import useActions from '../hooks/useActions'
import { AppbarActionContext } from '../context'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  fullWidth: {
    // Make the actionbutton fullwidth
    '& .MuiGrid-item': {
      width: '100%'
    }
  },
  restrictWidth: {
    '& .MuiGrid-item': {
      width: '70%',
      margin: 'auto'
    }
  }
}))

const MenuButtonBar = props => {
  const classes = useStyles(props)
  const { context } = props
  const { formFactor } = useFormFactor()
  const { filterActions } = useActions()
  const { onClick, getId } = useContext(AppbarActionContext)

  const actions = filterActions(context)

  const getButtonBarActions = () =>
    actions.map(action => (
      <ListItem
        disableGutters
        key={getId(action)}
        onClick={evt => onClick(evt, action)}
      >
        {action}
      </ListItem>
    ))

  const cls = actions.length === 1 ? classes.restrictWidth : classes.fullWidth

  return formFactor === 'tablet' ? (
    <LinkBar variant='fixedWidthItems' classes={{ root: cls }}>
      {actions}
    </LinkBar>
  ) : (
    <List className={classes.fullWidth}>{getButtonBarActions()}</List>
  )
}

export default MenuButtonBar
