import * as actions from './slice'

export const launchAssessment = ({ onSuccess, onError }) => {
  return {
    eventName: 'msaLaunchAssessment',
    onSuccess: [
      onSuccess,
      { action: actions.setStartedStatus, redux: true }
    ],
    onError: [
      onError
    ]
  }
}

export const resumeAssessment = ({ onSuccess }) => {
  return {
    eventName: 'msaResumeAssessment',
    onSuccess: [
      onSuccess
    ]
  }
}

export const getAssessmentDetails = () => {
  return {
    eventName: 'msaGetAssessmentDetails',
    onSuccess: {
      action: actions.setAssessmentDetails, redux: true
    }
  }
}

export const getProgress = () => {
  return {
    eventName: 'msaGetProgress',
    onSuccess: {
      action: actions.setProgress, redux: true
    }
  }
}

export const downloadScientificReport = ({ onSuccess }) => {
  return {
    eventName: 'msaDownloadScientificReport',
    onSuccess: [
      onSuccess
    ]
  }
}

export const downloadAssessmentReport = ({ onSuccess, onError }) => {
  return {
    eventName: 'msaDownloadAssessmentReport',
    onSuccess: [
      onSuccess
    ],
    onError: [
      onError
    ]
  }
}
