import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  campaign: undefined
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setCampaignData: (state, { payload }) => {
      state.campaign = payload.campaign
    },
    reset: () => initialState
  }
})

export const { setCampaignData, reset } = content.actions

export default content.reducer
