import { useState } from 'react'
import { useFormFactor } from '../../../hooks'

const useCardExpand = ({ expand } = {}) => {
  const [expanded, setExpanded] = useState(expand)
  const { isDesktop } = useFormFactor()

  const handleToggleDescription = () => setExpanded(!expanded)
  const handleMouseEnter = () => isDesktop && !expand && setExpanded(true)
  const handleMouseLeave = () => isDesktop && !expand && setExpanded(false)

  return {
    expanded,
    handleToggleDescription,
    handleMouseEnter,
    handleMouseLeave
  }
}

export default useCardExpand
