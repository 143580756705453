import React, { useContext } from 'react'
import { Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ToastContext from './context'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit'
  }
}))

const ToastLink = ({ to, children, ...props }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { onClose } = useContext(ToastContext)

  // Ensures that the redux state is reset if the link is clicked.
  const handleNav = () => {
    onClose()
    navigate(to)
  }

  return (
    <Link
      component='button'
      aria-label='toast link'
      role='nav'
      onClick={handleNav}
      className={classes.link}
      {...props}
      underline='hover'
    >
      {children}
    </Link>
  )
}

export default ToastLink
