/* eslint-disable multiline-ternary */
import React from 'react'
import {
  Divider,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText
  , Skeleton
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import { exploreSlice } from '../../reducers'
import MtLogo from '../logos/mindtools_logo_primary_rgb.svg'
import { useNavigate } from 'react-router-dom'
import { contentUrl } from '../../helpers/url'
import { useStyles as useMenuStyles } from './menu'
import Link from '../link'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  itemTitle: {
    lineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'left',
    marginLeft: spacing(1),
    '-webkit-box-orient': 'vertical'
  },
  itemRoot: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacing(1),
    justifyContent: 'flex-start',
    width: '100%',
    '&:hover': {
      backgroundColor: palette.action.hover
    }
  },
  itemDivider: {
    marginBottom: spacing(1)
  },
  titleText: {
    ...typography.body.xs
  }
}))

const COMPONENT_STATES = {
  LOADING: 'LOADING',
  EMPTY: 'EMPTY',
  LOADED: 'LOADED'
}

const getComponentState = newestReleases => {
  if (newestReleases === undefined) return COMPONENT_STATES.LOADING
  if (newestReleases.length === 0) return COMPONENT_STATES.EMPTY
  return COMPONENT_STATES.LOADED
}

const NewestReleasesItem = ({ content, handleSelect, index }) => {
  const classes = useStyles()

  let contentImage = MtLogo

  if (content?.image?.imgUrl) {
    contentImage = content?.image?.imgUrl + 'fit=crop&w=367&h=202'
  }

  const testAttr = `newest-release-${index ?? 'loading'}`

  return (
    <>
      <ListItem
        button
        component='li'
        disableGutters
        key={content?.locationId}
        className={classes.itemRoot}
        onClick={() => handleSelect(content)}
        data-test={testAttr}
      >
        {content ? (
          <img
            src={contentImage}
            alt={content && `Article ${content.locationId}`}
            height={50}
            width={66}
            data-test={`${testAttr}-image`}
          />
        ) : (
          <Skeleton variant='rectangular' height={50} width={66} />
        )}
        <Box className={classes.itemTitle}>
          <Typography className={classes.titleText} data-test={`${testAttr}-title`}>
            {content
              ? (
                <Link to={contentUrl(content)}>
                  {content.title}
                </Link>
                )
              : <Skeleton width={200} />}
          </Typography>
        </Box>
      </ListItem>
      <Divider className={classes.itemDivider} />
    </>
  )
}

const NewestReleasesList = ({ onClose = () => {} }) => {
  const menuClasses = useMenuStyles()

  const newestReleases = useSelector(
    exploreSlice.selectors.selectNewestReleases
  )

  const navigate = useNavigate()

  const componentState = getComponentState(newestReleases)

  if (componentState === COMPONENT_STATES.EMPTY) return null

  if (componentState === COMPONENT_STATES.LOADING) {
    return (
      <>
        <Box mb={2}>
          <Skeleton />
        </Box>
        {new Array(2).fill('').map((_, index) => (
          <NewestReleasesItem key={index} />
        ))}
      </>
    )
  }

  const newestReleasesMenuItemId = 'newest-releases-item'

  const handleCardSelect = item => {
    navigate(contentUrl(item, 'newest-releases'))
    onClose()
  }

  const handleGoToNewestReleasesPage = () => {
    navigate('/newest-releases')
    onClose()
  }

  return (
    <>
      <List className={menuClasses.root} style={{ paddingTop: 0 }}>
        <ListItem
          button
          component='li'
          key={newestReleasesMenuItemId}
          onClick={handleGoToNewestReleasesPage}
          classes={{ gutters: menuClasses.gutters }}
          data-test={`menu-item-${newestReleasesMenuItemId}`}
        >
          <ListItemText
            primary={
              <Link to='/newest-releases' underline='none'>
                <Typography noWrap>Latest Updates</Typography>
              </Link>
            }
          />
        </ListItem>
        <Divider className={menuClasses.divider} />
        {newestReleases.map((content, index) => (
          <NewestReleasesItem
            content={content}
            index={index}
            handleSelect={handleCardSelect}
            key={content.sk}
          />
        ))}
      </List>
    </>
  )
}

export default NewestReleasesList
