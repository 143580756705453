import name from './name'

const selectSliceData = state => state[name]
const selectSingUpInfo = state => selectSliceData(state).signUp
const selectEmail = state => selectSliceData(state).signUp.email

export {
  selectSingUpInfo,
  selectEmail
}
