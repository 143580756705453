import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(
  ({ palette, typography, spacing, breakpoints }) => ({
    root: () => {
      const border = `1px solid ${palette.grey.shade[20]}`
      return {
        borderLeft: border,
        borderTop: border,
        maxWidth: typography.pxToRem(384)
      }
    },
    card: {
      paddingBottom: 0
    },
    title: ({ isDesktop }) => isDesktop && typography.heading.h4,
    image: {
      position: 'relative',
      height: 196
    },
    // fix summary to ensure image remains visible
    summary: {
      position: 'relative',
      marginTop: spacing(-4)
    },
    progress: {
      height: 9,
      borderRadius: 8
    },
    foreground: {
      borderRadius: 8,
      backgroundColor: palette.primary.shade[80]
    },
    background: {
      backgroundColor: '#F2F2F2'
    },
    avatar: {
      height: 21,
      width: 21
    },
    time: {
      color: palette.grey.shade[80],
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      lineHeight: '20px'
    }
  })
)
