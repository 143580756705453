import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const getSearchResults = {
  eventName: 'contentSearchTenantContent',
  onSuccess: {
    action: actions.setResults,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get search results', 'error'),
    redux: true
  }
}

export const getSearchSuggestions = {
  eventName: 'contentSearchTenantSuggestion',
  onSuccess: {
    action: actions.setSuggestions,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get search suggestions', 'error'),
    redux: true
  }
}

export const getUserSearchHistory = {
  eventName: 'searchHistoryGetUser',
  onSuccess: {
    action: actions.setHistory,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get search history', 'error'),
    redux: true
  }
}
