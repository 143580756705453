import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const subscriptionGetAccountInfo = {
  eventName: 'subscriptionGetAccountInfo',
  onSuccess: {
    action: actions.setAccountInfo,
    redux: true
  },
  onError: {
    action: actions.setAccountInfo,
    redux: true
  }
}

export const subscriptionCancel = {
  eventName: 'subscriptionCancel',
  onSuccess: {
    action: actions.setSubscriptionChangeReceived,
    redux: true
  },
  onError: [{
    action: stackAlert('Unable to cancel', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}

export const subscriptionReactivate = {
  eventName: 'subscriptionReactivate',
  onSuccess: {
    action: actions.setSubscriptionChangeReceived,
    redux: true
  },
  onError: [{
    action: stackAlert('Unable to reactivate', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}

export const subscriptionUpdate = {
  eventName: 'subscriptionUpdate',
  onSuccess: [{
    action: actions.setSubscriptionChangeReceived,
    redux: true
  }, {
    action: stackAlert('Subscription Plan has been updated', 'success'),
    redux: true
  }],
  onError: [{
    action: stackAlert('Unable to update', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}

export const subscriptionCreate = {
  eventName: 'subscriptionCreate',
  onSuccess: {
    action: actions.setSubscriptionChangeReceived,
    redux: true
  },
  onError: [{
    action: stackAlert('Unable to update', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}

export const subscriptionSuspend = {
  eventName: 'subscriptionSuspend',
  onSuccess: {
    action: actions.setSubscriptionChangeReceived,
    redux: true
  },
  onError: [{
    action: stackAlert('Unable to suspend', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}

export const subscriptionResume = {
  eventName: 'subscriptionResume',
  onSuccess: {
    action: actions.setSubscriptionChangeReceived,
    redux: true
  },
  onError: [{
    action: stackAlert('Unable to resume', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}

export const subscriptionRedeemCoupon = {
  eventName: 'subscriptionRedeemCoupon',
  onSuccess: {
    action: actions.setSubscriptionChangeReceived,
    redux: true
  },
  onError: [{
    action: stackAlert('Unable to apply discount', 'error'),
    redux: true
  }, {
    action: actions.setSubscriptionChangeError,
    redux: true
  }]
}
