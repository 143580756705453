import React from 'react'
import { Divider, Grid, List, ListItem, ListItemText } from '@mui/material'
import { useSelector } from 'react-redux'
import { searchResultsSlice } from '../../../reducers'
import useFormFactor from '../hooks/useFormFactor'
import SuggestedContent from './suggested-content'
import History from './history'
import { useStyles } from './style'

const SearchSuggestions = ({
  open,
  suggestions,
  searchInput,
  onInputChange,
  onHistoryEdit
}) => {
  const { breakpoints } = useFormFactor()
  const classes = useStyles({ bps: breakpoints })
  const history = useSelector(searchResultsSlice.selectors.selectHistory)
  const showAll = searchInput?.length > 2
  const showSuggestions = showAll && !!suggestions?.length
  const showHistory = !!history?.length

  if ((!open) || (!showAll && !showSuggestions && !showHistory)) return null
  return (
    <Grid className={classes.searchSuggestions}>
      <List className={classes.searchSuggestionsList}>
        {showSuggestions && (
          <SuggestedContent
            input={searchInput} suggestions={suggestions}
            onInputChange={onInputChange}
          />
        )}
        {showSuggestions && showHistory && (
          <li>
            <Divider className={classes.divider} />
          </li>
        )}
        {showHistory && (
          <History
            history={history}
            onInputChange={onInputChange}
            onHistoryEdit={onHistoryEdit}
          />
        )}
        {showAll && (
          <ListItem
            button
            component='li'
            onClick={() => {
              onInputChange(searchInput)
            }}
            className={classes.seeAllResultsButton}
            data-test='search-all-results-action'
          >
            <ListItemText
              className={classes.searchAllText}
              primaryTypographyProps={{
                'data-test': 'search-all-results-text'
              }}
            >
              See all results page
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Grid>
  )
}

export default SearchSuggestions
