import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing }) => ({
  appBar: {
    boxShadow: 'none'
  },
  toolbar: {
    padding: 0,
    margin: spacing(3),
    marginTop: 0,
    borderBottom: '2px solid #5D5BED'
  }
}))
