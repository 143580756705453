import makeStyles from '@mui/styles/makeStyles'

// The dark art of styling a MUI TextField: https://stackoverflow.com/a/64914631

export const useStyles = makeStyles(({ spacing, palette }) => ({
  input: {
    textOverflow: 'ellipsis'
  },
  border: {
    '& .MuiOutlinedInput-root': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: palette.text.primary
      },
      '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: palette.primary.main
      }
    }
  },
  copyButton: {
    textTransform: 'none'
  }
}))
