import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { qsParse } from '../helpers/url'
import { isArray, has } from 'lodash'

const useQuery = ({ handlers, arrayNames }) => {
  const location = useLocation()

  const params = useMemo(() => {
    const handleArrays = params =>
      Object.entries(params).reduce((p, [key, value]) => {
        const v = arrayNames.includes(key) && !isArray(value) ? [value] : value
        return { ...p, [key]: v }
      }, {})

    const cleanParams = params =>
      Object.entries(params).reduce((acc, [key, value]) => {
        const k = key.toLowerCase()
        return {
          ...acc,
          ...(has(handlers, k) ? { [k]: handlers[k](value) } : { [k]: value })
        }
      }, {})

    const rawParams = qsParse(location.search)
    const arrayParams = handleArrays(rawParams)
    const params = cleanParams(arrayParams)

    return params
  }, [location.search, arrayNames, handlers])

  return { location, params }
}

export default useQuery
