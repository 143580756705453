import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: 'en',
    supportedLngs: [
      'ar',
      'de',
      'en',
      'es',
      'fr',
      'id',
      'it',
      'ja',
      'nl',
      'pl',
      'pt',
      'ru',
      'th',
      'tr',
      'zh_CN',
      'zh_TW'
    ],
    ns: ['skill_bite'], // list of namespaces (modules)
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true
    }
  })

export default i18n
