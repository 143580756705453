import * as actions from './slice'
import { stackAlert } from '../toast/helpers'
import { bindActionPayload } from '../util'

export const getCategoryDefaultHierarchy = {
  eventName: 'categoryGetDefaultHierarchy',
  onSuccess: {
    action: actions.setCategoryDefaultHierarchy,
    redux: true
  },
  onError: [
    {
      action: stackAlert('Unable to get the category hierarchy', 'error'),
      redux: true
    },
    {
      action: bindActionPayload(actions.setCategoryDefaultHierarchyError, true),
      redux: true
    }
  ]
}

export const getCategory = {
  eventName: 'categoryGetInfo',
  onSuccess: {
    action: actions.setCategory,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get the category info', 'error'),
    redux: true
  }
}
