import React from 'react'
import { Box, Typography, Grid, Collapse, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import ContentTypeBadge from '../../content-type-badge'
import { useGroup } from '../../../hooks'
import { ALL_CONSUMER } from '../../../services/cognito/userGroups'
import { DEFAULT_READ_TIME } from '../../../consts'
const multiNoWrap = lines => {
  return {
    lineClamp: lines,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical'
  }
}

const imgContainerPercentage = '45%'
const contentContainerOverflow = '30px'

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: {
    height: '100%'
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '400px'

  },
  imgContainer: {
    height: '100%',
    width: imgContainerPercentage,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0
  },
  contentContainer: {
    width: `calc(100% - ${imgContainerPercentage} + ${contentContainerOverflow})`,
    background: '#FFF',
    justifyContent: 'center'
    // color: palette.primary.main
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  cardTitle: props => ({
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: '#000',
    ...multiNoWrap(props.titleLines || 2)
  }),
  cardSummary: props => ({
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#454545',
    minHeight: `calc(24px * ${props.summaryLines})`,
    ...multiNoWrap(props.summaryLines || 1)
  }),
  time: ({ isConsumer }) => ({
    color: isConsumer ? palette.grey.shade[80] : palette.primary.main,
    fontWeight: typography.fontWeightBold
  }),
  actions: props => ({
    marginTop: spacing(props.defaultSectionMargin || 2)
  }),
  cardContentType: ({ isConsumer }) => ({
    ...(isConsumer && { color: palette.primary.main })
  })
}))

const PromotedContentCardContent = ({ content, actions, children, onSelect, expand, overrides = {}, ...props }) => {
  const { defaultSectionMargin = 2, subtitleMargin = 0.5 } = props
  const { isInGroup } = useGroup()
  const classes = useStyles({ ...props, isConsumer: isInGroup(ALL_CONSUMER) })
  const isLoading = !content

  let contentTime = `${DEFAULT_READ_TIME} mins` // default read time
  if (content?.readTime) {
    contentTime = content?.readTime + ' mins'
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      flex={1}
      onClick={onSelect}
      className={clsx(classes.contentContainer, overrides.contentContainer)}
      p={2}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
      >
        <Box display='flex' flex={1}>
          {isLoading
            ? <Skeleton variant='text' width={100} />
            : (
              <ContentTypeBadge
                primaryType={content?.primaryType}
                dataTest={`promoted-card-${content?.friendlyUrl}'}`}
                classes={{
                  root: clsx(classes.cardContentType, overrides.cardContentType),
                  text: clsx(overrides.cardContentTypeText)
                }}
              />
              )}
        </Box>
        <Box>
          <Typography
            variant='body1'
            className={clsx(classes.time, overrides.time)}
            noWrap
            align='right'
            data-test={`card-time-${content?.friendlyUrl}'}`}
          >
            {isLoading ? <Skeleton variant='text' width={100} /> : contentTime}
          </Typography>
        </Box>
      </Box>
      <Box mt={defaultSectionMargin}>
        <Typography className={clsx(classes.cardTitle, overrides.cardTitle)}>
          {isLoading ? <Skeleton variant='text' width='100%' /> : content?.title}
        </Typography>
      </Box>
      <Box mt={subtitleMargin}>
        <Collapse in={expand}>
          <Typography data-test={`card-subtitle-${content?.friendlyUrl}'}`} className={clsx(classes.cardSummary, overrides.cardSummary)}>
            {!isLoading
              ? content?.subtitle?.html
              : (
                <>
                  <Skeleton width='100%' />
                  <Skeleton width='70%' />
                </>
                )}
          </Typography>
        </Collapse>
      </Box>
      <div>
        {children}
      </div>
      <Grid
        container
        className={clsx(classes.actions, overrides.actions)}
        justifyContent='space-between'
        wrap='nowrap'
        spacing={1}
        onClick={e => e.stopPropagation()}
        data-test={`card-actions-${content?.friendlyUrl}'}`}
      >
        {!isLoading
          ? (
            <>
              <Grid
                container
                item
                justifyContent='flex-start'
                spacing={1}
                wrap='nowrap'
                data-test={`card-left-actions-${content?.friendlyUrl}'}`}
              >
                {actions?.leftActions}
              </Grid>
              <Grid
                container
                item
                justifyContent='flex-end'
                spacing={1}
                wrap='nowrap'
                data-test={`card-right-actions-${content?.friendlyUrl}'}`}
              >
                {actions?.rightActions}
              </Grid>
            </>
            )
          : (
            <Box ml={0.5} width='100%'>
              <Skeleton variant='rectangular' width='40%' />
            </Box>
            )}
      </Grid>
    </Box>
  )
}

export default PromotedContentCardContent
