import React from 'react'
import ArticleCardLandscape from './article-card'
import ArticleCardPortrait from './article-card-portrait'

const ArticleCard = ({ variant, ...props }) => {
  const Component =
    variant === 'portrait' ? ArticleCardPortrait : ArticleCardLandscape
  return <Component {...props} />
}

export default ArticleCard
