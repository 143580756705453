import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  authError: undefined
}

export const auth = createSlice({
  name,
  initialState,
  reducers: {
    setAuthError: (state, { payload }) => ({ ...state, authError: payload }),
    reset: () => initialState
  }
})

export const { setAuthError, reset } = auth.actions

export default auth.reducer
