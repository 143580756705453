import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  menu: {
    width: '12em',
    border: `1px solid ${palette.grey.shade[10]}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: `0px 4px 6px -2px rgba(24, 24, 24, 0.06), 
                0px 10px 15px -3px rgba(24, 24, 24, 0.1)`

  },
  action: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: '10px 8px',
    color: palette.text.secondary,
    fontWeight: typography.weight.medium
  }
}))
