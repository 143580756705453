import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  card: {
    display: 'flex',
    height: '150px',
    marginTop: spacing(4),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: '100%'
    }
  },
  cardMedia: {
    width: '150px',
    [breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  box: {
    backgroundColor: '#F4F4F4',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  title: {
    color: 'rgba(0, 0, 0, 0.8)'
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  url: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: spacing(2)
  }
}))
