import React, { useEffect, useState } from 'react'
import { useFormFactor, useGroup } from '../../../hooks'
import { MtLogo } from './../../logos'
import { AddToPlaylistButton, BookmarkButton, MoreActions, ActionButton, ToggleDescriptionButton, LikeButton } from '../../action-buttons'
import {
  CollectionsBookmarkTwoTone,
  ThumbUpTwoTone,
  ThumbUp,
  Bookmark,
  BookmarkBorder
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { contentUrl } from '../../../helpers/url'
import { ALLOW_LIKE_GROUP, ALLOW_PLAYLISTS_GROUP } from '../../user-group-policy/permissions'
import { UserGroupPolicy } from '../../user-group-policy'
import { ALL_CONSUMER } from '../../../services/cognito/userGroups'
import { useStyles } from './styles'
import { useDisplayAddToPlaylist } from '../../../hooks/useDisplayAddToPlaylist'

export const PROMOTED_CARD_TYPES = {
  MAIN: 'MAIN',
  SECONDARY: 'SECONDARY',
  GENERIC: 'GENERIC'
}

const usePromotedCardMethods = ({
  content,
  size,
  position,
  cardType,
  onAddToPlaylist,
  onBookmark,
  onLike,
  bookmarked,
  actionLoading
}) => {
  const { isDesktop, isLargeTablet } = useFormFactor()
  const isFullWidth = isDesktop || isLargeTablet
  const [expanded, setExpanded] = useState(isFullWidth)
  const [hoverShadow, setHoverShadow] = useState(false)
  const cardSize = size || (isDesktop ? 'large' : 'mobile')
  const { isInGroup } = useGroup()
  const isConsumer = isInGroup(ALL_CONSUMER)
  const classes = useStyles({ isConsumer })
  const displayAddToPlaylist = useDisplayAddToPlaylist()

  let contentImage = MtLogo

  if (content?.image?.imgUrl) {
    const dimensions = size === 'large' ? 'w=540&h=380' : 'w=367&h=202'
    contentImage = content?.image?.imgUrl + `fit=crop&${dimensions}`
  }

  useEffect(() => {
    setExpanded(isFullWidth)
  }, [isFullWidth])

  const navigate = useNavigate()
  const handleCardSelect = () => content && navigate(contentUrl(content, 'promoted', position))

  const handleLike = () => onLike(content)
  const handleToggleDescription = () => setExpanded(!expanded)
  const handleMouseEnter = () => {
    setHoverShadow(true)
    !isFullWidth && setExpanded(true)
  }
  const handleMouseLeave = () => {
    setHoverShadow(false)
    !isFullWidth && setExpanded(false)
  }
  const handleAddToPlaylist = () => {
    onAddToPlaylist(content)
  }

  const MoreActionsMenu = () => {
    return (
      <MoreActions
        variant={isConsumer ? 'none' : 'secondary'}
        size='xs'
        loading={actionLoading && Object.values(actionLoading).some(val => !!val)}
        className={classes.action}
        actions={actions => [
          [
            <LikeButton
              key='Like'
              variant='none'
              label={content?.hasRecommend ? 'Liked' : 'Like'}
              loading={actionLoading?.like}
              likes={content?.likeCount ?? 0}
              onClick={() => {
                handleLike()
                actions.onClose()
              }}
              className={actions.className}
            />
          ],
          [
            <BookmarkButton
              key='Bookmark'
              className={actions.className}
              onClick={() => {
                onBookmark()
                actions.onClose()
              }}
              bookmarked={bookmarked}
            />
          ],
          ...(isInGroup(ALLOW_PLAYLISTS_GROUP) && displayAddToPlaylist
            ? [
              <AddToPlaylistButton
                key='AddToPlaylist'
                className={actions.className}
                onClick={() => {
                  handleAddToPlaylist()
                  actions.onClose()
                }}
              />
              ]
            : [])
        ]}
      />
    )
  }

  let actions = cardType === PROMOTED_CARD_TYPES.MAIN
    ? {
        leftActions: (
          <>
            <UserGroupPolicy
              accessGroup={ALLOW_LIKE_GROUP}
              componentAllow={
                <ActionButton
                  variant={isConsumer ? 'none' : 'secondary'}
                  size='xs'
                  label={content?.hasRecommend ? 'Liked' : 'Like'}
                  startIcon={content?.hasRecommend ? <ThumbUp /> : <ThumbUpTwoTone />}
                  onClick={handleLike}
                  loading={actionLoading?.like}
                  className={classes.action}
                />
              }
            />
            <BookmarkButton
              bookmarked={bookmarked}
              variant={isConsumer ? 'none' : 'secondary'}
              size='xs'
              loading={actionLoading?.bookmark}
              onClick={onBookmark}
              startIcon={bookmarked ? <Bookmark /> : <BookmarkBorder />}
              className={classes.action}
            />
            {displayAddToPlaylist && (
              <UserGroupPolicy
                accessGroup={ALLOW_PLAYLISTS_GROUP}
                componentAllow={
                  <ActionButton
                    variant={isConsumer ? 'none' : 'secondary'}
                    size='xs'
                    label='Add to Playlist'
                    startIcon={<CollectionsBookmarkTwoTone />}
                    onClick={handleAddToPlaylist}
                    className={classes.action}
                  />
                }
              />
            )}
          </>
        )
      }
    : {
        leftActions: (
          <>
            <MoreActionsMenu />
          </>
        )
      }

  if (!isFullWidth) {
    actions = {
      leftActions: (
        <ToggleDescriptionButton
          size='xs'
          variant={isConsumer ? 'none' : 'secondary'}
          toggled={expanded}
          onClick={handleToggleDescription}
          className={classes.action}
        />
      ),
      rightActions: (
        <>
          <MoreActionsMenu />
        </>
      )
    }
  }

  return {
    contentImage,
    handleMouseEnter,
    handleMouseLeave,
    handleCardSelect,
    expanded,
    cardSize,
    hoverShadow,
    actions
  }
}

export default usePromotedCardMethods
