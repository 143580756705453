import makeStyles from '@mui/styles/makeStyles'
import { getContrastRatio } from '@mui/material'

export const variant = (variant, palette) => {
  const getSufficientContrast = (main, secondary) => {
    const shades = palette.primary.shade
    const keys = Object.keys(shades)
    const values = Object.values(shades)

    let contrast = secondary
    // Get the next available shade with a valid contrast
    while (getContrastRatio(main, contrast) < 4.5) {
      const next = Object.values(shades).indexOf(contrast) + 1
      if (next > values.length) return
      contrast = shades[keys[next]]
    }

    return { main, contrast }
  }

  switch (variant) {
    case 'primary': {
      const { main, contrast } = getSufficientContrast(
        palette.white.main,
        palette.primary.main
      )
      return {
        color: main,
        backgroundColor: contrast,
        '&:hover': {
          backgroundColor: palette.primary.shade[70]
        }
      }
    }
    case 'secondary': {
      const defaultState = getSufficientContrast(
        palette.primary.shade[5],
        palette.primary.shade[60]
      )
      const hoverState = getSufficientContrast(
        palette.primary.shade[10],
        palette.primary.shade[70]
      )
      return {
        backgroundColor: defaultState.main,
        color: defaultState.contrast,
        '&:hover': {
          backgroundColor: hoverState.main,
          color: hoverState.contrast
        }
      }
    }
    case 'secondary-noselect': {
      const defaultState = getSufficientContrast(
        palette.grey.shade[5],
        palette.grey.shade[70]
      )
      const hoverState = getSufficientContrast(
        palette.grey.shade[10],
        palette.grey.shade[80]
      )
      return {
        backgroundColor: defaultState.main,
        color: defaultState.contrast,
        '&:hover': {
          backgroundColor: hoverState.main,
          color: hoverState.contrast
        }
      }
    }
    case 'publicTenantSecondary': {
      const { main, contrast } = getSufficientContrast(
        palette.white.main,
        palette.publicTenantSecondary.main
      )
      return {
        color: main,
        backgroundColor: contrast,
        '&:hover': {
          backgroundColor: palette.publicTenantSecondary.main
        }
      }
    }
    case 'neutral': {
      return {
        backgroundColor: palette.grey[800],
        color: palette.getContrastText(palette.grey[800]),
        borderRadius: 4,
        '&:hover': {
          backgroundColor: palette.grey[800],
          color: palette.getContrastText(palette.grey[800])
        }
      }
    }
    case 'success':
      return {
        backgroundColor: '#2FA83E',
        color: palette.white.main
      }
    case 'ghost':
      return {
        backgroundColor: palette.white.main,
        color: palette.text.secondary,
        border: '1px solid #B9B7BD',
        height: 'calc(100% - 2px)',
        '&:hover': {
          backgroundColor: palette.grey.shade[5]
        }
      }
    case 'link': {
      const { contrast } = getSufficientContrast(
        palette.white.main,
        palette.primary.shade[70]
      )
      return {
        color: contrast,
        boxShadow: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    }
    case 'alert':
      return {
        backgroundColor: palette.alert.main,
        color: palette.common.white,
        '&:hover': {
          backgroundColor: palette.alert.dark
        }
      }
    case 'join':
      return {
        backgroundColor: '#B32013',
        color: palette.common.white,
        '&:hover': {
          backgroundColor: '#B32013'
        }
      }
    case 'grey':
      return {
        background: palette.grey.shade[80],
        color: 'white',
        '&:hover': {
          background: palette.grey.shade[90]
        }
      }
    default:
      return {
        backgroundColor: 'none',
        boxShadow: 'none'
      }
  }
}
const size = (size, typography) => {
  const sizes = {
    xs: {
      padding: '5px 8px',
      ...typography.micro
    },
    s: {
      padding: '8px 16px',
      ...typography.body.xs
    },
    m: {
      padding: '10px 16px',
      ...typography.body.s
    },
    l: {
      padding: '12px 16px',
      ...typography.body.m
    },
    xl: {
      padding: '16px 24px',
      ...typography.body.l,
      fontWeight: typography.weight.bold
    }
  }

  return sizes[size] ?? sizes.s
}

export const useStyles = makeStyles(
  ({ palette, typography, spacing, breakpoints }) => ({
    root: props => {
      return {
        fontWeight: typography.weight.medium,
        boxShadow: '0px 1px 2px rgba(24, 24, 24, 0.07)',
        borderRadius: '2px',
        ...variant(props?.variant, palette),
        ...size(props?.size, typography),
        '&.Mui-disabled': {
          backgroundColor: palette.grey.shade[20],
          color: palette.white.main,
          border:
            props?.variant === 'ghost'
              ? `1px solid ${palette.grey.shade[20]}`
              : 'none'
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.25em'
        }
      }
    },
    startIcon: {
      font: 'inherit',
      marginLeft: 0,
      marginRight: 5
    },
    label: {
      letterSpacing: '0.01px',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      padding: 0,
      textTransform: 'none'
    }
  })
)
