import React, { useContext } from 'react'
import { ListItem } from '@mui/material'
import HorizontalList from '../horizontal-list'
import { AppbarActionContext } from '../context'

const LinkBar = ({ children, ...props }) => {
  const { getId, onClick } = useContext(AppbarActionContext)
  return (
    <HorizontalList
      renderItem={item => (
        <ListItem
          key={getId(item)}
          disableGutters
          onClick={evt => onClick(evt, item)}
        >
          {item}
        </ListItem>
      )}
      {...props}
    >
      {children}
    </HorizontalList>
  )
}

export default LinkBar
