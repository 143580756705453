import React, { cloneElement } from 'react'
import { Grid, adaptV4Theme } from '@mui/material'
import { ThemeProvider, StyledEngineProvider, useTheme } from '@mui/material/styles'
import { createTheme } from '../../theme'
import { defaultColumns } from './params'
import { useStyles } from './style'

/**
 * The grid theme has to be created statically not within the component so that performance isn't
 * a problem. In mui v4 the Grid component only uses the breakpoints and spacing from the theme.
 */
const gridTheme = createTheme(adaptV4Theme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 750,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
}))

/**
 * This is the results grid with a single difference: the sm breakpoint is set to a
 * larger value to accommodate the wider playlist cards.
 * Using a nested theme means the mui Grid components can be used as-is.
 */
const PlaylistList = ({ results, renderItem, columns = defaultColumns }) => {
  const appTheme = useTheme()
  const classes = useStyles()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={gridTheme}>
        <Grid container spacing={3} data-test='list-container'>
          {results.filter((item) => Boolean).map((item, index) => {
            return (
              <Grid
                item
                key={item?.friendlyUrl ?? index}
                xs={columns.xs}
                sm={columns.sm}
                md={columns.md}
                lg={columns.lg}
                xl={columns.xl}
                data-test={`list-item-${index}`}
              >
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={appTheme}>
                    {cloneElement(renderItem(item), { className: classes.card })}
                  </ThemeProvider>
                </StyledEngineProvider>
              </Grid>
            )
          })}
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default PlaylistList
