import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ typography, palette }) => {
  return {
    root: ({ underline }) => ({
      ...typography.heading.h4,
      color: palette.grey.shade[100],
      ...(underline && { marginBottom: 3 })
    })
  }
})

const SectionHeader = props => {
  const classes = useStyles(props)
  const { underline, children } = props
  return (
    <>
      <Typography
        component='h2'
        variant='h4'
        className={classes.root}
      >
        {children}
      </Typography>
      {underline}
    </>
  )
}

export default SectionHeader
