import * as actions from './slice'

export const onboardingGetUser = {
  eventName: 'onboardingGetUser',
  initialiser: false,
  onSuccess: {
    action: actions.setOnboardingProfile,
    redux: true
  }
}
export const onboardingTrackEvent = {
  eventName: 'onboardingTrackEvent',
  initialiser: false,
  onStart: {
    action: actions.closePlaylistPopper,
    redux: true
  }
}
