import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ typography, spacing, breakpoints }) => ({
  root: {
    maxWidth: 550,
    [breakpoints.up('xs')]: {
      marginBottom: spacing(5)
    },
    [breakpoints.up('sm')]: {
      marginBottom: spacing(8)
    }
  },
  text: {
    ...typography.body.m
  }
}))

const Desc = props => {
  const { text = [] } = props
  const classes = useStyles(props)

  const renderDesc = () =>
    text.map((line, index) => (
      <Typography
        variant='body2'
        align='center'
        className={classes.text}
        key={index}
      >
        {line}
      </Typography>
    ))

  return <div className={classes.root}>{renderDesc()}</div>
}

export default Desc
