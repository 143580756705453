import { useRef } from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'

function usePromiseEvent (eventName) {
  const promiseRef = useRef(null)
  const [eventTrigger] = useEvent([{
    eventName,
    onSuccess: (payload) => {
      promiseRef.current.resolve(payload)
    },
    onError: (err) => {
      promiseRef.current.reject(err)
    }
  }])
  const trigger = (payload) => {
    return new Promise((resolve, reject) => {
      promiseRef.current = {
        resolve,
        reject
      }
      eventTrigger.trigger(payload)
    })
  }
  return trigger
}

export default usePromiseEvent
