import { AppBar, Box, Toolbar } from '@mui/material'
import React from 'react'
import { B2CMindToolsLogo } from '../icons'
import { useStyles } from './styles'

const B2CAuthAppBar = ({ rightSlot }) => {
  const classes = useStyles()

  return (
    <AppBar position='static' color='white' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box display='flex' flexDirection='row' flex={1} alignItems='center' alignContent='center'>
          <B2CMindToolsLogo height='20' />
          <Box flex={1} />
          <Box>
            {rightSlot}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default B2CAuthAppBar
