import { Grow, List, ListItem, Popover, Slide } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import useActions from '../hooks/useActions'
import MenuButtonBar from './menu-button-bar'
import useFormFactor from '../hooks/useFormFactor'
import CloseButton from './close-button'
import useAppbarEvent from '../hooks/useAppbarEvent'
import { AppbarActionContext } from '../context'
import { appBarHeight } from '../style'
import { GoogleAd } from '../../general-card/promoted-content-card'
import { useGoogleAdsense } from '../../../hooks'

// The !important styles are needed to override inline style attribute values.
const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  paper: ({ bps }) => ({
    top: '0 !important',
    [breakpoints.down(bps.smallDesktop)]: {
      maxHeight: '100%',
      maxWidth: '100%',
      left: 'calc(100vw / 2) !important',
      right: 0,
      bottom: 0
    },
    [breakpoints.down(bps.tablet)]: {
      left: '0 !important'
    },
    [breakpoints.up(bps.smallDesktop)]: {
      padding: spacing(1)
    }
  }),
  list: {
    minWidth: '17em'
  },
  listItem: {
    '& .MuiGrid-item': {
      width: '100%',
      '& > *': {
        width: '100%',
        justifyContent: 'flex-start'
      }
    }
  },
  container: ({ bps }) => ({
    [breakpoints.down(bps.smallDesktop)]: {
      marginLeft: spacing(3),
      marginRight: spacing(3)
    },
    [breakpoints.down('sm')]: {
      marginLeft: spacing(2),
      marginRight: spacing(2)
    }
  }),
  popoverRoot: {
    top: `${appBarHeight}px !important`
  }
}))

const Menu = props => {
  const { formFactor, breakpoints } = useFormFactor()
  const classes = useStyles({ bps: breakpoints })
  const { filterActions } = useActions()

  const handleListClick = useCallback(args => props.onClose(args), [props])

  /*
   * The modal closing will reset the focus to either the initiating button or
   * the document body. This focuses the search if it was the cause of the
   * modal being closed.
   */
  const handleAppbarClick = useCallback(
    evt => {
      handleListClick(evt)
      if (['INPUT'].includes(evt.target.tagName)) {
        setTimeout(() => evt.target.focus(), 0)
      }
    },
    [handleListClick]
  )

  useAppbarEvent({ open: props.open, onClick: handleAppbarClick })

  const actionContext = useContext(AppbarActionContext)

  useEffect(() => {
    actionContext.onMenuShow(props.open)
  }, [props.open, actionContext])

  const isLargeMenu = ['mobile', 'tablet'].includes(formFactor)
  const paperProps = {
    classes: { root: classes.paper },
    ...(isLargeMenu && {
      variant: 'outlined',
      square: true
    })
  }

  const useAdsense = useGoogleAdsense()

  // disableScrollLock prevents the removal of the scrollbar from shifting the content
  // to the right. At the cost of being able to scroll the content under the modal.
  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disableScrollLock
      TransitionComponent={isLargeMenu ? Slide : Grow}
      TransitionProps={isLargeMenu ? { direction: 'left' } : {}}
      PaperProps={paperProps}
      BackdropProps={{ style: { top: appBarHeight } }}
      classes={{ root: classes.popoverRoot }}
    >
      <div onClick={handleListClick} className={classes.container}>
        {formFactor === 'tablet' && <CloseButton onClick={handleListClick} />}
        <MenuButtonBar context='startmenubuttons' />
        <List className={classes.list}>
          {filterActions(props.filterContext).map(action => (
            <ListItem
              disableGutters
              key={actionContext.getId(action)}
              onClick={evt => actionContext.onClick(evt, action)}
              divider={['mobile', 'tablet'].includes(formFactor)}
              className={classes.listItem}
            >
              {action}
            </ListItem>
          ))}
        </List>
        <MenuButtonBar context='endmenubuttons' />
        {(formFactor === 'mobile' && useAdsense) && <GoogleAd height='100px' location='app-toolbar>menu-button>menu.js' />}

      </div>
    </Popover>
  )
}

export default Menu
